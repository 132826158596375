import React, { useState, useEffect, useMemo, useRef } from "react";
import { Box, Text, Progress, Avatar, Input, Kbd } from "@chakra-ui/react";
import useGetAIAnswer from "./utils/getAiAnswer";
import { useSelector, useDispatch } from "react-redux";
import { BiLabel, BiSend } from "react-icons/bi";
import { ALL_CHATS } from "../../redux/types";
import { dataObj } from "./data/data";
import InteractiveElements from "./components/InteractiveElements";

const SearchedDataPage = () => {
  const dispatch = useDispatch();
  const reduxData = useSelector((state) => state.aiSearch);
  const [allChats, setAllChats] = useState([]);
  const searchQuery = reduxData.new_searchQuery;
  const [response, setResponse] = useState(null);
  const [input, setInput] = useState("");

  const { fetchAIAnswer, isLoading, setIsLoading, errorAns } = useGetAIAnswer();

  const [tableState, setTableState] = useState(dataObj[0]?.table);
  function myFunction(table_input) {
    let objects_list = Object.keys(table_input);
    let data_array = [];
    let header_array = [];
    let i = 0;

    while (i < objects_list.length) {
      header_array.push("-");
      data_array.push([table_input[objects_list[i]], objects_list[i]]);
      i++;
    }
    let table_output = {
      headers: header_array,
      data: data_array,
    };
    return table_output;
  }

  console.log(
    "checking",
    Object.keys(tableState).includes("headers"),
    Object.keys(tableState).includes("data")
  );

  if (
    !Object.keys(tableState).includes("headers") ||
    !Object.keys(tableState).includes("data")
  ) {
    let modifiedTable = myFunction(tableState);
    console.log("modified", modifiedTable);
    setTableState(modifiedTable);
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const x = await fetchAIAnswer("r242@gmail.com", searchQuery);
      console.log(x);
      setResponse(x);
      setIsLoading(false);
      dispatch({
        type: ALL_CHATS,
        payload: {
          question: searchQuery,
          answer: x?.Answer || "No answer available",
        },
      });
    };

    fetchData();
  }, [searchQuery, setIsLoading, dispatch]);

  useEffect(() => {
    setAllChats(reduxData.all_chats);
  }, [reduxData]);

  const handleSearch = async () => {
    setIsLoading(true);
    const x = await fetchAIAnswer("r242@gmail.com", input);
    setIsLoading(false);
    dispatch({
      type: ALL_CHATS,
      payload: {
        question: input,
        answer: x?.Answer || "No answer available",
      },
    });
    setInput("");
  };

  const inputRef = useRef(null);

  const handleGlobalKeyDown = (e) => {
    if ((e.ctrlKey || e.metaKey) && e.key === "k") {
      e.preventDefault();

      inputRef.current.focus();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleGlobalKeyDown);
    return () => {
      document.removeEventListener("keydown", handleGlobalKeyDown);
    };
  }, []);

  console.log("IMP !!!", allChats);

  const memoizedChats = useMemo(() => {
    return allChats?.map((chat, i) => (
      <React.Fragment key={i}>
        <Box
          _hover={{
            border: "1px purple",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
            transform: "scale(1.0013)",
            transition: "all ease",
            transitionDuration: "0.3s",
          }}
        >
          <Box
            width={"full"}
            paddingX={8}
            paddingY={4}
            display={"flex"}
            alignItems={"center"}
            marginX={"16px"}
          >
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={3}
              marginLeft={"150px"}
              flexDir={"column"}
            >
              <Box display={"flex"} gap={2} alignSelf={"flex-start"} m={0}>
                <Avatar size={"sm"} src="assets\landing\section7Avatar.png" />
                <Text
                  m={0}
                  fontSize={"sm"}
                  fontWeight={"semibold"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  You
                </Text>
              </Box>
              <Text fontSize={"lg"} m={0} color={"purple.400"} ml={"40px"}>
                {chat.question}
              </Text>
            </Box>
          </Box>

          <Box
            backgroundColor={"purple.100"}
            width={"full"}
            paddingX={8}
            paddingY={4}
            display={"flex"}
            alignItems={"center"}
          >
            <Box
              maxWidth={"70%"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={3}
              marginLeft={"165px"}
              paddingY={"16px"}
              flexDir={"column"}
            >
              <Box display={"flex"} gap={2} alignSelf={"flex-start"} m={0}>
                <Avatar size={"sm"} src="" />
                <Text
                  m={0}
                  fontSize={"sm"}
                  fontWeight={"semibold"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  Claydeck Ai
                </Text>
              </Box>
              <Box ml={"40px"} display={"flex"} flexDir={"column"}>
                {chat?.answer?.text ? (
                  <Text fontSize={"md"} color={"black"} m={0}>
                    {chat?.answer?.text}
                  </Text>
                ) : (
                  <Text fontSize={"md"} color={"black"} m={0}>
                    {chat?.answer}
                  </Text>
                )}
                <InteractiveElements chat={chat?.answer} />
              </Box>
            </Box>
          </Box>
        </Box>
      </React.Fragment>
    ));
  }, [allChats]);

  return (
    <Box
      display={"flex"}
      flexDir={"column"}
      paddingY={"16px"}
      width={"full"}
      position={"relative"}
    >
      <Box marginBottom={"100px"}>
        {memoizedChats}
        {isLoading && (
          <>
            <Box
              width={"full"}
              paddingX={8}
              paddingY={4}
              display={"flex"}
              alignItems={"center"}
              marginX={"16px"}
            >
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={3}
                marginLeft={"150px"}
                flexDir={"column"}
              >
                <Box display={"flex"} gap={2} alignSelf={"flex-start"} m={0}>
                  <Avatar size={"sm"} src="assets\landing\section7Avatar.png" />
                  <Text
                    m={0}
                    fontSize={"sm"}
                    fontWeight={"semibold"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    You
                  </Text>
                </Box>
                <Text fontSize={"lg"} m={0} color={"purple.400"} ml={"40px"}>
                  {input === "" ? searchQuery : input}
                </Text>
              </Box>
            </Box>
            <Box
              backgroundColor={"purple.100"}
              width={"full"}
              paddingX={8}
              paddingY={4}
              display={"flex"}
              alignItems={"center"}
            >
              <Box
                display={"flex"}
                flexDir={"column"}
                gap={2}
                transition={"ease-in-out"}
                transitionDuration={"300ms"}
                marginLeft={"165px"}
                paddingY={"16px"}
              >
                <Box display={"flex"} gap={2} alignSelf={"flex-start"} m={0}>
                  <Avatar size={"sm"} src="assets\landing\section7Avatar.png" />
                  <Text
                    m={0}
                    fontSize={"sm"}
                    fontWeight={"semibold"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    Claydeck Ai
                  </Text>
                </Box>
                <Text fontSize={"md"} color={"purple.300"} fontWeight={450}>
                  Generating Answer, AI on working... Be patient
                </Text>
                <Progress
                  size="xs"
                  isIndeterminate
                  backgroundColor={"purple.200"}
                  colorScheme="purple"
                />
              </Box>
            </Box>
          </>
        )}
      </Box>

      <Box
        as="form"
        position={"fixed"}
        bottom={0}
        bg={"purple.200"}
        alignSelf={"center"}
        width={"60%"}
        borderRadius={"md"}
        marginBottom={"20px"}
        display={"flex"}
        gap={2}
        justifyContent={"center"}
        alignItems={"center"}
        boxShadow={"lg"}
        transition={"ease-in-out"}
        transitionDuration={"300ms"}
        _focusWithin={{
          marginBottom: "24px",
          transform: "scale(1.015)",
          borderColor: "purple.200",
          borderBottom: "2px solid",
        }}
      >
        <Input
          placeholder="ask anything here ..."
          borderColor={"purple.200"}
          ml={"16px"}
          border={0}
          variant={"unstyled"}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          ref={inputRef}
        />
        <Kbd color={"black"} bgColor={"purple.100"}>
          ctrl
        </Kbd>{" "}
        +{" "}
        <Kbd color={"black"} bgColor={"purple.100"}>
          K
        </Kbd>
        <Box
          as="button"
          padding={1}
          rounded={"md"}
          margin={2.5}
          opacity={"60%"}
          transition={"ease-in-out"}
          transitionDuration={"300ms"}
          _hover={{
            backgroundColor: "purple.300",
            cursor: "pointer",
            opacity: "90%",
            transform: "scale(1.05)",
          }}
          _active={{
            transform: "scale(0.7)",
          }}
          onClick={(e) => {
            e.preventDefault();
            handleSearch();
          }}
        >
          <BiSend size={32} />
        </Box>
      </Box>
    </Box>
  );
};

export default SearchedDataPage;
