import {useState} from "react";
import InputOutline from "./InputOutline";
import { InputGroup, InputRightElement, Button } from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

const InputPassword = (props) => {

    const [showPassword, setShowPassword] = useState(false);

    const handlePasswordVisibility = () => setShowPassword(prev => !prev);

    return (
      <InputGroup>
        <InputOutline
          type={showPassword ? "text" : "password"}
          placeholder="Password"
          {...props}
        />
        <InputRightElement width="3rem" height={"100%"}>
          <Button
            h="2rem"
            size="sm"
            onClick={handlePasswordVisibility}
          >
            {showPassword ? <ViewIcon /> : <ViewOffIcon />}
          </Button>
        </InputRightElement>
      </InputGroup>
    );
}

export default InputPassword;
