import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Button,
  useColorModeValue,
  Center,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import useGetTest from "../utils/getTests";
import { useParams } from "react-router-dom";
import usePostSubmitTestData from "../utils/postSubmitTestData";

const QuizOption = ({ option, isSelected, onClick }) => {
  const buttonBg = useColorModeValue("transparent", "purple.500");
  const buttonColor = useColorModeValue("purple.500", "white");

  return (
    <Button
      onClick={onClick}
      variant="outline"
      disabled={isSelected}
      whiteSpace={"none"}
      p={2}
      mt={2}
      minH={"45px"}
      bg={isSelected ? "purple.500" : buttonBg}
      color={isSelected ? "white" : buttonColor}
      borderColor="purple.500"
      _hover={{
        bg: "purple.500",
        color: "white",
      }}
    >
      {option}
    </Button>
  );
};

const MockPaperTest = ({ id, setQuizCompleted, isQuizCompleted, timer }) => {
  const { fetchTest, isDataLoading, setIsDataLoading } = useGetTest();
  const [questions, setQuestions] = useState([]);
  const [isLastQue, setIsLastQue] = useState(false);

  const toast = useToast();

  const params = useParams();

  useEffect(() => {
    const fetchData = async () => {
      setIsDataLoading(true);
      const res = await fetchTest(id);
      setQuestions(res?.test?.questions);
      setIsDataLoading(false);
    };

    fetchData();
  }, []);

  const { postSubmitTestData, isLoadingpost, errorPost } =
    usePostSubmitTestData();

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [questionsAttended, setQuestionsAttended] = useState(0);
  const [submitData, setSubmitData] = useState({
    practice_type: "mock paper",
    test_format: "objective",
    subject: params?.subject,
    chapter: `chapter 8 ${params?.test}`,
    max_score: 0,
    user_score: 0,
    time_taken: 0,
    question: {
      TotalCorrectAnswers: 0,
      TotalAttendedQuestions: 0,
    },
  });

  console.log(errorPost);

  const handleSubmit = async () => {
    if (!isLastQue) {
      var confirm = window.confirm(
        "Yet you have not completed the test , are you sure you want to submit the test ?"
      );
      if (confirm) {
        console.log(submitData);
        const postData = async () => {
          try {
            submitData.question.TotalAttendedQuestions = questionsAttended;
            submitData.question.TotalCorrectAnswers = totalCorrectAnswers;
            submitData.time_taken = 600 - timer;
            submitData.user_score = totalCorrectAnswers * 5;
            submitData.max_score =
              submitData.max_score < totalCorrectAnswers * 5
                ? totalCorrectAnswers * 5
                : submitData.max_score;
            await postSubmitTestData(
              "patelhetvarasada@gmail.com",
              2,
              1,
              submitData
            );

            setQuizCompleted(true);
          } catch (error) {
            console.error("Error in postData:", error);
            toast({
              title: `Something went wrong! Try again later`,
              status: "error",
              isClosable: true,
              position: "top-right",
              duration: 3000,
            });
          }
        };

        await postData();

        console.log("error", errorPost);
        if (errorPost) {
          toast({
            title: `Something went wrong! Try again later`,
            status: "error",
            isClosable: true,
            position: "top-right",
            duration: 3000,
          });
          return;
        }
        toast({
          title: `Data submitted successfully`,
          position: "top-right",
          isClosable: true,
          status: "success",
          duration: 3000,
        });
      } else {
        return;
      }
    } else {
      const postData = async () => {
        try {
          submitData.question.TotalAttendedQuestions = questionsAttended;
          submitData.question.TotalCorrectAnswers = totalCorrectAnswers;
          submitData.time_taken = 600 - timer;
          submitData.user_score = totalCorrectAnswers * 5;
          submitData.max_score =
            submitData.max_score < totalCorrectAnswers * 5
              ? totalCorrectAnswers * 5
              : submitData.max_score;
          await postSubmitTestData(
            "patelhetvarasada@gmail.com",
            2,
            1,
            submitData
          );
          setQuizCompleted(true);
        } catch (error) {
          console.error("Error in postData:", error);
          toast({
            title: `Something went wrong! Try again later`,
            status: "error",
            isClosable: true,
            position: "top-right",
            duration: 3000,
          });
        }
      };

      await postData();
      console.log("error", errorPost);

      if (errorPost) {
        toast({
          title: `Something went wrong! Try again later`,
          status: "error",
          isClosable: true,
          position: "top-right",
          duration: 3000,
        });
        return;
      }
      toast({
        title: `Data submitted successfully`,
        position: "top-right",
        isClosable: true,
        status: "success",
        duration: 3000,
      });
    }
  };

  const handleAnswerSubmit = (selectedOption) => {
    setUserAnswers((prevAnswers) => {
      const updatedAnswers = [...prevAnswers];
      updatedAnswers[currentQuestionIndex] = selectedOption;
      return updatedAnswers;
    });
    setLoading(true);

    setTimeout(() => {
      setLoading(false);

      if (currentQuestionIndex + 1 < questions.length) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        setIsLastQue(true);
      }
    }, 500);

    if (
      selectedOption !== userAnswers[currentQuestionIndex] &&
      currentQuestionIndex >= questionsAttended
    ) {
      setQuestionsAttended(questionsAttended + 1);
    }
    setSubmitData((prevData) => {
      const updatedQuestions = prevData.question;
      const currentQuestion = questions[currentQuestionIndex];
      const answerKey = currentQuestion.answer.toLowerCase();
      const correctAnswer = currentQuestion[`option${answerKey}`];

      updatedQuestions[currentQuestionIndex] = {
        Question: currentQuestion.question_text,
        UserSelectedAnswer: selectedOption,
        CorrectAnswer: correctAnswer,
        IsCorrect: selectedOption === correctAnswer,
      };

      return {
        ...prevData,
        question: updatedQuestions,
        max_score:
          prevData.max_score < totalCorrectAnswers * 5
            ? totalCorrectAnswers * 5
            : prevData.max_score,
      };
    });
  };

  console.log(isDataLoading);

  const totalCorrectAnswers = questions?.reduce((count, question, index) => {
    let answerKey = (question?.answer).toLowerCase();
    let selectedAnswer = question[`option${answerKey}`];
    return userAnswers[index] === selectedAnswer ? count + 1 : count;
  }, 0);

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const renderQuizQuestion = () => {
    const currentQuestion = questions[currentQuestionIndex];

    return isDataLoading ? (
      <Box>
        <Center mt={7}>
          <Spinner color="purple.500" />
        </Center>
      </Box>
    ) : (
      <Box maxW={"75%"}>
        <Text fontSize={"lg"}>{currentQuestion?.question_text}</Text>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box display={"flex"} flexDir={"column"} gap={1}>
            <Text fontWeight={"semibold"} margin={0}>
              From : {currentQuestion?.bookname}
            </Text>
            <Text fontWeight={"semibold"} margin={0}>
              Subject : {currentQuestion?.subject}
            </Text>
          </Box>
          <Box>
            <Text fontWeight={"semibold"} margin={0}>
              Year : {currentQuestion?.year}
            </Text>
          </Box>
        </Box>
        <Box display={"flex"} flexDir={"column"}>
          {["optiona", "optionb", "optionc", "optiond"]?.map(
            (optionKey, index) => (
              <QuizOption
                key={index}
                option={currentQuestion[optionKey]}
                isSelected={
                  userAnswers[currentQuestionIndex] ===
                  currentQuestion[optionKey]
                }
                onClick={() => handleAnswerSubmit(currentQuestion[optionKey])}
              />
            )
          )}
        </Box>
        {currentQuestionIndex > 0 && (
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Button
              onClick={handlePreviousQuestion}
              variant="outline"
              disabled={isLoading}
              mt={2}
            >
              Previous
            </Button>
            <Text fontSize="md">
              Questions Attended:
              <b>
                {questionsAttended}/{questions?.length}
              </b>
            </Text>
          </Box>
        )}
      </Box>
    );
  };

  const renderQuizCompletion = () => {
    // Calculate the total number of correct answers

    // setQuestionCorrect(totalCorrectAnswers);

    return (
      <Box maxW={"70%"}>
        <Text fontSize="lg">
          Thank you for completing the quiz! Here are your answers:
        </Text>
        <Text fontSize="lg">
          Questions Attended: {questionsAttended} out of {questions?.length}{" "}
          (Total Correct Answers: {totalCorrectAnswers})
        </Text>
        <ul>
          {questions?.map((question, index) => {
            let answerKey = (question?.answer).toLowerCase();
            let selectedAnswer = question[`option${answerKey}`];
            return (
              <li key={index}>
                {question?.question_text}:{" "}
                {userAnswers[index] !== selectedAnswer ? (
                  <>
                    <b style={{ color: "red" }}>{userAnswers[index]}</b>{" "}
                    (Correct Answer: <b>{selectedAnswer}</b>)
                  </>
                ) : (
                  <b>({userAnswers[index]})</b>
                )}
              </li>
            );
          })}
        </ul>
      </Box>
    );
  };

  return (
    <Box
      display={"flex"}
      flexDir={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      {questions?.length > 0 &&
        (isQuizCompleted === false
          ? renderQuizQuestion()
          : renderQuizCompletion())}
      {questions?.length > 0 && isQuizCompleted === false && (
        <Button
          mt={4}
          size="sm"
          backgroundColor={"purple.100"}
          borderWidth={"2px"}
          borderColor={"purple.200"}
          fontWeight={"bold"}
          letterSpacing={"1px"}
          _hover={{
            backgroundColor: "purple.50",
          }}
          _active={{
            transform: "translateY(4px)",
          }}
          onClick={handleSubmit}
        >
          {isLoadingpost ? (
            <Spinner size={"sm"} color="purple.500" />
          ) : (
            "Submit"
          )}
        </Button>
      )}
    </Box>
  );
};

export default MockPaperTest;
