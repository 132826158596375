import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormLabel,
  Input,
  Box,
  Textarea,
} from "@chakra-ui/react";

const AddEventsModal = ({ isOpen, onClose, handleAddRoom }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        borderTopColor={"purple.600"}
        borderTopRadius={"8px"}
        borderTopWidth={"6px"}
      >
        <ModalHeader>Add Event</ModalHeader>
        <ModalCloseButton backgroundColor={"purple.100"} rounded={"full"} />
        <ModalBody>
          <Box display={"flex"} flexDir={"column"} gap={5} as="form">
            <Box>
              <FormLabel>Event name:</FormLabel>
              <Input required placeholder="Technology Conference" />
            </Box>
            <Box>
              <FormLabel>Event Schedule:</FormLabel>
              <Input required type="datetime-local" />
            </Box>
            <Box>
              <FormLabel>Describtion:</FormLabel>

              <Textarea
                required
                placeholder="what will we do on this event ..."
                resize="vertical"
              />
            </Box>

            <Button
              type="submit"
              marginY={"12px"}
              backgroundColor={"purple.500"}
              color={"white"}
              onClick={handleAddRoom}
              _hover={{
                backgroundColor: "purple.100",
                color: "black",
                borderWidth: "1px",
                borderColor: "purple.600",
              }}
            >
              Add Event
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddEventsModal;
