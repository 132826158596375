import React, {useState, useEffect} from "react";
import Joyride, { STATUS } from "react-joyride";
import { Link } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button, Center,
  Container,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Select, Tab, TabList, TabPanel, TabPanels, Tabs,
  Tag,
  VStack,
  Wrap,
  WrapItem
} from "@chakra-ui/react";
import H2 from "../../Components/Typography/Heading/H2";
import H3 from "../../Components/Typography/Heading/H3";
import Psm from "../../Components/Typography/Text/Psm";
import Pmd from "../../Components/Typography/Text/Pmd";
import ButtonPrimary from "../../Components/Button/ButtonPrimary";

import {AiOutlineArrowRight} from "react-icons/ai";
import {PiFunnelLight} from "react-icons/pi";
import {FaCircle} from "react-icons/fa";

import axios from "axios";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { NEW_API_URL } from "../../redux/types";
import { useNavigate } from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";
import {setNewUser} from "../../redux/actions/userActions";
import {capsFirst} from "../../Components/utils";
// import ChakraCarousel from "../../Components/ChakraCarousel";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Slider from "react-slick";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";
import HeadCard from "../../Components/HeadCard";
import Pxl from "../../Components/Typography/Text/Pxl";
import P2xl from "../../Components/Typography/Text/P2xl";
import {ArrowBackIcon} from "@chakra-ui/icons";
import TopicCard from "../../Components/TopicCard";
import {news_map_data} from "../../data/new_generated/news_map";
import Plg from "../../Components/Typography/Text/Plg";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import EmptyImage from "../../Components/EmptyImage";
import { useSearchParams } from "react-router-dom";
import InputOutline from "../../Components/Input/InputOutline";

const News = () => {
  const [startDate, setStartDate] = useState(new Date());
  function compareDatesByDay(date1, date2) {
    const day1 = date1.getFullYear() + "-" + (date1.getMonth() + 1) + "-" + date1.getDate();
    const day2 = date2.getFullYear() + "-" + (date2.getMonth() + 1) + "-" + date2.getDate();
    return day1 === day2;
  }

  function storeDate(key, date) {
    localStorage.setItem(key, date.toISOString());
  }

  function fetchDate(key) {
    const dateStr = localStorage.getItem(key);
    return dateStr ? new Date(dateStr) : null;
  }

  useEffect(() => {
    if(localStorage.getItem('news_date')){
      setStartDate(fetchDate('news_date'))
    }
  }, []);


  useEffect(() => {
    if(!compareDatesByDay(new Date(),startDate)){
      storeDate('news_date',startDate)
    }
  }, [startDate]);


  const [dateFilter, setDatefilter] = useState('7');
  const [showTrendingFilter, setShowTrendingFilter] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [trendingPage, setTrendingPage] = useState('cluster');
  const [savedNewsType, setSavedNewsType] = useState('news');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const newUser = useSelector(state => state.user.newUser);
  const username = useSelector(state => state.user.username);
  const selectedWorkspace = useSelector(state => state.user.selectedWorkspace);
  const user = useSelector(state => state.user);
  let workspaceId = '';
  if(selectedWorkspace && selectedWorkspace.hasOwnProperty('workspace_id')){
    workspaceId = selectedWorkspace.workspace_id
  }


  const [searchParams,setSP] = useSearchParams();

  // Get a specific query parameter
  const trending_news_tab = searchParams.get("trending"); // 'myParam' is the key of the query param
  const saved = searchParams.get("saved"); // 'myParam' is the key of the query param
  const [tabIndex, setTabIndex] = useState(0); // State to track active tab

  useEffect(()=>{
    if(trending_news_tab){
      setTab("trending")
      setTabIndex(1)
    }
  },[trending_news_tab])

  useEffect(()=>{
    if(saved){
      setTab("saved")
      setTabIndex(2)
    }
  },[saved])


  useEffect(() => {
    if(!tabIndex && tabIndex!==0)
    return
    // if(tabIndex===0){
    //   searchParams.delete("saved");
    //   setSP(searchParams);
    //   searchParams.delete("trending");
    console.log(tabIndex)
      setSP({});
    // }
    if(tabIndex === 1){
      searchParams.delete("saved");
      setSP(searchParams);
      searchParams.set("trending","1");
      setSP(searchParams);
    }
    if(tabIndex === 2){
      searchParams.set("saved","1");
      setSP(searchParams);
      searchParams.delete("trending");
      setSP(searchParams);
    }
  }, [tabIndex]);



  function getFormattedDate(dt) {
    const today = dt;
    const year = today.getFullYear();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed

    return `${year}-${month}-${day}`;
    // return '2024-09-26'
  }

  useEffect(() => {
    getDailyNews(username)
  }, [startDate]);

  const getDailyNews = async (username) => {
    setIsLoading(true);
    const r = await axios.get(NEW_API_URL+'feed/daily-news/'+getFormattedDate(startDate)+'/'+username).catch(err=>{
      setDailyNews([])
      setdailyQuiz([])
    })

    // setTopicDetail(r.data.topic_detail)
    // setTimelineData(r.data)
    console.log(r?.data,'timeline')
    if(r?.data?.NewsArticles)
    {
      r.data.NewsArticles.sort((a, b) => {
        const priorityOrder = { High: 1, Medium: 2, Low: 3 };
        return priorityOrder[a.priority] - priorityOrder[b.priority];
      });
      setDailyNews(r.data.NewsArticles)
    }
    else {
      setDailyNews([])
    }

    if(r?.data?.DailyQuiz){
      setdailyQuiz(r.data.DailyQuiz)
    }
    setIsLoading(false);
    return r?.data
  }
  const getSavedNews = async (username) => {
    setIsLoading(true);
    const r = await axios.get(NEW_API_URL+'feed/get_saved_news/'+username).catch(err=>{})
    setIsLoading(false);
    // setTopicDetail(r.data.topic_detail)
    // setTimelineData(r.data)
    console.log(r?.data,'timeline')
    if(r?.data) {
      setSavedNews(r.data)
      setSavedNewsOriginal(r.data)
    }

    return r?.data
  }

  const getTrendingNews = async (username) => {
    setIsLoading(true);
    const r = await axios.get(NEW_API_URL+'feed/trending-news/'+username+'/'+dateFilter).catch(err=>{})
    setIsLoading(false);
    // setTopicDetail(r.data.topic_detail)
    // setTimelineData(r.data)
    console.log(r.data,'trending')
    if(r?.data){
      r.data[0].clusters.sort((a, b) => {
        const priorityOrder = { High: 1, Medium: 2, Low: 3 };
        return priorityOrder[a.priority] - priorityOrder[b.priority];
      });
      setClusters(r.data[0].clusters)
      setClustersOriginal(r.data[0].clusters)
      setTrendingNews([])
      setSelectedCluster([])
      setTrendingNewsOriginal([])

    }
    return r?.data
  }

  useEffect(()=>{
    // getDailyNews(username)
    getSavedNews(username)
    getSavedNotesData(username)
  },[])


  useEffect(()=>{
    getDailyNews(username)
    // getSavedNews(username)
  },[startDate])

  useEffect(()=>{
    getTrendingNews(username)
  },[dateFilter])

  const [dailyNews,setDailyNews] = useState([]);
  const [dailyQuiz,setdailyQuiz] = useState([]);
  const [tredingNews,setTrendingNews] = useState([]);
  const [trendingNewsOriginal,setTrendingNewsOriginal] = useState([]);
  const [clusters,setClusters] = useState([]);
  const [clustersOriginal,setClustersOriginal] = useState([]);
  const [saved_news,setSavedNews] = useState([]);
  const [saved_newsOriginal,setSavedNewsOriginal] = useState([]);
  const [selectedCluster,setSelectedCluster] = useState([]);
  const [tab,setTab] = useState('daily_news');
  const [relatedSavedNotes,setRelatedSavedNotes] = useState([]);


  // Function to change the tab index
  const changeTab = (index) => {
    setTabIndex(index);
  };


  const getSavedNotesData = async (username) => {
    try {
      const res = await axios.get(
          NEW_API_URL+`feed/get_read_news/${username}/`
      );
      const allRelatedNotes = res?.data.flatMap(item =>
          item.news_articles.flatMap(article => article.related_notes)
      );
      setRelatedSavedNotes(allRelatedNotes)
      console.log(allRelatedNotes,'relnot')
      return allRelatedNotes;
    } catch (error) {
      console.log("Error fetching saved notes data:", error);
      throw error;
    }
  };




  return (
      <>
        <Container  justifyContent={'center'} alignItems={'center'} w={'90%'} maxW='container.xl' >
          <Flex flexDirection={'column'} alignItems={'space-between'} justifyContent={'space-between'} minH={'95vh'}>
            <Box>
              <Flex mt={'10vh'} alignItems={'center'}>
                {/*<ArrowBackIcon cursor={'pointer'} onClick={()=>{navigate(-1)}} marginRight={'20px'} boxSize={'45px'}/>*/}

                <Box>
                  <P2xl mb={0} ml={0} mr={0}  >
                    <strong>News</strong>

                  </P2xl>
                </Box>
              </Flex>









              <Tabs index={tabIndex} onChange={(index) => setTabIndex(index)}  align={'center'}>
                <TabList>
                  <Tab onClick={()=>{setTab("daily_news")}}>
                    <Pxl style={{fontWeight:tab=="daily_news"?'600':'300'}} ml={'30px'} mr={'30px'} cursor={'pointer'}  color={tab=="daily_news"?'#0962A2':'black'}>Daily</Pxl>


                  </Tab>
                  <Tab onClick={()=>{setTab("trending")}}>
                    <Pxl style={{fontWeight:tab=="trending"?'600':'300'}} ml={'30px'} mr={'30px'} cursor={'pointer'}  color={tab=="trending"?'#0962A2':'black'}>Trending</Pxl>


                  </Tab><Tab  onClick={()=>{setTab("saved")}}>
                  <Pxl style={{fontWeight:tab=="saved"?'600':'300'}} ml={'30px'} mr={'30px'} cursor={'pointer'} color={tab=="saved"?'#0962A2':'black'}>Saved</Pxl>


                </Tab>
                </TabList>

                <TabPanels>
                  <TabPanel>





                    <Flex marginBottom={'16px'} justifyContent={'space-between'} width={'90%'}>
                      <Flex alignItems={'center'}>
                        <Pmd mb={0} mr={'12px'}>Priority: </Pmd>
                        <Flex alignItems={'center'} mr={'10px'}>
                          <Box borderRadius={'50%'} backgroundColor={'#5F79D7'} minW={'16px'} minH={'16px'} mr={'6px'}>
                          </Box>
                          <Psm mb={0}>Low</Psm>
                        </Flex>
                        <Box display={'flex'} alignItems={'center'} mr={'10px'}>
                          <Box borderRadius={'50%'} backgroundColor={'#F4B400'} minW={'16px'} minH={'16px'} mr={'6px'}></Box>
                          <Psm mb={0}>Medium</Psm>
                        </Box>
                        <Box display={'flex'} alignItems={'center'}>
                          <Box borderRadius={'50%'} backgroundColor={'#FF0E36'} minW={'16px'} minH={'16px'} mr={'6px'}></Box>
                          <Psm mb={0}>High</Psm>
                        </Box>
                      </Flex>
                      <Flex zIndex={3} className="date-picker-container-1" bgColor={'white'} borderRadius={'30px'} height={'20px'} padding={'20px'} alignItems={'center'} justifyContent={'center'} border={'1px'}>
                        <Image marginRight={'20px'} src={'/calendar.png'} width={'20px'} height={'20px'}></Image>
                        <DatePicker style={{width:'20px',zIndex:2}} selected={startDate} onChange={(date) => setStartDate(date)} />
                      </Flex>
                    </Flex>
                    {/* <HeadCard marginBottom={'20px'} border={'1px'} shadow paddingRight={'10px'} paddingLeft={'10px'} paddingBottom={'30px'}> */}
                    {isLoading ?
                     (
                      <>
                     <Pmd>Loading...</Pmd>
                     </>
                     )
                     :
                     dailyNews.length <= 0 ?
                     <EmptyImage message={'No daily news available for the date'}/>
                     :
                     <Flex justifyContent={'space-around'}>
                        <HeadCard flex={'0.6'} title={'News Articles'} border={ (dailyNews.length>0) ? '0px' : '1px'} marginBottom={'20px'} shadow
                                  paddingBottom={'30px'}>
                          <Container maxW={'97%'} mt={'40px'}>
                            {
                                dailyNews.length > 0 && dailyNews.map((item, index) => {

                                  return <>
                                    <Link to={'/news/' + item.id}>


                                      <Flex alignItems={'center'} justifyContent={'space-between'}>
                                        <Pmd width={'80%'} textAlign={'left'} margin={0}>{item.title}</Pmd>

                                        {item.priority === 'Low' && <Box borderRadius={'30px'} backgroundColor={'#5F79D7'} minW={'20px'}
                                              minH={'20px'}></Box>}
                                        {item.priority === 'Medium' && <Box borderRadius={'30px'} backgroundColor={'#F4B400'} minW={'20px'}
                                                                         minH={'20px'}></Box>}
                                        {item.priority === 'High' && <Box borderRadius={'30px'} backgroundColor={'#FF0E36'} minW={'20px'}
                                                                            minH={'20px'}></Box>}

                                        <Image src={'/go_button.png'} h={'27px'} w={'27px'}></Image>
                                      </Flex>
                                      <hr/>
                                    </Link>
                                  </>
                                })

                            }
                          </Container>
                        </HeadCard>
                        <HeadCard flex={'0.2'} title={'News tests'} border={'1px'} marginBottom={'20px'} shadow paddingRight={'10px'}
                                  paddingBottom={'30px'}>
                          <Flex ml={'12px'} pb={'15px'} flexWrap={'wrap'}>
                            {dailyQuiz?.length <= 0 && <Pmd mt={'48px'}>No news tests available for this date</Pmd>}
                            {
                              dailyQuiz.map((item, index) => (
                                  <Link to={'/tests/'+item.quiz_id}>
                                  <Box position={'relative'} mr={'15px'} mt={'30px'} w={'200px'} p={'12px'}
                                       boxShadow={'0px 3px 3px 0px #00000040'}
                                       borderRadius={'12px'} borderLeft={'4px solid #2A6291'}>
                                    {item.read_status === 'Read' &&
                                        <Image style={{position: 'absolute', right: '10px', top: '10px'}}
                                               src={'/read_tick.png'} height={'20px'} width={'20px'}/>}


                                    <Flex alignItems={'center'} mt={4}>
                                      <Image height={'64px'} width={'53px'} src={'/news_test.png'}></Image>
                                      {item.read_status==='read' && <Plg margin={0} pl={'20px'}>{item.score} / {item.max_score}</Plg>}
                                    </Flex>
                                    <Psm textAlign={'left'} mt={4}>{item.title}</Psm>
                                  </Box>
                                  </Link>
                              ))
                            }


                          </Flex>
                        </HeadCard>
                      </Flex>
                      }

                    {/* </HeadCard> */}


                    {/*<HeadCard title={'News Articles'}  paddingRight={'10px'} paddingBottom={'30px'}>*/}


                    {/*  <Slider {...settings} draggable={true} arrows={false}>*/}
                    {/*    {*/}
                    {/*      dailyNews.map((item) => {*/}
                    {/*        return (<>*/}
                    {/*          <Link to={'/news/'+item.id}>*/}
                    {/*            <Box  backgroundColor={'#ededed'} borderRadius={'12px'} overflow={'hidden'} h={'380px'}*/}
                    {/*                 w={'300px'} boxShadow={'0px 1px 2px 0px #00000040'} border={'1px solid #FFFFFF80'}*/}
                    {/*                 mt={'15px'}>*/}

                    {/*              <Image src={item.icon} w={'100%'}></Image>*/}

                    {/*              <Flex alignItems={'center'} justifyContent={'space-around'}>*/}
                    {/*                <Box>*/}

                    {/*                  <Pmd textAlign={'left'} pt={'14px'} margin={0}><strong>{item.title}</strong></Pmd>*/}
                    {/*                  <Psm textAlign={'left'} >{item.summary}</Psm>*/}
                    {/*                </Box>*/}
                    {/*                {item.read_status === 'Read' && <Image src={'/tick_news.png'} h={'20px'} w={'20px'}></Image>}*/}

                    {/*              </Flex>*/}


                    {/*            </Box>*/}
                    {/*          </Link>*/}
                    {/*        </>)*/}
                    {/*      })*/}
                    {/*    }*/}


                    {/*  </Slider>*/}


                    {/*</HeadCard>*/}

                    {/*<HeadCard title={"News test"} mt={'30px'}>*/}

                    {/*  <Flex ml={'12px'} pb={'15px'}>*/}

                    {/*    {*/}
                    {/*      dailyQuiz.map((item,index)=>(*/}
                    {/*          <Box position={'relative'} mr={'15px'} mt={'30px'} w={'200px'} p={'12px'} boxShadow={'0px 3px 3px 0px #00000040'}*/}
                    {/*               borderRadius={'12px'} borderLeft={'4px solid #2A6291'}>*/}
                    {/*            {item.read_status === 'Read' && <Image style={{position:'absolute',right:'10px',top:'10px'}} src={'/read_tick.png'} height={'20px'} width={'20px'}/>}*/}

                    {/*            <Psm>{item.title}</Psm>*/}
                    {/*            <Flex alignItems={'center'}>*/}
                    {/*              <Image height={'64px'} width={'53px'} src={'/news_test.png'}></Image>*/}
                    {/*              <Plg margin={0} pl={'20px'}>{item.score} / 10</Plg>*/}
                    {/*            </Flex>*/}
                    {/*          </Box>*/}
                    {/*      ))*/}
                    {/*    }*/}




                    {/*  </Flex>*/}


                    {/*</HeadCard>*/}
                  </TabPanel>
                  <TabPanel>
                    <Container maxWidth={'70%'} mt={'2px'}>

                      <InputOutline
                          type="text"
                          border={'1px solid #B7B7B7'}
                          name="search"
                          placeholder={"Search"}
                          w={'100%'}
                          mb={'16px'}
                          onChange={(e)=>{
                            console.log(e.target.value,tredingNews,tredingNews.length <=0,tredingNews.length>0,trendingNewsOriginal,e.target.value.length)
                            if(trendingPage==='cluster') {
                              setClusters(clustersOriginal.filter((i)=>{
                                return i.title.toLowerCase().includes( e.target.value)
                              }))
                            }
                            if(trendingPage==='trending'){

                              if(e.target.value.length <=0){
                                setTrendingNews(trendingNewsOriginal)
                              }
                              else
                              setTrendingNews(trendingNewsOriginal.filter((i)=>{
                                return i.title.toLowerCase().includes( e.target.value)
                              }))
                            }

                          }}
                      />


                      <HeadCard selectChange={(e)=>{
                        // console.log(e.target.value)
                        setDatefilter(e.target.value)
                      }} select={showTrendingFilter} shadow border back={()=>{
                        setTrendingNews([]);setShowTrendingFilter(true);setSelectedCluster({})
                        setTrendingPage('cluster');setTrendingNewsOriginal([]);

                      }} showBack={trendingPage==='trending'} title={selectedCluster.title || 'News Topics'} pb={'12px'}>
                        <Container maxW={'97%'} mt={'32px'}>

                          {
                              clusters && clusters.length <= 0 && <EmptyImage message={'No trending news available yet'}/>
                          }




                          {
                              trendingPage==='cluster' && clusters && clusters.map((item,index)=>{

                              return <>
                                {/*<Link to={'/news/'+item.news_id}>*/}

                                  <Flex cursor={'pointer'}
                                  onClick={()=>{
                                    setTrendingNews(
                                    item.related_news.sort((a, b) => {
                                      const priorityOrder = { High: 1, Medium: 2, Low: 3 };
                                      return priorityOrder[a.priority] - priorityOrder[b.priority];
                                    })
                                    );
                                    setSelectedCluster(item);
                                    setTrendingNewsOriginal(item.related_news.sort((a, b) => {
                                      const priorityOrder = { High: 1, Medium: 2, Low: 3 };
                                      return priorityOrder[a.priority] - priorityOrder[b.priority];
                                    }))
                                    setTrendingPage('trending')
                                    setShowTrendingFilter(false);
                                    }} alignItems={'center'} justifyContent={'space-between'}>
                                    <Box width={'80%'}>
                                    <Pmd width={'100%'} textAlign={'left'} margin={0}>{item.title}</Pmd>
                                    <Psm width={'100%'} color={'gray'} textAlign={'left'} margin={0}>{item.related_news.length} news articles</Psm>
                                    </Box>
                                    {item.priority === 'Low' && <Box borderRadius={'30px'} backgroundColor={'#5F79D7'} minW={'20px'}
                                                                     minH={'20px'}></Box>}
                                    {item.priority === 'Medium' && <Box borderRadius={'30px'} backgroundColor={'#F4B400'} minW={'20px'}
                                                                        minH={'20px'}></Box>}
                                    {item.priority === 'High' && <Box borderRadius={'30px'} backgroundColor={'#FF0E36'} minW={'20px'}
                                                                      minH={'20px'}></Box>}
                                    <Image    src={'/go_button.png'} h={'27px'} w={'27px'}></Image>
                                  </Flex>
                                  <hr/>
                                {/*</Link>*/}
                              </>
                            })

                          }
                        </Container>

                        <Container maxW={'97%'} mt={'20px'}>
                          {
                              trendingPage==='trending' && tredingNews.map((item,index)=>{

                                return <>
                                  <Link to={'/news/'+item.news_id}>

                                  <Flex  alignItems={'center'} justifyContent={'space-between'}>
                                    <Pmd width={'80%'} textAlign={'left'} margin={0}>{item.title}</Pmd>

                                    {item.priority === 'Low' && <Box borderRadius={'30px'} backgroundColor={'#5F79D7'} minW={'20px'}
                                                                     minH={'20px'}></Box>}
                                    {item.priority === 'Medium' && <Box borderRadius={'30px'} backgroundColor={'#F4B400'} minW={'20px'}
                                                                        minH={'20px'}></Box>}
                                    {item.priority === 'High' && <Box borderRadius={'30px'} backgroundColor={'#FF0E36'} minW={'20px'}
                                                                      minH={'20px'}></Box>}
                                    <Image src={'/go_button.png'} h={'27px'} w={'27px'}></Image>
                                  </Flex>
                                  <hr/>
                                  </Link>
                                </>
                              })

                          }
                        </Container>

                      </HeadCard>
                    </Container>
                  </TabPanel>
                  <TabPanel>
                    <Container maxWidth={'70%'} mt={'2px'}>

                      <InputOutline
                          type="text"
                          border={'1px solid #B7B7B7'}
                          name="search"
                          placeholder={"Search"}
                          w={'100%'}
                          mb={'12px'}
                          onChange={(e)=>{

                            setSavedNews(saved_newsOriginal.filter((i)=>{
                              return i.title.toLowerCase().includes( e.target.value)
                            }))
                            //
                            // setSavedNews(saved_newsOriginal.filter(i=>i.toLowerCase()))

                          }}
                      />
                      <HeadCard  pb={'12px'} border shadow>
                        <Container maxW={'97%'} mt={'20px'}>



                          <Flex mb={'15px'}>

                          <ButtonPrimary
                              onClick={() => {
                                // setNoteType("My Notes");
                                setSavedNewsType('news')
                                // setNotesDetail(topicDetail.related_notes);
                              }}
                              bg={
                                savedNewsType === "news"
                                    ? "claydeck.brand.primary"
                                    : "transparent"
                              }
                              mr={"16px"}
                              mb={'24px'}
                              color={savedNewsType === "news" ? "white" : "#0962A2"}
                              border={"1px solid #0962A2"}
                              w={'180px'}
                          >
                            News
                          </ButtonPrimary>
                          <ButtonPrimary
                              onClick={() => {
                                // setNoteType("My Notes");
                                setSavedNewsType('notes')
                                // setNotesDetail(topicDetail.related_notes);
                              }}
                              bg={
                                savedNewsType === "notes"
                                    ? "claydeck.brand.primary"
                                    : "transparent"
                              }
                              mr={"15px"}
                              w={'180px'}

                              color={savedNewsType === "notes" ? "white" : "#0962A2"}
                              border={"1px solid #0962A2"}
                          >
                            Notes
                          </ButtonPrimary>

                          </Flex>

                          {
                              saved_news && saved_news.length <= 0 && <EmptyImage message={'No saved news available yet'}/>
                          }

                          {
                              savedNewsType === "news" && saved_news && saved_news.length > 0 && saved_news.map((item,index)=>{

                              return <>
                                <Link to={'/news/'+item.id}>

                                  <Flex alignItems={'center'} justifyContent={'space-between'}>
                                    <Pmd width={'80%'} textAlign={'left'} margin={0}>{item.title}</Pmd>

                                    {item.priority === 'Low' && <Box borderRadius={'30px'} backgroundColor={'#5F79D7'} minW={'20px'}
                                                                     minH={'20px'}></Box>}
                                    {item.priority === 'Medium' && <Box borderRadius={'30px'} backgroundColor={'#F4B400'} minW={'20px'}
                                                                        minH={'20px'}></Box>}
                                    {item.priority === 'High' && <Box borderRadius={'30px'} backgroundColor={'#FF0E36'} minW={'20px'}
                                                                      minH={'20px'}></Box>}
                                    <Image src={'/go_button.png'} h={'27px'} w={'27px'}></Image>
                                  </Flex>
                                  <hr/>
                                </Link>
                              </>
                            })

                          }
                          {
                              savedNewsType === "notes" && relatedSavedNotes && relatedSavedNotes.length > 0 && relatedSavedNotes.map((item,index)=>{

                                return <>
                                  <Link to={'/learn/mynote/mynote/mynote/notes/'+item.id+'?mynote=1'}>

                                    <Flex alignItems={'center'} justifyContent={'space-between'}>
                                      <Pmd width={'80%'} textAlign={'left'} margin={0}>{item.title}</Pmd>

                                      {item.priority === 'Low' && <Box borderRadius={'30px'} backgroundColor={'#5F79D7'} minW={'20px'}
                                                                       minH={'20px'}></Box>}
                                      {item.priority === 'Medium' && <Box borderRadius={'30px'} backgroundColor={'#F4B400'} minW={'20px'}
                                                                          minH={'20px'}></Box>}
                                      {item.priority === 'High' && <Box borderRadius={'30px'} backgroundColor={'#FF0E36'} minW={'20px'}
                                                                        minH={'20px'}></Box>}
                                      <Image src={'/go_button.png'} h={'27px'} w={'27px'}></Image>
                                    </Flex>
                                    <hr/>
                                  </Link>
                                </>
                              })

                          }
                        </Container>

                      </HeadCard>
                    </Container>
                  </TabPanel>
                </TabPanels>
              </Tabs>



            </Box>
          </Flex>
        </Container>
      </>

  );
}

export default News;
