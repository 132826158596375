import { Box, Text, useToast, Center, Spinner } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import useGetSubTest from "../utils/getSubjectiveTest";
import { useDispatch } from "react-redux";
import { SUBJECTIVE_QUESTIONS } from "../../../../redux/types";

const SubjectiveTestComp = () => {
  const dispatch = useDispatch();

  const { fetchSubTest, isLoading, setIsLoading, error } = useGetSubTest();

  const [questions, setQuestions] = useState([]);

  const toast = useToast();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const res = await fetchSubTest("rahulk30s@gmail.com");
      setQuestions(res);
      dispatch({
        type: SUBJECTIVE_QUESTIONS,
        payload: res,
      });
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const errorToast = () => {
    toast({
      title: `Something went wrong ! Try again later`,
      status: "error",
      isClosable: true,
      position: "top-right",
      duration: 3000,
    });

    return "Questions not found !!";
  };

  console.log("err", Boolean(error));
  return (
    <Box
      display={"flex"}
      width={"full"}
      flexDir={"column"}
      padding={8}
      margin={8}
      gap={4}
    >
      <Box width={"full"}>
        <Box
          backgroundColor={"purple.100"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          padding={2}
          borderRadius={"md"}
          borderWidth={"2px"}
          borderTopColor={"purple.300"}
          boxShadow={"md"}
        >
          <Text
            fontSize={"xl"}
            fontWeight={"bold"}
            letterSpacing={"2px"}
            margin={"0"}
          >
            Subjective Test
          </Text>
        </Box>
      </Box>
      <Box>
        <Text fontSize={"lg"} color={"purple.400"} fontWeight={450} mt={"8px"}>
          Choose the question you want to answer :
        </Text>
      </Box>
      {!isLoading ? (
        <Box>
          {!error
            ? questions?.map((que, i) => {
                return (
                  <Text
                    transition={"all"}
                    transitionDuration={"300ms"}
                    transitionDelay={"initial"}
                    key={i}
                    padding={1}
                    _hover={{
                      backgroundColor: "purple.100",
                      color: "purple.400",
                      borderRadius: "md",
                      cursor: "pointer",
                      // transform: "scale(1.05)",
                      padding: "10px",
                    }}
                  >
                    <a href={`/library/subjective-test/${i}`}>{que}</a>
                  </Text>
                );
              })
            : errorToast()}
        </Box>
      ) : (
        <Box>
          <Center mt={7}>
            <Spinner color="purple.500" />
          </Center>
        </Box>
      )}
    </Box>
  );
};

export default SubjectiveTestComp;
