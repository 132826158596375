import React, { useState, useEffect } from "react";
import { Box, Text, Button, useColorModeValue } from "@chakra-ui/react";

import { datadogLogs } from '@datadog/browser-logs';

let t1 =  Math.floor(Date.now());

const QuizOption = ({ option, isSelected, onClick }) => {
  const buttonBg = useColorModeValue("transparent", "purple.500");
  const buttonColor = useColorModeValue("purple.500", "white");

  return (
    <Button
      onClick={onClick}
      variant="outline"
      disabled={isSelected}
      mt={2}
      bg={isSelected ? "purple.500" : buttonBg}
      color={isSelected ? "white" : buttonColor}
      borderColor="purple.500"
      _hover={{
        bg: "purple.500",
        color: "white",
      }}
    >
      {option}
    </Button>
  );
};

const NewsQuizBox = ({ questions, isQuizCompleted, setQuizCompleted }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [timer, setTimer] = useState(15);
  const [questionsAttended, setQuestionsAttended] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);

      if (timer === 0) {
        clearInterval(interval);
        setQuizCompleted(true);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  const handleAnswerSubmit = (selectedOption) => {
    setUserAnswers((prevAnswers) => {
      const updatedAnswers = [...prevAnswers];
      updatedAnswers[currentQuestionIndex] = selectedOption;
      return updatedAnswers;
    });
    setLoading(true);

    setTimeout(() => {
      setLoading(false);

      if (currentQuestionIndex + 1 < questions.length) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        setQuizCompleted(true);
      }
    }, 1000);

    if (selectedOption !== userAnswers[currentQuestionIndex]) {
      setQuestionsAttended(questionsAttended + 1);
    }

    datadogLogs.logger.info('News Quiz', {name: 'News Quiz answers submitted', id: 123, responseTime: t1});

  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const renderQuizQuestion = () => {
    const currentQuestion = questions[currentQuestionIndex];

    return (
      <Box maxW={"70%"}>
        <Text fontSize={"xl"}>{currentQuestion.question}</Text>
        <Box display={"flex"} flexDir={"column"}>
          {["optiona", "optionb", "optionc", "optiond"].map(
            (optionKey, index) => (
              <QuizOption
                key={index}
                option={currentQuestion[optionKey]}
                isSelected={
                  userAnswers[currentQuestionIndex] ===
                  currentQuestion[optionKey]
                }
                onClick={() => handleAnswerSubmit(currentQuestion[optionKey])}
              />
            )
          )}
        </Box>
        {currentQuestionIndex > 0 && (
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Button
              onClick={handlePreviousQuestion}
              variant="outline"
              disabled={isLoading}
              mt={2}
            >
              Previous
            </Button>
            <Text fontSize="md">
              Questions Attended:{" "}
              <b>
                {questionsAttended}/{questions.length}
              </b>
            </Text>
          </Box>
        )}
        <Text mt={2}>{timer} seconds left</Text>
      </Box>
    );
  };

  const renderQuizCompletion = () => {
    // Calculate the total number of correct answers
    const totalCorrectAnswers = questions.reduce(
      (count, question, index) =>
        userAnswers[index] === question.answer ? count + 1 : count,
      0
    );

    return (
      <Box maxW={"70%"}>
        <Text fontSize="lg">
          Thank you for completing the quiz! Here are your answers:
        </Text>
        <Text fontSize="lg">
          Questions Attended: {questionsAttended} out of {questions.length}{" "}
          (Total Correct Answers: {totalCorrectAnswers})
        </Text>
        <ul>
          {questions.map((question, index) => (
            <li key={index}>
              {question.question}:{" "}
              {userAnswers[index] !== question.answer ? (
                <>
                  <b style={{ color: "red" }}>{userAnswers[index]}</b> (Correct
                  Answer: <b>{question.answer}</b>)
                </>
              ) : (
                <b>{userAnswers[index]}</b>
              )}
            </li>
          ))}
        </ul>
      </Box>
    );
  };

  return (
    <Box
      display={"flex"}
      flexDir={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      {isQuizCompleted ? renderQuizCompletion() : renderQuizQuestion()}
    </Box>
  );
};

export default NewsQuizBox;
