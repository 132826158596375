import { MaterialData } from "../../../data/data";
import MaterialBox from "./materialBox";
import MessageModalBox from "./msgModalBox";
import { ArrowDownIcon } from "@chakra-ui/icons";
import { useState } from "react";
import {
  Box,
  Button,
  Text,
  useMediaQuery,
  Image,
  Collapse,
} from "@chakra-ui/react";
import ParticipantMembers from "./participantMembers";
import { joinmemberData } from "../../../data/data";

const MobileSidebarItems = () => {
  const [isSmallDevice] = useMediaQuery("(max-width: 768px)");
  const [isMediumDevice] = useMediaQuery("(max-width: 992px)");
  const [msgModal, setMsgModal] = useState(false);

  const handleMsgModal = () => {
    setMsgModal(!msgModal);
  };
  return (
    <>
      {isSmallDevice && isMediumDevice && (
        <>
          <Box
            alignSelf={"start"}
            p="1"
            gap="4"
            w={"full"}
            marginBottom={"4px"}
          >
            <Text
              fontSize={"16px"}
              fontWeight={"500"}
              color={"rgba(74, 74, 74, 1)"}
              marginBottom={"8px"}
            >
              Materials & Question papers
            </Text>
            <MaterialBox data={MaterialData} />
          </Box>
        </>
      )}
      {isSmallDevice && isMediumDevice && (
        <>
          <Box
            marginBottom={"12px"}
            display="flex"
            alignItems="center"
            flexDirection="column"
            p="1"
            gap="4"
          >
            <Text fontSize={{ lg: "18px", base: "15px" }} fontWeight="semibold">
              Have anything to say?
            </Text>
            <Button
              onClick={handleMsgModal}
              fontSize={{ lg: "14px", base: "12px" }}
              fontWeight="500"
              maxWidth="auto"
              gap="2"
              paddingX={{ lg: "19px", base: "16px" }}
              paddingY={{ lg: "10px", base: "8px" }}
              backgroundColor="white"
              border="1.25px solid rgba(33, 0, 93, 1)"
              color="rgba(33, 0, 93, 1)"
            >
              <Image src="assets/icons/pencil.svg" />
              Write a Message
              <ArrowDownIcon
                transition={"0.25s ease-in-out"}
                transform={msgModal && "rotate(180deg)"}
              />
            </Button>
            <Collapse in={msgModal} animateOpacity>
              <MessageModalBox />
            </Collapse>
          </Box>
        </>
      )}
      <>
        {isSmallDevice && isMediumDevice && (
          <Box display={"flex"} w={"full"} flexDirection={"column"}>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              as="div"
              w={"full"}
              marginBottom={"5px"}
            >
              <Text
                fontSize={"16px"}
                fontWeight={"500"}
                color={"rgba(74, 74, 74, 1)"}
                marginBottom={"8px"}
              >
                Participants ({joinmemberData.length})
              </Text>
              <Image src="assets/icons/plusSign.svg" />
            </Box>
            <ParticipantMembers data={joinmemberData} />
          </Box>
        )}
      </>
    </>
  );
};

export default MobileSidebarItems;
