import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormLabel,
  Input,
  Box,
  RadioGroup,
  Stack,
  Radio,
  Image,
} from "@chakra-ui/react";

const AddRoomModal = ({ isOpen, onClose, handleAddRoom }) => {
  const [displayPicture, setDisplayPicture] = useState(null);
  const [roomName, seRoomName] = useState("");
  const [rooomType, setRoomtype] = useState(null);
  const [jsonData, setJsonData] = useState({});

  const handleSubmitAddroom = (e) => {
    e.preventDefault();
    setJsonData(() => {});
  };
  const handleDisplayPictureChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setDisplayPicture(file);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        borderTopColor={"purple.600"}
        borderTopRadius={"8px"}
        borderTopWidth={"6px"}
      >
        <ModalHeader>Add Room</ModalHeader>
        <ModalCloseButton backgroundColor={"purple.100"} rounded={"full"} />
        <ModalBody>
          <Box display={"flex"} flexDir={"column"} gap={5} as="form">
            <Box>
              <FormLabel>Room name:</FormLabel>
              <Input required placeholder="Coffee-room" onChange={() => {}} />
            </Box>
            <Box
              display={"flex"}
              alignItems={"flex-start"}
              flexDir={"column"}
              justifyContent={"center"}
            >
              <Box display={"flex"} alignItems={"center"} gap={"3"}>
                <FormLabel margin={"0"}>Display Picture:</FormLabel>
                <label
                  htmlFor="file-upload"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                  }}
                >
                  <Input
                    id="file-upload"
                    type="file"
                    accept="image/*"
                    onChange={handleDisplayPictureChange}
                    style={{ display: "none" }}
                    size={"sm"}
                  />
                  <Box
                    as="span"
                    backgroundColor="white"
                    cursor="pointer"
                    width={"full"}
                    display={"flex"}
                    alignItems={"center"}
                    color={"#4d4d4d"}
                    opacity={"0.7"}
                    gap={"1"}
                    justifyContent={"center"}
                    borderWidth={"1px"}
                    borderColor={"#4d4d4d"}
                    rounded={"md"}
                    padding={"2px 8px 2px 8px"}
                    _hover={{
                      opacity: "1",
                    }}
                  >
                    {displayPicture ? (
                      <Box fontSize={"xs"} fontWeight={"bold"}>
                        {displayPicture.name}
                      </Box>
                    ) : (
                      <Box fontSize={"xs"} fontWeight={"bold"}>
                        Upload
                      </Box>
                    )}
                    {displayPicture ? (
                      <Image src="assets\icons\checked.svg" />
                    ) : (
                      <Image src="assets\icons\upload.svg" />
                    )}
                  </Box>
                </label>
              </Box>
            </Box>
            <Box>
              <RadioGroup defaultValue="2">
                <Stack spacing={5} direction="row">
                  <Radio colorScheme="purple.200" value="1">
                    Public
                  </Radio>
                  <Radio colorScheme="purple.200" value="2">
                    Private
                  </Radio>
                </Stack>
              </RadioGroup>
            </Box>

            <Button
              type="submit"
              marginY={"12px"}
              backgroundColor={"purple.500"}
              color={"white"}
              onClick={(e) => e.preventDefault()}
              _hover={{
                backgroundColor: "purple.100",
                color: "black",
                borderWidth: "1px",
                borderColor: "purple.600",
              }}
            >
              Add Room
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddRoomModal;
