const PostDuration = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="22"
        height="22"
        viewBox="0 0 32 32"
      >
        <path d="M 16 4 C 9.382813 4 4 9.382813 4 16 C 4 22.617188 9.382813 28 16 28 C 22.617188 28 28 22.617188 28 16 C 28 9.382813 22.617188 4 16 4 Z M 14.96875 6.0625 C 14.980469 6.0625 14.988281 6.0625 15 6.0625 C 15.035156 6.585938 15.46875 7 16 7 C 16.53125 7 16.964844 6.585938 17 6.0625 C 21.738281 6.527344 25.472656 10.261719 25.9375 15 C 25.414063 15.035156 25 15.46875 25 16 C 25 16.53125 25.414063 16.964844 25.9375 17 C 25.472656 21.738281 21.738281 25.472656 17 25.9375 C 16.964844 25.414063 16.53125 25 16 25 C 15.46875 25 15.035156 25.414063 15 25.9375 C 10.261719 25.472656 6.527344 21.738281 6.0625 17 C 6.585938 16.964844 7 16.53125 7 16 C 7 15.46875 6.585938 15.035156 6.0625 15 C 6.527344 10.269531 10.246094 6.539063 14.96875 6.0625 Z M 15 9 L 15 16.40625 L 15.28125 16.71875 L 19.28125 20.71875 L 20.71875 19.28125 L 17 15.5625 L 17 9 Z"></path>
      </svg>
    </div>
  );
};

export default PostDuration;
