import { Box, Text, Image, Button } from "@chakra-ui/react";
import getAllMaterials from "../utils/getAllMaterials";

const MaterialBox = ({ data }) => {
  // const { materialsData, isLoading } = getAllMaterials(1); get all Materials from workspace ...

  const ImgSrc = (src) => {
    if (src === "image/png" || src === "image/jpg") {
      return "assets/icons/materials/image.svg";
    } else if (src === "application/pdf") {
      return "assets/icons/materials/pdf.svg";
    } else if (src === "video/mp4") {
      return "assets/icons/materials/mp4.svg";
    } else {
      return "assets/icons/materials/doc.svg";
    }
  };

  return (
    <Box display="flex" flexDirection={"column"}>
      {data.map((item, index) => (
        <Box
          key={index}
          cursor={"pointer"}
          rounded={"md"}
          w={"full"}
          as={"div"}
          display={"flex"}
          gap={"3"}
          margin={"4px"}
          backgroundColor={"rgba(217, 217, 217, 0.25)"}
          p={"5px"}
          _hover={{
            ".hover-image": {
              opacity: 0.8,
            },
            ".hover-text": {
              color: "black",
            },
            transform: "scale(1.0019)",
            transition: "transform 0.2s",
          }}
        >
          <Image
            w={"20px"}
            h={"20px"}
            opacity={"50%"}
            src={ImgSrc(item.type)}
            className="hover-image"
          />

          <Text
            fontSize={"12px"}
            fontWeight={"500"}
            className="hover-text"
            color={"rgba(130, 130, 130, 1)"}
          >
            {item.name}
          </Text>
        </Box>
      ))}
      <Button
        marginTop={{ lg: "8px", base: "6px" }}
        width={"50%"}
        alignSelf={"center"}
        size={"sm"}
        fontSize={"12px"}
        fontWeight={"400"}
        _hover={{
          background: "#9531F3",
        }}
        background="#21005D"
        color={"white"}
      >
        Upload Files
      </Button>
    </Box>
  );
};

export default MaterialBox;
