import React from 'react';
import {Box, Flex, Image} from "@chakra-ui/react";
import H3 from "./Typography/Heading/H3";
import Psm from "./Typography/Text/Psm";
import H1 from "./Typography/Heading/H1";
import ButtonSecondary from "./Button/ButtonSecondary";

function RoomCard(props) {

    return (
        <Box padding={"24px 32px"}>
            <Box
                height={"min-content"}
                maxWidth={"250px"}
                bgColor={"claydeck.white.primary"}
                alignItems={"center"}
                gap={"8px"}
                borderRadius={"10px"}
                textAlign={'center'}
                // padding={"23px 28px"}
                // paddingLeft={"25px"}
            >
                <Image src={"./img.png"}/>
                <H1 style={{marginTop:15}}>{props.name}</H1>
                <ButtonSecondary onClick={props.onClickJoin} style={{marginTop:15,marginBottom:15}}>{props.private?"Request to Join":"Join Room"}</ButtonSecondary>
                {/*<ButtonSecondary onClick={props.onClickParticipant} style={{marginTop:15,marginBottom:15}}>Participants</ButtonSecondary>*/}

            </Box>

        </Box>
    );
}

export default RoomCard;
