const COLORS = {
  purple: "#6750A4",
  purpleSecondary: "#8300C4",
  purpleTertiary: "#9531F3",
  purple4: "#6941C6",
  darkPurple: "#21005D",
  grey: "#828282",
  black: "#000",
  white: "#fff",
  backgroundGrey: "#DEE0E1",
  offWhite: "#F8F8F8",
  dark: "#1F1F1F",
  lightPurple: "#F7EBEBB2",
  background: "#F4EBFF",
  lightBlue: "#E0EAFF",
  lightPink: "#FCE7F6",
  green: "#0DDF61",
  darkBlue: "#101828",
  blue: '#0962A2',
};
export default COLORS;
