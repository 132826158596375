import React, { useState, useRef, useEffect } from "react";
import Joyride, { STATUS } from "react-joyride";

import {
  Avatar,
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Modal,
  position,
  Select,
  Switch,
  Table,
  TableCaption,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  VStack,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import H2 from "../../Components/Typography/Heading/H2";
import H3 from "../../Components/Typography/Heading/H3";
import Psm from "../../Components/Typography/Text/Psm";
import Pmd from "../../Components/Typography/Text/Pmd";
import ButtonPrimary from "../../Components/Button/ButtonPrimary";

import { AiOutlineArrowRight } from "react-icons/ai";
import { PiFunnelLight } from "react-icons/pi";
import { FaCircle } from "react-icons/fa";

import axios from "axios";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { NEW_API_URL } from "../../redux/types";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";
import { setNewUser } from "../../redux/actions/userActions";
import { capsFirst } from "../../Components/utils";
// import ChakraCarousel from "../../Components/ChakraCarousel";
import { Carousel } from "react-responsive-carousel";
import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Slider from "react-slick";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";
import HeadCard from "../../Components/HeadCard";
import Pxl from "../../Components/Typography/Text/Pxl";
import P2xl from "../../Components/Typography/Text/P2xl";
import { ArrowBackIcon, CloseIcon } from "@chakra-ui/icons";
import Plg from "../../Components/Typography/Text/Plg";
import { quiz_map } from "../../data/new_generated/quiz_map";
import { quiz_details } from "../../data/new_generated/quiz_details";
import AskBudAccent from "../../Components/AskBudAccent";
import { IoSettingsSharp } from "react-icons/io5";
import { IoSettingsOutline } from "react-icons/io5";
import CustomStyledModal from "../../Components/Modal/CustomStyledModal";
import BackArrow from "../../Components/backArrow";

const TestsQuiz = () => {
  const location = useLocation();
  const { leaderboard } = location.state || {};
  const { topic, subject, area, noteid } = useParams();
  const username = useSelector((state) => state.user.username);

  // const [tab,setTab] = useState('personalized');
  const [quizData, setQuizData] = useState(undefined);
  const [quizDataSelected, setQuizDataSelected] = useState({});
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [hintOpen, setHintOpen] = useState(false);
  const [isPersonalTest, setIsPersonalTest] = useState(false);
  const [resultsOpen, setResultsOpen] = useState(false);
  const [score, setScore] = useState(false);
  const [isPersonal, setIsPersonal] = useState(true);
  const [pageTimes, setPageTimes] = useState([]);
  const [pageStartTime, setPageStartTime] = useState(Date.now());
  const [showSettings, setShowSettings] = useState(false);
  const [showAnswers, setShowAnswers] = useState(false);
  const [isReview, setIsReview] = useState(false);
  const [isReviewModalVisible, setIsReviewModalVisible] = useState(false);
  const [correctAnswers, setCorrectAnswers] = useState(false);
  const [wrongAnswers, setWrongAnswers] = useState(false);
  const [notAttempted, setNotAttempted] = useState(false);
  const [forceOpen, setForceOpen] = useState(false);
  const [previousAnswers, setPreviousAnswers] = useState({});

  const navigate = useNavigate();
  const settingsRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (settingsRef.current && !settingsRef.current.contains(event.target)) {
        setShowSettings(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const isCorrect = (question_idx) => {
    if (
      quizDataSelected[question_idx] &&
      quizData?.questions[question_idx].answer.toLowerCase() ===
        quizDataSelected[question_idx].toLowerCase()
    ) {
      return true;
    }
    return false;
  };

  // const getQuizDataBgColor = (question_idx) => {
  //   if (isCorrect(question_idx) && isPersonal) {
  //     return "green";
  //   } else if (isPersonal) {
  //     return "red";
  //   }

  //   return "#0d6efd";
  // };

  const getTestDetails = async (id, username) => {
    try {
      // console.log(${API_URL}/all_quizes/quiz_details/${id}/1/${username});
      const res = await axios.get(
        `${NEW_API_URL}all_quizes/quiz_details/${id}/1/${username}`
      );
      setQuizData(res.data);
      return res?.data;
    } catch (error) {
      console.error("Error fetching quiz data for id:", id, error);
      throw error;
    }
  };

  const submitTest = async (id, username, score) => {
    try {
      let ans = [];
      for (let i = 0; i < quizData.questions.length; i++) {
        // ans.push()
        ans[i] = {
          question_id: quizData.questions[i].question_id,
          answer: quizDataSelected[i] || "",
          time_taken: pageTimes[i] + "s",
        };
      }

      // console.log(${API_URL}/all_quizes/quiz_details/${id}/1/${username});
      let body = {
        quiz_id: noteid,
        user_id: username,
        topic_id: topic || null,
        quiz_type: quizData.quiz_type,
        max_score: 2 * quizData.questions.length,
        read_status: "read",
        score: score,
        answers: ans,
      };

      const res = await axios.post(
        `${NEW_API_URL}all_quizes/quiz-map-tracking/`,
        body
      );
      // setQuizData(res.data)
      return res?.data;
    } catch (error) {
      console.error("Error fetching quiz data for id:", id, error);
      throw error;
    }
  };

  // console.log("quizData read or not :", quizData?.read_status);

  // let pageStartTime = Date.now();
  useEffect(() => {
    getTestDetails(noteid, username).then((data) => {
      setQuizData(data);

      // Check if the user has already attempted the test
      if (data.score !== 0) {
        setIsReviewModalVisible(true);
        reviewModalDisclosure.onOpen();
        setPreviousAnswers(
          data.questions.reduce((acc, q, i) => {
            acc[i] = q.previous_answer;
            return acc;
          }, {})
        );
      }
    });
  }, []);

  const getQuizDataBgColor = (question_idx, char) => {
    if (showAnswers) {
      if (
        quizDataSelected[question_idx] &&
        quizData?.questions[question_idx].answer.toLowerCase() ===
          quizDataSelected[question_idx].toLowerCase()
      ) {
        return "#41B868";
      } else if (quizDataSelected[question_idx]) {
        return "#DA5656";
      }
    } else if (isReview) {
      if (Object.keys(quizDataSelected).length > 0) {
        if (
          quizDataSelected[question_idx]?.toLowerCase() === char.toLowerCase()
        ) {
          return "rgba(9, 98, 162, 1)";
        } else {
          return "white";
        }
      } else {
        if (
          quizData?.questions[question_idx]?.previous_answer.toLowerCase() ===
          char.toLowerCase()
        ) {
          return "rgba(9, 98, 162, 1)";
        } else {
          return "white";
        }
      }
    }

    return "rgba(9, 98, 162, 1)";
  };

  const getQuizDataTextColor = (question_idx, char) => {
    let textColor = "";

    if (Object.keys(previousAnswers).length > 0) {
      textColor =
        previousAnswers[question_idx]?.toLowerCase() === char?.toLowerCase()
          ? "white"
          : "black";
    } else {
      textColor =
        quizDataSelected[question_idx]?.toLowerCase() === char.toLowerCase()
          ? "white"
          : "black";
    }

    return textColor;
  };

  const hintDisclosure = useDisclosure();
  const reviewModalDisclosure = useDisclosure();

  function getTotalTime() {
    return pageTimes.reduce((total, time) => total + time, 0);
  }
  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  }

  const onResetTest = (review = false) => {
    if (!review) {
      setQuizDataSelected({});
    }
    setPreviousAnswers({});
    setResultsOpen(false);
    setCurrentQuestion(0);
    setPageTimes([]);
    setPageStartTime(Date.now());
  };

  console.log("quiz data------->", quizData);
  console.log("quiz selected data------->", quizDataSelected);

  // useEffect(() => {
  //   if (quizData && quizData.score !== 0) {
  //     setIsReviewModalVisible(true);
  //     reviewModalDisclosure.onOpen();
  //   } else {
  //     setIsReviewModalVisible(false);
  //   }
  // }, [quizData]);

  console.log("isREview", isReview);

  return (
    <>
      <Container
        justifyContent={"center"}
        alignItems={"center"}
        w={"95%"}
        maxW="container.xl"
      >
        <Flex
          flexDirection={"column"}
          alignItems={"space-between"}
          justifyContent={"space-between"}
          minH={"95vh"}
        >
          <Box>
            {isReviewModalVisible && (
              <CustomStyledModal
                review_quiz
                isOpen={reviewModalDisclosure.isOpen}
                onOpen={reviewModalDisclosure.onOpen}
                onClose={reviewModalDisclosure.onClose}
                onRetakeClick={() => {
                  onResetTest();
                  reviewModalDisclosure.onClose();
                }}
                onReviewClick={() => {
                  setIsReview(true);

                  reviewModalDisclosure.onClose();
                }}
                title={"Test already Taken"}
              />
            )}
            <Flex
              mt={"10vh"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={4}
              >
                {/* <ArrowBackIcon
                  cursor={"pointer"}
                  onClick={() => {
                    navigate(-1);
                  }}
                  boxSize={"45px"}
                /> */}
                <BackArrow  />

                <Box>
                  <P2xl mb={0} ml={0} mr={0}>
                    <strong>Test</strong>
                  </P2xl>
                </Box>
              </Box>

              <Box position="relative">
                <Box ref={settingsRef} cursor="pointer">
                  {!resultsOpen && <IoSettingsSharp
                      size={24}
                      onClick={() => {
                        setShowSettings(!showSettings);
                      }}
                  />}

                  {showSettings && (
                    <Box
                      position="absolute"
                      top={-3.5}
                      right={-3}
                      zIndex={1000}
                      bg="rgba(231, 231, 231, 1)"
                      borderRadius="8px"
                      boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
                      border="1px solid #E2E8F0"
                      width="230px"
                      p={3}
                    >
                      <Flex direction="column" gap={2}>
                        <Flex
                          justify="space-between"
                          align="center"
                          pb={2}
                          borderBottom="1px solid #E2E8F0"
                        >
                          <Flex align="center" gap={2}>
                            <Text m={0} fontSize={16} fontWeight="500">
                              Settings
                            </Text>
                          </Flex>
                          <IoSettingsOutline
                            size={24}
                            color="rgba(9, 98, 162, 1)"
                            onClick={() => {
                              setShowSettings(false);
                            }}
                          />
                        </Flex>

                        <Flex justify="space-between" align="center" py={1}>
                          <Text m={0} fontSize={16} fontWeight={"400"}>
                            Show Answers
                          </Text>
                          <Switch
                            size="md"
                            disabled={isReview}
                            colorScheme="blue"
                            isChecked={showAnswers}
                            onChange={(e) => {
                              if (!isReview) {
                                setShowAnswers(e.target.checked);
                              }
                            }}
                          />
                        </Flex>
                      </Flex>
                    </Box>
                  )}
                </Box>
              </Box>
            </Flex>

            <hr />

            {!resultsOpen && <Container maxW={"100%"}


            >
              <Box
                  border={"1px solid #B6B6B6"}
                  boxShadow={"0px 3px 3px 0px #00000040"}
                  borderRadius={"12px"}
                  pt={"24px"}
                  pb={"24px"}
                  mt={"24px"}
                  mb={"24px"}
                  pl={"24px"}
                  pr={"24px"}
                  height={"60vh"}
                  overflow={'auto'}
              >


                {isPersonal && !resultsOpen && (
                    <Flex w={"100%"} justifyContent={"flex-end"}>
                      <ButtonPrimary
                          bg={"none"}
                          border={"1px solid #0962A2"}
                          onClick={() => {
                            // setHintOpen(true)
                            hintDisclosure.onOpen();
                          }}
                      >
                        <Flex alignItems={"center"}>
                          <Image h={"20px"} w={"20px"} src={"/hintai.png"}></Image>
                          <Psm m={0} color={"#0962A2"} ml={"5px"}>
                            Get Hint
                          </Psm>
                        </Flex>
                      </ButtonPrimary>
                    </Flex>
                )}
                {!resultsOpen && (
                    <Center
                        mt={12}
                        bgColor={"white"}
                        position={"relative"}
                        borderRadius={"12px"}
                        minH={"20vh"}
                    >
                      <Center
                          position={"absolute"}
                          top={"-30px"}
                          bgColor={"white"}
                          fontWeight={"300"}
                          h={"64px"}
                          w={"64px"}
                          borderRadius={"50%"}
                          boxShadow={"0px 2px 2px 0px #00000040"}
                          border={"1px solid #B6B6B6"}
                      >
                        {currentQuestion + 1}/{quizData?.questions?.length}
                      </Center>
                      <Pxl m={0} fontWeight={"400"} style={{padding: "20px"}}>
                        {quizData?.questions[currentQuestion].question}
                      </Pxl>
                    </Center>
                )}
                {!hintOpen && !resultsOpen && (
                    <>
                      <Flex
                          alignItems={"center"}
                          justifyContent={"space-around"}
                          mt={"20px"}
                      >
                        <Flex
                            cursor={"pointer"}
                            alignItems={"center"}
                            w={"45%"}
                            bgColor={
                              isReview
                                  ? quizData.questions[currentQuestion].answer === "A"
                                      ? "green"
                                      : getQuizDataBgColor(currentQuestion, "A")
                                  : quizDataSelected[currentQuestion] === "A"
                                      ? getQuizDataBgColor(currentQuestion)
                                      : "white"
                            }
                            height={'90px'}
                            borderRadius={"100px"}
                            border={"1px solid #B6B6B6"}
                            padding={"12px"}
                            boxShadow={"0px 2px 2px 0px #00000040"}
                            onClick={() => {
                              if (!isReview) {
                                let cpy = JSON.parse(
                                    JSON.stringify(quizDataSelected)
                                );
                                cpy[currentQuestion] = "A";
                                setQuizDataSelected(cpy);
                              }
                            }}
                        >
                          <Box ml={"12px"} borderRadius={"50px"}>
                            <Pxl
                                color={getQuizDataTextColor(currentQuestion, "A")}
                                textAlign={"center"}
                                ml={"12px"}
                                fontWeight={"400"}
                                m={0}
                            >
                              A
                            </Pxl>
                          </Box>
                          <Box w={"100%"} ml={'12px'}>
                            <Pxl
                                color={getQuizDataTextColor(currentQuestion, "A")}
                                textAlign={"left"}
                                ml={"12px"}
                                fontWeight={"400"}
                                m={0}
                            >
                              {quizData?.questions[currentQuestion].option_a}
                            </Pxl>
                          </Box>
                        </Flex>
                        <Flex
                            cursor={"pointer"}
                            alignItems={"center"}
                            w={"45%"}
                            borderRadius={"100px"}
                            padding={"12px"}
                            border={"1px solid #B6B6B6"}
                            height={'90px'}

                            bgColor={
                              isReview
                                  ? quizData.questions[currentQuestion].answer === "B"
                                      ? "green"
                                      : getQuizDataBgColor(currentQuestion, "B")
                                  : quizDataSelected[currentQuestion] === "B"
                                      ? getQuizDataBgColor(currentQuestion)
                                      : "white"
                            }
                            boxShadow={"0px 2px 2px 0px #00000040"}
                            onClick={() => {
                              if (!isReview) {
                                let cpy = JSON.parse(
                                    JSON.stringify(quizDataSelected)
                                );
                                cpy[currentQuestion] = "B";
                                setQuizDataSelected(cpy);
                              }
                            }}
                        >
                          <Box ml={"12px"} borderRadius={"50px"}>
                            <Pxl
                                color={getQuizDataTextColor(currentQuestion, "B")}
                                textAlign={"center"}
                                ml={"12px"}

                                fontWeight={"400"}
                                m={0}
                            >
                              B
                            </Pxl>
                          </Box>
                          <Box w={"100%"} ml={'12px'}>
                            <Pxl
                                color={getQuizDataTextColor(currentQuestion, "B")}
                                textAlign={"left"}
                                ml={"12px"}
                                fontWeight={"400"}
                                m={0}
                            >
                              {quizData?.questions[currentQuestion].option_b}
                            </Pxl>
                          </Box>
                        </Flex>
                      </Flex>

                      <Flex
                          alignItems={"center"}
                          justifyContent={"space-around"}
                          mt={"20px"}
                      >
                        <Flex
                            cursor={"pointer"}
                            alignItems={"center"}
                            w={"45%"}
                            bgColor={
                              isReview
                                  ? quizData.questions[currentQuestion].answer === "C"
                                      ? "green"
                                      : getQuizDataBgColor(currentQuestion, "C")
                                  : quizDataSelected[currentQuestion] === "C"
                                      ? getQuizDataBgColor(currentQuestion)
                                      : "white"
                            }
                            borderRadius={"100px"}
                            padding={"12px"}
                            boxShadow={"0px 2px 2px 0px #00000040"}
                            border={"1px solid #B6B6B6"}
                            height={'90px'}

                            onClick={() => {
                              if (!isReview) {
                                let cpy = JSON.parse(
                                    JSON.stringify(quizDataSelected)
                                );
                                cpy[currentQuestion] = "C";
                                setQuizDataSelected(cpy);
                              }
                            }}
                        >
                          <Box ml={"12px"} borderRadius={"50px"}>
                            <Pxl
                                color={getQuizDataTextColor(currentQuestion, "C")}
                                textAlign={"center"}
                                ml={"12px"}
                                fontWeight={"400"}
                                m={0}

                            >
                              C
                            </Pxl>
                          </Box>
                          <Box w={"100%"} ml={'12px'}>
                            <Pxl
                                color={getQuizDataTextColor(currentQuestion, "C")}
                                textAlign={"left"}
                                ml={"12px"}
                                fontWeight={"400"}
                                m={0}
                            >
                              {quizData?.questions[currentQuestion].option_c}
                            </Pxl>
                          </Box>
                        </Flex>
                        <Flex
                            cursor={"pointer"}
                            alignItems={"center"}
                            w={"45%"}
                            bgColor={
                              isReview
                                  ? quizData.questions[currentQuestion].answer === "D"
                                      ? "green"
                                      : getQuizDataBgColor(currentQuestion, "D")
                                  : quizDataSelected[currentQuestion] === "D"
                                      ? getQuizDataBgColor(currentQuestion)
                                      : "white"
                            }
                            borderRadius={"100px"}
                            padding={"12px"}
                            height={'90px'}

                            boxShadow={"0px 2px 2px 0px #00000040"}
                            border={"1px solid #B6B6B6"}

                            onClick={() => {
                              if (!isReview) {
                                let cpy = JSON.parse(
                                    JSON.stringify(quizDataSelected)
                                );
                                cpy[currentQuestion] = "D";
                                setQuizDataSelected(cpy);
                              }
                            }}
                        >
                          <Box ml={"12px"} borderRadius={"50px"}>
                            <Pxl
                                color={getQuizDataTextColor(currentQuestion, "D")}
                                textAlign={"center"}
                                ml={"12px"}
                                fontWeight={"400"}
                                m={0}
                            >
                              D
                            </Pxl>
                          </Box>
                          <Box w={"100%"} ml={'12px'}>
                            <Pxl
                                color={getQuizDataTextColor(currentQuestion, "D")}
                                textAlign={"left"}
                                ml={"12px"}
                                fontWeight={"400"}
                                m={0}
                            >
                              {quizData?.questions[currentQuestion].option_d}
                            </Pxl>
                          </Box>
                        </Flex>
                      </Flex>
                    </>
                )}
              </Box>

              {hintOpen && (
                  <>
                    <Center w={"100%"}>
                      <Center
                          bgColor={"white"}
                          w={"80%"}
                          mr={"20px"}
                          flexDirection={"column"}
                          mt={"30px"}
                          p={"12px"}
                          boxShadow={"0px 3px 3px 0px #00000040"}
                          borderRadius={"12px"}
                          borderTop={"4px solid #2A6291"}
                      >
                        <Pxl>Hint</Pxl>
                        <Plg>
                          <strong>Related Concepts:</strong>
                        </Plg>
                        {quizData?.questions[currentQuestion].hint_concepts.map(
                            (item, idx) => (
                                <Plg color="#2A6291" key={idx}>
                                  {item.name}
                                </Plg>
                            )
                        )}
                        <Plg>
                          <strong>Related News:</strong>
                        </Plg>
                        {quizData?.questions[currentQuestion].hint_news.map(
                            (item, idx) => (
                                <Plg color="#2A6291" key={idx}>
                                  {item.name}
                                </Plg>
                            )
                        )}
                      </Center>
                    </Center>
                  </>
              )}

              {!resultsOpen && (
                  <Flex
                      alignItems={"center"}
                      justifyContent={"space-around"}
                      mt={"60px"}
                  >
                    <ButtonSecondary
                        isDisabled={currentQuestion <= 0}
                        style={{margin: 10, width: '40%',position:'relative',zIndex:1}}

                        onClick={() => {
                          const currentTime = Date.now();
                          const timeSpent = Math.floor(
                              (currentTime - pageStartTime) / 1000
                          );

                          let cp = JSON.parse(JSON.stringify(pageTimes));
                          cp[currentQuestion] =
                              (cp[currentQuestion] || 0) + timeSpent;

                          setPageTimes(cp);
                          // pageStartTime = currentTime

                          setPageStartTime(currentTime);

                          setCurrentQuestion(currentQuestion - 1);
                        }}
                    >
                      Previous
                    </ButtonSecondary>

                    {hintOpen && (
                        <ButtonPrimary
                            onClick={() => {
                              setHintOpen(false);
                            }}
                        >
                          Close Hint
                        </ButtonPrimary>
                    )}
                    {currentQuestion < quizData?.questions.length - 1 && (
                        <ButtonPrimary
                            style={{margin: 10, width: '40%',position:'relative',zIndex:1}}

                            onClick={() => {
                              const currentTime = Date.now();
                              const timeSpent = Math.floor(
                                  (currentTime - pageStartTime) / 1000
                              );

                              let cp = JSON.parse(JSON.stringify(pageTimes));
                              cp[currentQuestion] =
                                  (cp[currentQuestion] || 0) + timeSpent;

                              setCurrentQuestion(currentQuestion + 1);
                              setPageTimes(cp);
                              // pageStartTime = currentTime
                              setPageStartTime(currentTime);
                            }}
                        >
                          Next
                        </ButtonPrimary>
                    )}
                    {!isReview &&
                        currentQuestion >= quizData?.questions.length - 1 && (
                            <ButtonPrimary
                                style={{margin: 10, width: '40%',position:'relative',zIndex:1}}

                                onClick={() => {
                                  setResultsOpen(true);
                                  let correct = 0;
                                  let wrong = 0;
                                  let unattempted = 0;
                                  let score = 0;
                                  for (let i = 0; i < quizData?.questions.length; i++) {
                                    if (
                                        quizDataSelected[i] &&
                                        quizData?.questions[i].answer.toLowerCase() ===
                                        quizDataSelected[i].toLowerCase()
                                    ) {
                                      score += 2;
                                      correct++;
                                    } else if (quizDataSelected[i]) {
                                      score -= 0.66;
                                      wrong++;
                                    } else {
                                      unattempted++;
                                    }
                                  }
                                  setScore(score);
                                  setCorrectAnswers(correct);
                                  setWrongAnswers(wrong);
                                  setNotAttempted(unattempted);
                                  submitTest(noteid, username, score);
                                  console.log(pageTimes);
                                }}
                            >
                              Finish
                            </ButtonPrimary>
                        )}
                    {isReview &&
                        currentQuestion >= quizData?.questions.length - 1 && (
                            <ButtonPrimary
                                onClick={() => {
                                  onResetTest();
                                  setIsReview(false);
                                }}
                                width={"40%"}
                            >
                              Retake
                            </ButtonPrimary>
                        )}
                  </Flex>
              )}
            </Container>}

            <CustomStyledModal
              hint
              onMoreHints={()=>{
                console.log("on more hints called")
                setForceOpen(true)
                hintDisclosure.onClose()

              }}
              isOpen={hintDisclosure.isOpen}
              onOpen={hintDisclosure.onOpen}
              onClose={hintDisclosure.onClose}
              title={"Hint"}
              quizData={quizData}
              currentQuestion={currentQuestion}
            />

            {resultsOpen && (
              <>
                <Flex
                  justifyContent={"center"}
                  alignItems={"center"}
                  flexDirection={"column"}
                  w={"100%"}
                >
                  <Flex
                    bgColor={"#0962A2"}
                    w={"80%"}
                    mr={"20px"}
                    justifyContent={"space-around"}
                    alignItems={"center"}
                    mt={"30px"}
                    p={"12px"}
                    borderRadius={"12px"}
                    pt={"40px"}
                    pb={"40px"}
                  >
                    <Flex
                      flexDirection={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Flex
                        borderRadius={"50%"}
                        w={"200px"}
                        h={"200px"}
                        bg={"white"}
                        flexDirection={"column"}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Pxl margin={0} fontSize={"64px"}>
                          <strnog>{score.toFixed(0)}</strnog>
                        </Pxl>
                        <Psm margin={0} fontSize={"24px"}>
                          <strong>{`Out of ${
                            2 * quizData?.questions?.length
                          }`}</strong>
                        </Psm>
                      </Flex>
                      <Psm
                        margin={0}
                        color={"white"}
                        mt={"10px"}
                        fontSize={"24px"}
                      >
                        Time Taken: {formatTime(getTotalTime())}
                      </Psm>
                    </Flex>

                    <Box w={"50%"}>
                      <Box
                        w={"100%"}
                        position={"relative"}
                        overflow={"hidden"}
                        h={"24px"}
                        bg={"gray"}
                        borderRadius={"24px"}
                      >
                        <Box
                          position={"absolute"}
                          zIndex={3}
                          h={"100%"}
                          w={(correctAnswers *100/ quizData?.questions.length)+'%'}
                          bg={"green"}
                        ></Box>
                        <Box
                          position={"absolute"}
                          zIndex={2}
                          h={"100%"}
                          w={((correctAnswers *100/ quizData?.questions.length)+(wrongAnswers *100/ quizData?.questions.length))+'%'}
                          bg={"red"}
                        ></Box>
                        <Box
                          position={"absolute"}
                          zIndex={1}
                          h={"100%"}
                          w={'100%'}
                          bg={"gray"}
                        ></Box>
                      </Box>
                      <Flex justifyContent={"space-around"}>
                        <Flex alignItems={"center"} mt={"14px"}>
                          <Box
                            borderRadius={"50%"}
                            h={"14px"}
                            w={"14px"}
                            bg={"green"}
                          />
                          <Pmd margin={0} ml={"8px"} color={"white"}>
                            <strong>{`Correct ${correctAnswers}`}</strong>
                          </Pmd>
                        </Flex>
                        <Flex alignItems={"center"} mt={"14px"}>
                          <Box
                            borderRadius={"50%"}
                            h={"14px"}
                            w={"14px"}
                            bg={"red"}
                          />
                          <Pmd margin={0} ml={"8px"} color={"white"}>
                            <strong>{`Wrong ${wrongAnswers}`}</strong>
                          </Pmd>
                        </Flex>
                        <Flex alignItems={"center"} mt={"14px"}>
                          <Box
                            borderRadius={"50%"}
                            h={"14px"}
                            w={"14px"}
                            bg={"gray"}
                          />
                          <Pmd margin={0} ml={"8px"} color={"white"}>
                            <strong>{`Not attempted ${notAttempted}`}</strong>
                          </Pmd>
                        </Flex>
                      </Flex>

                      <Flex justifyContent={"space-around"} mt={"12px"}>
                        <ButtonPrimary
                          display={"flex"}
                          bg={"white"}
                          color={"#0962A2"}
                          onClick={() => {
                            setIsReview(true);
                            onResetTest(true);
                          }}
                        >
                          <Image
                            h={"20px"}
                            w={"20px"}
                            src={"/review_icon.png"}
                          />
                          Review
                        </ButtonPrimary>
                        <ButtonPrimary
                          onClick={() => {
                            onResetTest();
                            setIsReview(false);
                          }}
                          bg={"white"}
                          color={"#0962A2"}
                          display={"flex"}
                        >
                          <Image
                            h={"20px"}
                            w={"20px"}
                            src={"/restart_icon.png"}
                            mr={"3px"}
                          />{" "}
                          Retry
                        </ButtonPrimary>
                      </Flex>
                    </Box>
                  </Flex>

                  {leaderboard && (
                    <Box
                      boxShadow={"0px 4px 4px 0px #00000040"}
                      w={"50%"}
                      mt={"20px"}
                    >
                      <TableContainer>
                        <Table variant="simple">
                          <Thead>
                            <Tr>
                              <Th></Th>
                              <Th color={"black"}>Rank</Th>
                              <Th color={"black"}>Name</Th>
                              <Th color={"black"}>Time</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            <Tr>
                              <Td>
                                <Image
                                  src={"/first.png"}
                                  h={"38px"}
                                  w={"31px"}
                                />
                              </Td>
                              <Td>1</Td>
                              <Td>m2</Td>
                              <Td>25.4</Td>
                            </Tr>
                            <Tr>
                              <Td>
                                <Image
                                  src={"/second.png"}
                                  h={"38px"}
                                  w={"31px"}
                                />
                              </Td>
                              <Td>2</Td>
                              <Td>n1</Td>
                              <Td>25.4</Td>
                            </Tr>
                          </Tbody>
                        </Table>
                      </TableContainer>
                    </Box>
                  )}
                </Flex>
              </>
            )}
          </Box>
        </Flex>

        <AskBudAccent
            context={`Give answers related to ${quizData?.questions[currentQuestion].question}`}
            forceOpen={forceOpen}
            selectedText={quizData?.questions[currentQuestion].question}
            setForceOpen={setForceOpen}
            initText={quizData?.questions[currentQuestion].question}
        />

        {/*<AskBudAccent/>*/}
      </Container>
    </>
  );
};

export default TestsQuiz;
