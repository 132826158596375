import { Box } from "@chakra-ui/react";

const RemoveBin = () => {
  return (
    <Box as="span" cursor={"pointer"}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="22"
        height="22"
        viewBox="0 0 48 48"
      >
        <path
          fill="#ff3d00"
          d="M34,11l-6-6h-8l-6,6h-3v28c0,2.2,1.8,4,4,4h18c2.2,0,4-1.8,4-4V11H34z"
        ></path>
        <path
          fill="#ff6e40"
          d="M11,7h26c1.1,0,2,0.9,2,2v2H9V9C9,7.9,9.9,7,11,7z"
        ></path>
        <path
          fill="#fafafa"
          d="M15.515 25H32.486000000000004V29H15.515z"
          transform="rotate(-45.001 24 27)"
        ></path>
        <path
          fill="#fafafa"
          d="M22 18.515H26V35.486000000000004H22z"
          transform="rotate(-45.001 24 27)"
        ></path>
      </svg>
    </Box>
  );
};

export default RemoveBin;
