import React, {useState, useEffect} from "react";
import Joyride, { STATUS } from "react-joyride";

import {
  Avatar,
  Box,
  Button, Center,
  Container,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Select,
  Tag,
  VStack,
  Wrap,
  WrapItem
} from "@chakra-ui/react";
import H2 from "../../Components/Typography/Heading/H2";
import H3 from "../../Components/Typography/Heading/H3";
import Psm from "../../Components/Typography/Text/Psm";
import Pmd from "../../Components/Typography/Text/Pmd";
import ButtonPrimary from "../../Components/Button/ButtonPrimary";

import {AiOutlineArrowRight} from "react-icons/ai";
import {PiFunnelLight} from "react-icons/pi";
import {FaCircle} from "react-icons/fa";

import axios from "axios";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { NEW_API_URL } from "../../redux/types";
import { useNavigate } from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";
import {setNewUser} from "../../redux/actions/userActions";
import {capsFirst} from "../../Components/utils";
// import ChakraCarousel from "../../Components/ChakraCarousel";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Slider from "react-slick";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";
const Home = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const newUser = useSelector(state => state.user.newUser);
  const username = useSelector(state => state.user.username);
  const selectedWorkspace = useSelector(state => state.user.selectedWorkspace);
  const user = useSelector(state => state.user);
  let workspaceId = '';
  if(selectedWorkspace && selectedWorkspace.hasOwnProperty('workspace_id')){
    workspaceId = selectedWorkspace.workspace_id
  }
  console.log('workspace_ID',workspaceId,selectedWorkspace,user)
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const [{ run, steps }, setState] = useState({
    run: newUser,
    steps: [
      {
        content: <h2>Lets begin our journey</h2>,
        locale: { skip: <strong>SKIP</strong> },
        placement: "center",
        target: "body",
      },
      {
        title: "Productivity",
        content: (
            <p>
              You can check your productivity here. Click on it to see your lesson
              plan.
            </p>
        ),
        target: "#productivity",
        placement: "bottom",
      },
      {
        title: "Feeds",
        content: <p>Check your news reading status</p>,
        target: "#feeds",
        placement: "bottom",
      },
      {
        title: "Results",
        content: <p>Check your average test score percentage</p>,
        target: "#test-score",
        placement: "bottom",
      },
    ],
  });

  const [loading, setLoading] = useState(false);
  const [bookRecommendations, setBookRecommendations] = useState([
    {'title':'lorem','author':'aaa','image':'https://cdn.sanity.io/images/bilbb9h8/production/8148c49082454c83aa1fb24744d506c5c8231672-128x128.svg'},
    {'title':'lorem','author':'aaa','image':'https://cdn.sanity.io/images/bilbb9h8/production/8148c49082454c83aa1fb24744d506c5c8231672-128x128.svg'},
    {'title':'lorem','author':'aaa','image':'https://cdn.sanity.io/images/bilbb9h8/production/8148c49082454c83aa1fb24744d506c5c8231672-128x128.svg'},
    {'title':'lorem','author':'aaa','image':'https://cdn.sanity.io/images/bilbb9h8/production/8148c49082454c83aa1fb24744d506c5c8231672-128x128.svg'},
    {'title':'lorem','author':'aaa','image':'https://cdn.sanity.io/images/bilbb9h8/production/8148c49082454c83aa1fb24744d506c5c8231672-128x128.svg'},
    {'title':'lorem','author':'aaa','image':'https://cdn.sanity.io/images/bilbb9h8/production/8148c49082454c83aa1fb24744d506c5c8231672-128x128.svg'},
    {'title':'lorem','author':'aaa','image':'https://cdn.sanity.io/images/bilbb9h8/production/8148c49082454c83aa1fb24744d506c5c8231672-128x128.svg'},
    {'title':'lorem','author':'aaa','image':'https://cdn.sanity.io/images/bilbb9h8/production/8148c49082454c83aa1fb24744d506c5c8231672-128x128.svg'},
    {'title':'lorem','author':'aaa','image':'https://cdn.sanity.io/images/bilbb9h8/production/8148c49082454c83aa1fb24744d506c5c8231672-128x128.svg'},
    {'title':'lorem','author':'aaa','image':'https://cdn.sanity.io/images/bilbb9h8/production/8148c49082454c83aa1fb24744d506c5c8231672-128x128.svg'},
    {'title':'lorem','author':'aaa','image':'https://cdn.sanity.io/images/bilbb9h8/production/8148c49082454c83aa1fb24744d506c5c8231672-128x128.svg'},
    {'title':'lorem','author':'aaa','image':'https://cdn.sanity.io/images/bilbb9h8/production/8148c49082454c83aa1fb24744d506c5c8231672-128x128.svg'},
    {'title':'lorem','author':'aaa','image':'https://cdn.sanity.io/images/bilbb9h8/production/8148c49082454c83aa1fb24744d506c5c8231672-128x128.svg'},
    {'title':'lorem','author':'aaa','image':'https://cdn.sanity.io/images/bilbb9h8/production/8148c49082454c83aa1fb24744d506c5c8231672-128x128.svg'},
    {'title':'lorem','author':'aaa','image':'https://cdn.sanity.io/images/bilbb9h8/production/8148c49082454c83aa1fb24744d506c5c8231672-128x128.svg'}
  ]);
  const [feeds, setFeeds] = useState([
      {source:'a', title:'z'},
      {source:'a', title:'z'},
      {source:'a', title:'z'},
      {source:'a', title:'z'},
      {source:'a', title:'z'},
      {source:'a', title:'z'},
      {source:'a', title:'z'},
      {source:'a', title:'z'},
      {source:'a', title:'z'},
      {source:'a', title:'z'},
      {source:'a', title:'z'},
      {source:'a', title:'z'}
  ]);
  const [badges, setBadges] = useState({
    answersWritten: 0,
    pointsEarned: 0,
    testsAttended: 0,
  });

  useEffect(() => {
    if (username !== "" && workspaceId !== "") {
      fetchUserStats();
    }
  }, [username, workspaceId]);

  const fetchUserStats = async () => {
    // dispatch(checkUserSubscription(username));
    try {
      setLoading(true);
      await axios
          .get(
              `${NEW_API_URL}gamification/get_metrics/${username}/${workspaceId}`
          )
          .then((response) => {
            // console.log(JSON.stringify(response.data))
            if (response.status === 200) {
              // setNewsReadStatus(response.data.metrics.news_reading_status);
              // setAvgTestScore(response.data.metrics.average_test_scores);
              // setReadingHoursDone(response.data.metrics.hours_reading_done);
              // setReadingHoursLeft(response.data.metrics.hours_reading_left);
              // setTodaysTasks(response.data.metrics.todays_tasks.tasks);
              setBadges(response.data.metrics?.badges?.badges);

              setBookRecommendations(
                  response.data.metrics?.book_recommendations?.tasks || []
              );
              setFeeds(response.data.metrics?.headlines || []);
              // setNotifications(response.data.metrics.notifications.notifications);

              // notificationScheduler(
              //   response.data.metrics.notifications.notifications
              // );
            }
          })
          .catch((error) => {
            console.error(error);
            alert("Couldn't load data");
          });
    } catch (e) {
      console.error(e);
      alert("Something unexpected happened");
    } finally {
      setLoading(false);
    }
  };

  const handleTourEnd = (state) => {
    const { action, index, status, type } = state;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      dispatch(setNewUser(false));
    }
  };

  const styles = {
    heading: {
      color: "claydeck.text.primary",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "120%",
      letterSpacing: "0.2px",
    },
    bookContainer: {

      minHeight: "350px",

      bgColor: "claydeck.white.primary",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "5px",
      padding: "9px 15px",
      cursor: "pointer",
    },
    feeds: {
      // maxWidth: "750px",
      overflow: "hidden",
      overflowX: "scroll",
      gap: "18px",
      flexDirection: { base: "row", md: "column" },
      paddingBottom: "12px",
      marginTop: "18px",
    },
    feedContainer: {
      height: { base: "225px", md: "auto" },
      width: { base: "150px", md: "initial" },
      minWidth: "150px",
      bgColor: "claydeck.white.primary",
      display: { base: "flex", md: "initial" },
      flexDirection: "column",
      justifyContent: "space-between",
      borderRadius: { base: "5px", md: "10px" },
      padding: { base: "12px", md: "16px", lg: "24px" },
      cursor: "pointer",
    },
    feedContent: {
      height: "80%",
      flexDirection: { base: "column", md: "row-reverse" },
      gap: "16px",
      justifyContent: { base: "space-around", md: "flex-end" },
      alignItems: "center",
      marginBottom: { base: "8px", md: "0px" },
    },
    feedHeading: {
      color: "claydeck.brand.secondary",
      fontSize: { base: "14px", md: "16px" },
      display: "block",
      textOverflow: "ellipsis",
      wordWrap: "break-word",
      overflow: "hidden",
      maxHeight: "4.2em",
      lineHeight: "1.4em",
    },
    userStats: {
      // height: "116px",
      // minWidth: "357px",
      // width:'100%',
      // minWidth:'100%',
      // backgroundColor: 'claydeck.white.primary',
      // justifyContent: "space-around",
      alignItems: "center",
      borderRadius: "5px",
      flexWrap:'wrap',
      // paddingTop: "8px",
      // paddingBottom: "7px",
      // margin: "22px",
    },
    userStat: {
      textAlign: 'center',
      // width:'100%',
      flexDirection: 'column',
      minHeight:'15vh',
      alignItems: 'center',
      padding:'10px',
      justifyContent:'center'
    },
    userStatIcon: {
      height: "48px",
      width: '48px',
      bgColor: "claydeck.white.primary",
      objectFit: 'contain',
      borderRadius: "4px",
      boxShadow: "0px 4px 20px 5px rgba(0, 0, 0, 0.03)",
      padding: "8px"
    },
    userStatTitle: {
      textAlign: 'center',
      color: 'claydeck.text.tertiary',
      fontSize: '12px',
      fontWeight:'400',
      lineHeight: '120%',
      marginTop: '12px',
      marginBottom: '8px'
    },
    userStatValue: {
      fontSize: '30px',
      fontWeight: 400
    },
    option: {
      bgColor: '#fff',
      color: '#000'
    },
    labelInfo: {
      color: '#000',
      fontSize: '14px',
      marginLeft: '8px'
    }
  };
  const arrowStyle = {
    color: 'blue', // Set the arrow color to blue
  };
  return (
      <Box
          width={{ base: "100%", xl: "80%" }}
          maxWidth={{ base: "100%", xl: "80%" }}
          // backgroundColor={'black'}
          // direction={{ base: "column", "2xl": "row" }}
          // justify="space-between"
      >
        <Joyride
            continuous
            callback={(state) => handleTourEnd(state)}
            run={run}
            steps={steps}
            hideCloseButton
            scrollToFirstStep
            showSkipButton
            showProgress
        />
        <Flex

            direction={{ base: "column", "xl": "row" }}
        >



          <Box width={{ base: "100%", xl: "70%" }} minWidth={{ base: "100%", xl: "70%" }} padding={"24px 32px"}>

            <H3 sx={styles.heading}>Lesson Plan</H3>

            <Box>
            <Image
                src={'/maps/UPSC.png'}
                alt="Description of the image"
                width="100%"
                height="300px"
                borderRadius={'20px'}
                objectFit={'cover'}
            />
              <ButtonSecondary style={{position:'relative',top:-60,left:30}} onClick={()=>{window.location.href='/library/map'}}>Map View for Notes</ButtonSecondary>
            </Box>


            <H3 sx={styles.heading} style={{marginTop:'5px'}}>Book Recommendations</H3>

            <Slider {...settings} draggable={true} arrows={false}>

              {bookRecommendations && bookRecommendations.map((book, index) => {
                return (
                    <Box>
                    <Flex
                        key={index}
                        sx={styles.bookContainer}

                        onClick={() => window.open(book.pdf_link)}
                    >
                      <Pmd color={"claydeck.brand.secondary"} textAlign="center">
                        {book.title}
                      </Pmd>
                      <Image
                          overflow={"hidden"}
                          objectFit={"contain"}
                          src={
                            book?.image ? book.image : "./assets/images/NCERT.png"
                          }
                          height={"135px"}
                          width={"103px"}
                          marginTop={"8px"}
                          marginBottom={"6px"}
                      />
                      <Pmd color={"claydeck.text.tertiary"} textAlign={"center"}>
                        {book.author}
                      </Pmd>
                    </Flex>
                    </Box>
                );
              })}
            </Slider>

              <H3 style={{marginTop:'30px'}} sx={styles.heading}>Feeds</H3>
            <Slider {...settings} draggable={true} arrows={false}>

              {feeds && feeds.map((feed, index) => {
                return (

                    <Box
                        key={index}
                        sx={styles.feedContainer}
                        onClick={() => window.open(feed.source)}
                    >
                      <Flex sx={styles.feedContent}>
                        <H3 sx={styles.feedHeading}>{feed.title}</H3>
                        <Image
                            src={
                              feed?.image
                                  ? feed.image
                                  : "./assets/images/HinduLogo.png"
                            }
                            height={"75px"}
                            width={"100px"}
                            objectFit={"contain"}
                            borderRadius={"4px"}
                        />
                      </Flex>
                      <Pmd
                          textAlign={"right"}
                          fontSize={{ base: "11px", md: "14px" }}
                      >
                        {moment(feed.pub_date).format("DD/MM/YYYY")}
                      </Pmd>
                    </Box>
                );
              })}
            </Slider>



          </Box>
          {/* user stats */}
          <Flex
              bgColor={"claydeck.white.primary"}
              direction={"column"}
              align={"center"}
              height={{base: 'min-content'}}
              minHeight={{base:'0vh',xl:'100vh'}}

              width={{ base: "100%", xl: "30%" }}
              // width={'30%'}
          >


            <Wrap align={'center'} justify={'center'} marginTop={'50px'}>
              <WrapItem>

                <Flex justifyContent={'center'} alignItems={'center'} width={'100%'} marginBottom={'100px'}>



                  <Flex
                      sx={styles.userStats}
                      // width={'100%'}
                      justifyContent={'space-around'}
                      id="test-score"
                  >
                    <Flex sx={styles.userStat} className="shadow-primary">
                      <Image
                          src={"./assets/icons/check-shield.svg"}
                          sx={styles.userStatIcon}
                      />
                      <Pmd sx={styles.userStatTitle}>News Quiz Score</Pmd>
                      <H2 sx={styles.userStatValue} color={"#4702CB"}>
                        {badges?.answersWritten}
                      </H2>
                    </Flex>
                    <Flex sx={styles.userStat} className="shadow-primary">
                      <Image
                          src={"./assets/icons/star.png"}
                          sx={styles.userStatIcon}
                      />
                      <Pmd sx={styles.userStatTitle}>Average Test Score</Pmd>
                      <H2 sx={styles.userStatValue} color={"#0BC500"}>
                        {badges?.pointsEarned}
                      </H2>
                    </Flex>
                    <Flex sx={styles.userStat} className="shadow-primary">
                      <Image
                          src={"./assets/icons/presence.png"}
                          sx={styles.userStatIcon}
                      />
                      <Pmd sx={styles.userStatTitle}>Answers Written</Pmd>
                      <H2 sx={styles.userStatValue} color={"#C47501"}>
                        {badges?.testsAttended}
                      </H2>
                    </Flex>

                    <Flex sx={styles.userStat} className="shadow-primary">
                      <Image
                          src={"./assets/icons/presence.png"}
                          sx={styles.userStatIcon}
                      />
                      <Pmd sx={styles.userStatTitle}>Mock Tests Done</Pmd>
                      <H2 sx={styles.userStatValue} color={"#C47501"}>
                        {badges?.testsAttended}
                      </H2>
                    </Flex>
                  </Flex>
                  <Flex alignItems={'center'} justifyContent={'center'} flexDirection={'column'} flexWrap={'wrap'} padding={'10px'}>
                    <Avatar src="assets\landing\section7Avatar.png" />
                    <br/>
                    <Pmd>Mains Target</Pmd>
                  </Flex>


                </Flex>


              </WrapItem>
              <WrapItem><Flex
                  width={"284px"}
                  backgroundColor={"#e9ffdb"}
                  textAlign={"center"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  borderRadius={"16px"}
                  padding={"25px"}
                  paddingTop={"71px"}
                  margin={{ base: "0 16px", "2xl": "0px 56px" }}
                  position={"relative"}
              >
                <Flex
                    height={"91px"}
                    width={"91px"}
                    backgroundColor={"#e9ffdb"}
                    justify={"center"}
                    align={"flex-end"}
                    borderRadius={"50px"}
                    position={"absolute"}
                    top={"-45px"}
                >
                  <Image src={"./assets/images/bulb.png"} height={"64px"} />
                </Flex>
                <H2
                    color={"claydeck.text.primary"}
                    fontSize={"20px"}
                    fontWeight={600}
                    lineHeight={"120%"}
                    letterSpacing={"0.2px"}
                    marginBottom={"10px"}
                >
                  Well Done!!
                </H2>
                <Pmd
                    color={"#2c2c2c"}
                    fontSize={"14px"}
                    fontWeight={400}
                    lineHeight={"140%"}
                    marginBottom={"20px"}
                >
                  You have completed almost 60 percent of tasks scheduled for
                  this week. Keep it up. Unlock new levels by taking a model
                  UPSC test here!
                </Pmd>
                <Box
                    as="button"
                    height={"36px"}
                    width={"207px"}
                    borderRadius={"5px"}
                    border={"1px solid #21005D"}
                >
                  <Pmd
                      color={"claydeck.brand.primary"}
                      fontSize={"14px"}
                      fontWeight={500}
                      margin={0}
                  >
                    Take Test
                  </Pmd>
                </Box>
              </Flex></WrapItem>
              <WrapItem>

                <Box id={"productivity"} margin={"65px 32px"}>
                  <Flex justify={"space-between"} align={"center"}>
                    <ButtonPrimary onClick={() => navigate('/lesson-plan')} display="flex" gap={"6px"} alignItems={"center"}>
                      <H3>Overall Productivity</H3>
                      <Icon as={AiOutlineArrowRight} />
                    </ButtonPrimary>
                    <Select
                        variant="filled"
                        iconColor="#fff"
                        iconSize="18px"
                        placeholder="Range"
                        height={"36px"}
                        width={"max-content"}
                        bgColor={"claydeck.brand.primary"}
                        color={"#fff"}
                        fontSize={"14px"}
                    >
                      <option value={"daily"} style={styles.option}>
                        Daily
                      </option>
                      <option value={"weekly"} style={styles.option}>
                        Weekly
                      </option>
                      <option value={"monthly"} style={styles.option}>
                        Monthly
                      </option>
                    </Select>
                  </Flex>
                  <Flex
                      gap={"15px"}
                      justify={"space-between"}
                      align={"center"}
                      margin={"40px 0px"}
                  >
                    <PieChart
                        style={{ height: "156px", width: "156px" }}
                        data={[
                          { title: "One", value: 10, color: "#21005D" },
                          { title: "Two", value: 15, color: "#9C27B0" },
                          { title: "Three", value: 20, color: "#D81B60" },
                          { title: "One", value: 30, color: "#FF9800" },
                          { title: "Two", value: 5, color: "#C0CA33" },
                          { title: "Three", value: 25, color: "#D7D7D7" },
                        ]}
                        lineWidth={20}
                        // label={({ dataEntry }) => dataEntry.value}
                        labelStyle={{
                          fontSize: "28px",
                          fontFamily: "Roboto, sans-serif",
                          fill: "#000",
                        }}
                        labelPosition={0}
                        paddingAngle={2}
                    />
                    <Flex direction={"column"} gap={"8px"}>
                      <Flex align={"center"}>
                        <Icon as={FaCircle} boxize={"9px"} color={"#21005D"} />
                        <Pmd sx={styles.labelInfo}>Group assignments</Pmd>
                      </Flex>
                      <Flex align={"center"}>
                        <Icon as={FaCircle} boxize={"9px"} color={"#9C27B0"} />
                        <Pmd sx={styles.labelInfo}>News reading</Pmd>
                      </Flex>
                      <Flex align={"center"}>
                        <Icon as={FaCircle} boxize={"9px"} color={"#D81B60"} />
                        <Pmd sx={styles.labelInfo}>Research</Pmd>
                      </Flex>
                      <Flex align={"center"}>
                        <Icon as={FaCircle} boxize={"9px"} color={"#FF9800"} />
                        <Pmd sx={styles.labelInfo}>Room engagements</Pmd>
                      </Flex>
                      <Flex align={"center"}>
                        <Icon as={FaCircle} boxize={"9px"} color={"#C0CA33"} />
                        <Pmd sx={styles.labelInfo}>Answering question papers</Pmd>
                      </Flex>
                      <Flex align={"center"}>
                        <Icon as={FaCircle} boxize={"9px"} color={"#D7D7D7"} />
                        <Pmd sx={styles.labelInfo}>Other tasks</Pmd>
                      </Flex>
                    </Flex>
                  </Flex>
                </Box>

              </WrapItem>

            </Wrap>




            {/* pie chart */}
            {/* </Box> */}
          </Flex>
        </Flex>




      </Box>
  );
}

export default Home;
