import React, { useState, useEffect } from "react";
import Joyride, { STATUS } from "react-joyride";

import {
  Avatar,
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Select,
  Tag,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import H2 from "../../Components/Typography/Heading/H2";
import H3 from "../../Components/Typography/Heading/H3";
import Psm from "../../Components/Typography/Text/Psm";
import Pmd from "../../Components/Typography/Text/Pmd";
import ButtonPrimary from "../../Components/Button/ButtonPrimary";

import { AiOutlineArrowRight, AiOutlineSearch } from "react-icons/ai";
import { PiFunnelLight } from "react-icons/pi";
import { FaCircle } from "react-icons/fa";

import axios from "axios";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { NEW_API_URL } from "../../redux/types";
import { useNavigate } from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";
import { setNewUser } from "../../redux/actions/userActions";
import { capsFirst } from "../../Components/utils";
// import ChakraCarousel from "../../Components/ChakraCarousel";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Slider from "react-slick";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";
import HeadCard from "../../Components/HeadCard";
import Pxl from "../../Components/Typography/Text/Pxl";
import P2xl from "../../Components/Typography/Text/P2xl";
import { ArrowBackIcon } from "@chakra-ui/icons";
import TopicCard from "../../Components/TopicCard";
import AskBudAccent from "../../Components/AskBudAccent";
import { useParams } from "react-router-dom";
import MultiLayerDropdown from "../../Components/MultiLayerDropdown";
import BackArrow from "../../Components/backArrow";

import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const TopicDetail = () => {
  const { topic, subject, area } = useParams();
  console.log(topic, "topic");
  const navigate = useNavigate();
  const newUser = useSelector((state) => state.user.newUser);
  const username = useSelector((state) => state.user.username);
  const selectedWorkspace = useSelector(
    (state) => state.user.selectedWorkspace
  );
  const user = useSelector((state) => state.user);
  let workspaceId = 1;

  const [topicDetail, setTopicDetail] = useState({});
  const [subjectData, setSubjectData] = useState([]);

  const get_map_data = async (username, workspace) => {
    const r = await axios.get(
      NEW_API_URL +
        "lessonplan/get_topic_detail/" +
        username +
        "/" +
        workspace +
        "/"
    );
    setTopicDetail(r.data.topic_detail);
    return r.data;
  };

  const get_lesson_map_data = async (username, workspace) => {
    const r = await axios.get(
      NEW_API_URL +
        "lessonplan/get_lessonplan/" +
        username +
        "/" +
        workspace +
        "/"
    );
    console.log(r);
    setSubjectData(r.data);
    // return r.data
  };

  useEffect(() => {
    get_lesson_map_data(username, workspaceId);
  }, []);

  useEffect(() => {
    get_map_data(username, topic);
  }, []);

  return (
    <>
      <Container
        justifyContent={"center"}
        alignItems={"center"}
        w={"90%"}
        maxW="container.xl"
      >
        <Flex
          flexDirection={"column"}
          alignItems={"space-between"}
          justifyContent={"space-between"}
          minH={"95vh"}
        >
          <Box>
            <Flex mt={"10vh"} alignItems={"center"}>
              <BackArrow />

              <Box style={{ display: "flex", alignItems: "center" }} flex={1}>
                <img
                  src={
                    topicDetail.topic_image ||
                    "https://smipismainstorage.s3.ap-south-1.amazonaws.com/imageUploads/medieval_history.jfif"
                  }
                  alt={"detail_icon"}
                  style={{
                    width: "45px",
                    height: "45px",
                    borderRadius: "12px",
                    marginRight: "8px",
                  }}
                />
                <Text mb={0} ml={0} mr={0} fontSize={"26px"} >
                  <strong>{topicDetail.topic_name}</strong>
                </Text>
              </Box>

              <div style={{width: 50, height: 50,marginRight:10}}>
                <CircularProgressbar value={topicDetail.completion_rate} text={`${Math.round(topicDetail.completion_rate,0)}%`}/>

              </div>

              {topicDetail.topic_priority === 'Low' && <Center px={'5px'} borderRadius={'50px'} backgroundColor={'#5F79D7'} minW={'50px'}
                                                            minH={'50px'} color={'white'}>{topicDetail.topic_priority}</Center>}
              {topicDetail.topic_priority === 'Medium' && <Center px={'5px'} borderRadius={'50px'} backgroundColor={'#F4B400'} minW={'50px'}
                                                               minH={'50px'} color={'white'}>{topicDetail.topic_priority}</Center>}
              {topicDetail.topic_priority === 'High' && <Center px={'5px'} borderRadius={'50px'} backgroundColor={'#FF0E36'} minW={'50px'}
                                                             minH={'50px'} color={'white'}>{topicDetail.topic_priority}</Center>}

            </Flex>
            <Box ml={"60px"} mt={"16px"}>
              <MultiLayerDropdown data={subjectData} text={"Change Topic"} />
            </Box>
            <hr />

            <Flex
              gap={"40px"}
              alignItems={"flex-start"}
              justifyContent={"space-between"}
            >
              <Box>
                <P2xl mt={12}>
                  <strong>Learn</strong>
                </P2xl>
                <Wrap spacing={"20px"}>
                  <WrapItem>
                    <TopicCard
                      to={
                        "/learn/" +
                        subject +
                        "/" +
                        area +
                        "/" +
                        topic +
                        "/readings"
                      }
                      title={"Reading List"}
                      icon={"/detail_reading.png"}
                      shadow
                    />
                  </WrapItem>
                  <WrapItem>
                    <TopicCard
                      title={"Notes"}
                      icon={"/detail_notes.png"}
                      to={
                        "/learn/" +
                        subject +
                        "/" +
                        area +
                        "/" +
                        topic +
                        "/notes"
                      }
                      shadow
                    />
                  </WrapItem>
                </Wrap>
              </Box>

              <Box>
                <P2xl mt={12}>
                  <strong>Master</strong>
                </P2xl>
                <Wrap spacing={"20px"}>
                  <WrapItem>
                    <TopicCard
                      title={"Maps & Timelines"}
                      icon={"/detail_map.png"}
                      to={
                        "/learn/" +
                        subject +
                        "/" +
                        area +
                        "/" +
                        topic +
                        "/timeline"
                      }
                      shadow
                    />
                  </WrapItem>

                  <WrapItem>
                    <TopicCard
                      title={"Case Studies"}
                      icon={"/itools.png"}
                      to={
                        "/learn/" +
                        subject +
                        "/" +
                        area +
                        "/" +
                        topic +
                        "/itools"
                      }
                      shadow
                    />
                  </WrapItem>
                </Wrap>
              </Box>
            </Flex>

            <P2xl mt={10}>
              <strong>Apply</strong>
            </P2xl>
            <Wrap spacing={"20px"}>
              <WrapItem>
                <TopicCard
                  to={"/learn/" + subject + "/" + area + "/" + topic + "/news"}
                  title={"News"}
                  icon={"/detail_news.png"}
                  shadow
                />
              </WrapItem>
              <WrapItem>
                <TopicCard
                  to={"/learn/" + subject + "/" + area + "/" + topic + "/tests"}
                  title={"Tests"}
                  icon={"/detail_test.png"}
                  shadow
                />
              </WrapItem>
            </Wrap>
          </Box>
          {/* <Box mb={"20px"}>
            <Pmd fontWeight={"300px"} mt={"50px"}>
              To access all the features go to the app
            </Pmd>
            <ButtonPrimary>App Button Here</ButtonPrimary>
          </Box> */}
        </Flex>

        {topicDetail.topic_name && (
          <AskBudAccent
            context={topicDetail.topic_name}
            initText={
              "You are a UPSC assistant. Try to use succinct and to-the-point stoic language with academic inclination. Give answers in the context of " +
              topicDetail.topic_name +
              " topic of UPSC"
            }
          />
        )}
      </Container>
    </>
  );
};

export default TopicDetail;
