import axios from "axios";
import { useState } from "react";

const useGetPracticeTestSubs = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorsub, setErrorSub] = useState({});

  const fetchPracticeTestSubs = async (ws_id, username) => {
    try {
      const res = await axios.get(
        `https://55aaqcxwk7.execute-api.ap-south-1.amazonaws.com/dev/library/get_practice_tests/${ws_id}/${username}/`
      );
      console.log(res);
      return res?.data;
    } catch (error) {
      console.error("Error fetching response:", error);
      setErrorSub(error);
    }
  };

  return { fetchPracticeTestSubs, errorsub, isLoading, setIsLoading };
};

export default useGetPracticeTestSubs;
