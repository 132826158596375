import ButtonTag from "../../Components/Button/ButtonTag";
import { Container, Stack, Box, Spinner, Flex } from "@chakra-ui/react";
import CardItems from "./components/CardItems";
import { nanoid } from "nanoid";
import Pmd from "../../Components/Typography/Text/Pmd";
import { useSelector } from "react-redux";
import useForumGetData from "../../hooks/useForumGetData";

const Forums = () => {
  const dataApi = useSelector((state) => state.forumData.data.message);
  const { isLoading } = useForumGetData();

  return (
    <Container
      position={"relative"}
      maxW={"738px"}
      paddingX={"22px"}
      paddingY={"48px"}
    >
      <Stack
        direction="row"
        spacing={4}
        align="center"
        marginBottom={"6px"}
        wrap={"wrap"}
      >
        <ButtonTag variant="outline" buttonText={"All"} />
        <ButtonTag variant="outline" buttonText={"Trending"} />
        <ButtonTag variant="outline" buttonText={"Questions"} />
        <ButtonTag variant="outline" buttonText={"Ideas"} />
        <ButtonTag variant="outline" buttonText={"Solutions"} />
        <ButtonTag variant="outline" buttonText={"UPSC"} />
        <ButtonTag variant="outline" buttonText={"Current Affair"} />
        <ButtonTag variant="outline" buttonText={"NEWS"} />
        <ButtonTag variant="outline" buttonText={"Political"} />
        <ButtonTag variant="outline" buttonText={"SENSEX"} />
        <ButtonTag variant="outline" buttonText={"International"} />
        <ButtonTag variant="outline" buttonText={"Other"} />
      </Stack>
      <Flex justify={"flex-end"} marginBottom={"16px"}>
        <Box
          as="a"
          href="/forums/compose"
          height={"38px"}
          width={"max-content"}
          backgroundColor={"#6750a4"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          rounded={"md"}
          shadow={"dark-lg"}
          padding={"0px 22px"}
        >
          {/* <EditPencil /> */}
          <Pmd
            color={"claydeck.white.primary"}
            fontSize={"14px"}
            marginBottom={"0px"}
          >
            + Create
          </Pmd>
        </Box>
      </Flex>
      {isLoading ? (
        <Flex justify={"center"} align={"center"}>
          <Spinner color="claydeck.brand.secondary" size={"md"} />
        </Flex>
      ) : (
        <Flex
          direction={"column"}
          gap={{ base: "16px", md: "18px", lg: "20px" }}
        >
          {dataApi?.map((item) => (
            <CardItems key={item.id} data={item} />
          ))}
        </Flex>
      )}
    </Container>
  );
};

export default Forums;
