import axios from "axios"
import {API_URL, NEW_API_URL} from "../redux/types";

export const getTodaysHeadline = async(workspace,username) => {
    let response = await axios.get(NEW_API_URL+'/'+"feed/today_news/"+workspace+"/"+username+"/")
    return response
}

export const getFeedItem = async(item) => {
    let response = await axios.get(`https://kk2z2518c6.execute-api.ap-south-1.amazonaws.com/staging/feed/${item.news_fetched_date}/${item.news_id}`) 
    return response
}