import {useState} from "react";
import { Link, useNavigate } from "react-router-dom";

import {useGoogleLogin} from "react-google-login";

import {Box, Grid, GridItem, Checkbox, Image, Center, Flex, Text, HStack} from "@chakra-ui/react";
import Pmd from "../../Components/Typography/Text/Pmd";
import H2 from "../../Components/Typography/Heading/H2";
import InputOutline from "../../Components/Input/InputOutline";
import InputPassword from "../../Components/Input/InputPassword";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";

import {useDispatch, useSelector} from "react-redux";
import {loginUser, signupUser} from "../../redux/actions/userActions";
import { Carousel } from "react-responsive-carousel";
import Plg from "../../Components/Typography/Text/Plg";
import {GoogleLogin} from "@react-oauth/google";
import Psm from "../../Components/Typography/Text/Psm";
import ButtonPrimary from "../../Components/Button/ButtonPrimary";
import IntroCarousel from "./IntroCarousel";

const clientId =
  'dummy'; //"105604948407-qgjbl3k27icekteikgjq56tehc4tlnuc.apps.googleusercontent.com"; // to be replaced with actual client id


const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const signuploading = useSelector((state) => state.UI.signupLoading);

  const [firstName, setFirstName] = useState("");
  const [isAccountCreateLoading, setIsAccountCreateLoading] = useState(false);
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [tancAcceptence, setTandcAcceptance] = useState(false);

  const onSuccess = (res) => {
    console.log('Login Success: currentUser:', res.profileObj);
    const prf = res.profileObj;

    dispatch(loginUser({
      email:prf.email,
      password:prf.email+''+prf.googleId,
      username:prf.email,
      isGoogle:true,
      prf:prf
    }, rememberMe, navigate));

    // const userData = {
    //   first_name: '',
    //   last_name: '',
    //   username: email,
    //   email: email,
    //   password: prf.email+''+prf.googleId,
    // };
    //
    // dispatch(signupUser(userData, rememberMe, navigate));

    // setIsSigningIn(false);
  }

  const onFailure = (res) => {
    console.log('Login failed res:', res);
  }

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    isSignedIn: true,
    accessType: "offline",
  });

  const validation = () => {
    // name validation
    const regexName = /^[a-zA-Z ]+$/;
    const testFirstName = regexName.test(firstName);
    const testLastName = regexName.test(lastName);

    if (!testFirstName) {
      alert("Please enter a first name");
      return;
    }
    if (!testLastName) {
      alert("Please enter a last name");
      return;
    }


    // email validation
    const regexEmail =
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/;
    const testEmail = regexEmail.test(email);
    if (!testEmail) {
      alert("Please enter a valid email address.");
      return;
    }

    // password validation
    const regexPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    const testPassword = regexPassword.test(password);
    if (!testPassword) {
      alert(
        "Password must contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character:"
      );
      return;
    }

    // password match validation
    if (password !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    if (!tancAcceptence) {
      alert("Please accept the terms and conditions");
      return;
    }

    return (
      email !== "" &&
      password !== "" &&
      confirmPassword !== ""
    );
  };

  const handleSubmit = () => {
    setIsAccountCreateLoading(true)

    if (!validation()) {
      setIsAccountCreateLoading(false)

      return;
    }

    const userData = {
      first_name: firstName,
      last_name: lastName,
      username: email,
      email: email,
      password: password,
      user_image: "https://smipismainstorage.s3.ap-south-1.amazonaws.com/imageUploads/avatar1.png",
    };

    dispatch(signupUser(userData, rememberMe, navigate));
    // setIsAccountCreateLoading(false)
  }

  return (
    <Grid
      columns={{ base: 1, lg: 2 }}
      templateColumns={{ base: "1fr", lg: "2fr 1.5fr" }}
    >
     <IntroCarousel/>
      <GridItem
        w="100%"
        h="100vh"
        display="flex"
        flexDirection={"column"}
        alignItems={"center"}
        bgColor={"claydeck.brand.secondary"}
        // paddingTop={"66px"}
      >
        <Box
          height={"100%"}
          width={"80%"}
          maxWidth={"620px"}
          display={"flex"}
          flexDirection={"column"}
          // justifyContent={"space-evenly"}
        >
          {/* <Image
            height={"80px"}
            objectFit={"contain"}
            src={"./assets/images/claydeck-logo.png"}
            alt={"site-logo"}
            alignSelf={"flex-start"}
            // marginBottom={"68px"}
          /> */}
          <Box mt={'120px'}>
            {/* <Box marginBottom={"39px"}>
              <Pmd color={"claydeck.text.tertiary"} marginBottom={"16px"}>
                Sign up with?
              </Pmd>
              <ButtonSecondary
                onClick={()=>signIn}
                width={"150px"}
                marginRight={"24px"}
              >
                Google
              </ButtonSecondary>
            </Box> */}
            {/* <ButtonSecondary width={"150px"}>Facebook</ButtonSecondary> */}
            <Grid gap={"24px"}>
              <GridItem
                display={"flex"}
                justifyContent={"space-between"}
                gap={"24px"}
              >
                <Box width={"100%"}>
                  <Pmd
                    htmlFor="firstName"
                    color={"white"}
                    mb={"16px"}
                  >
                    First Name
                  </Pmd>
                  <InputOutline
                    type="text"
                    name="firstName"
                    placeholder={"John"}
                    value={firstName}
                    onChange={(event) =>
                      setFirstName(event.currentTarget.value)
                    }
                  />
                </Box>
                <Box width={"100%"}>
                  <Pmd
                    htmlFor="lastName"
                    color={"white"}
                    mb={"16px"}
                  >
                    Last Name
                  </Pmd>
                  <InputOutline
                    type="text"
                    name="lastName"
                    placeholder={"Doe"}
                    value={lastName}
                    onChange={(event) => setLastName(event.currentTarget.value)}
                  />
                </Box>
              </GridItem>
              <GridItem>
                <Pmd
                  htmlFor="email"
                  color={"white"}
                  mb={"16px"}
                >
                  Email
                </Pmd>
                <InputOutline
                  type="email"
                  name="email"
                  placeholder={"example@email.com"}
                  value={email}
                  onChange={(event) => setEmail(event.currentTarget.value)}
                />
              </GridItem>
              <GridItem>
                <Pmd
                  htmlFor="password"
                  color={"white"}
                  mb={"16px"}
                >
                  Password
                </Pmd>
                <InputPassword
                  value={password}
                  onChange={(event) => setPassword(event.currentTarget.value)}
                />
              </GridItem>
              <GridItem>
                <Pmd
                  htmlFor="confirmPassword"
                  color={"white"}
                  mb={"16px"}
                >
                  Confirm Password
                </Pmd>
                <InputPassword
                  value={confirmPassword}
                  placeholder={'Confirm Password'}

                  onChange={(event) =>
                    setConfirmPassword(event.currentTarget.value)
                  }
                />
              </GridItem>

            </Grid>
            <HStack marginTop={"18px"} alignItems={'center'}>
             <Checkbox
               isChecked={tancAcceptence}
               onChange={(e) => setTandcAcceptance(e.target.checked)}
               mr={'8px'}
             />
             <p
             style={{color:'white',marginBottom:0}}
             >I accept Claydeck's
             <span
             onClick={() => window.open('/tandc', 'T&C', 'width=1000,height=400')}
             style={{color:'#75BFF5', fontWeight:800, cursor:'pointer',}}
             > Terms & Conditions</span>
             </p>

            </HStack>
            {/*<Box marginTop={"18px"}>*/}
            {/*  <Checkbox*/}
            {/*    isChecked={rememberMe}*/}
            {/*    onChange={(e) => setRememberMe(e.target.checked)}*/}
            {/*  >*/}
            {/*    <Pmd color={'white'} fontWeight='800'>Remember Me</Pmd>*/}
            {/*  </Checkbox>*/}
            {/*</Box>*/}
          </Box>



          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            // marginTop={"48px"}
          >
            <ButtonPrimary
                isLoading={signuploading}
                width={"100%"}
              // minWidth={"250px"}
              // maxWidth={"400px"}
              // height={"56px"}
              color={"white"}
              bg={"claydeck.brand.primary"}
              borderRadius={"50px"}
                mt={'40px'}
              marginBottom={0}
              onClick={() => handleSubmit()}
            >
              Create Account
            </ButtonPrimary>
            <Link to={"/signin"}>
            <Box display={"flex"} flexDirection={'row'} marginTop={"24px"}>

              <Pmd color={"#75BFF5"}>Already have an account?</Pmd>
              <Pmd color='#75BFF5' style={{fontWeight:800, marginLeft:'4px'}}>Sign In</Pmd>

            </Box>
            </Link>
          </Box>

          <Flex alignItems={"center"} justifyContent={"center"} marginTop={3}>
            <hr style={{width: '40%', border: '1px solid #838383'}}/>
            <Psm margin={0} color={'white'} marginLeft={'20px'} marginRight={'20px'}>Or</Psm>
            <hr style={{width: '40%', border: '1px solid #838383'}}/>

          </Flex>

          {/*<Flex*/}
          {/*  direction={{ base: "column", md: "row" }}*/}
          {/*  gap={{ base: "12px", md: "0px" }}*/}
          {/*  justify={"space-between"}*/}
          {/*  alignItems={{ base: "flex-start", md: "center" }}*/}
          {/*  marginTop={"18px"}*/}
          {/*>*/}
          {/*  <Checkbox*/}
          {/*    isChecked={rememberMe}*/}
          {/*    onChange={(e) => setRememberMe(e.target.checked)}*/}
          {/*  >*/}
          {/*    <H2>Remember Me</H2>*/}
          {/*  </Checkbox>*/}
          {/*  <ButtonPrimary onClick={onOpen}>Forgot Password?</ButtonPrimary>*/}
          {/*</Flex>*/}
          <Flex justifyContent={'center'} mt={'10px'}>

            <GoogleLogin
                style={{width:'100%'}}
                onSuccess={credentialResponse => {
                  console.log(credentialResponse);
                  const profile = JSON.parse(atob(credentialResponse.credential.split(".")[1]))
                  console.log(JSON.parse(atob(credentialResponse.credential.split(".")[1])));


                  // console.log("Login Success: currentUser:", res.profileObj);
                  const prf = profile;
                  prf['googleId'] = prf['sub']

                  dispatch(loginUser({
                    email:prf.email,
                    password:prf.email+''+prf.googleId,
                    username:prf.email,
                    isGoogle:true,
                    prf:prf
                  }, rememberMe, navigate));

                  // const userData = {
                  //   first_name: '',
                  //   last_name: '',
                  //   username: email,
                  //   email: email,
                  //   password: prf.email+''+prf.googleId,
                  // };
                  //
                  // dispatch(signupUser(userData, rememberMe, navigate));

                  // setIsSigningIn(false);

                }}
                onError={() => {
                  console.log('Login Failed');
                }}
            />
          </Flex>


        </Box>
      </GridItem>
    </Grid>
  );
};

export default SignUp;
