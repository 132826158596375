import { Box, Text, Textarea, Button, Progress } from "@chakra-ui/react";
import { useState, useEffect, useRef } from "react";
import useGetSubFeedback from "../utils/getSubjectiveFeedback";
import { useToast } from "@chakra-ui/react";
import { Tooltip } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import usePostSubmitTestData from "../utils/postSubmitTestData";

const SubjectiveTestComp = () => {
  const { fetchSubFeedback, isLoading, setIsLoading, error } =
    useGetSubFeedback();

  const [feedback, setFeedback] = useState([]);

  const { postSubmitTestData, isLoadingpost, setIsLoadingpost, errorPost } =
    usePostSubmitTestData();

  const que = useSelector((state) => state.subTestQue.sub_questions);

  const param = useParams();

  const [isQuizCompleted, setQuizCompleted] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [timerStop, setTimerStop] = useState(false);
  const [answer, setAnswer] = useState("");
  const [timer, setTimer] = useState(600);
  const [submitData, setSubmitData] = useState({
    practice_type: "subjective paper",
    test_format: "subjective",
    subject: "history",
    chapter: "chapter 8",
    max_score: 0,
    user_score: 0,
    time_taken: 0,
    question: {},
  });

  const toast = useToast();

  function countWords(s){
    s = s.replace(/(^\s*)|(\s*$)/gi,"");//exclude  start and end white-space
    s = s.replace(/[ ]{2,}/gi," ");//2 or more space to 1
    s = s.replace(/\n /,"\n"); // exclude newline with a start spacing
    return s.split(' ').filter(function(str){return str!="";}).length;
    //return s.split(' ').filter(String).length; - this can also be used
  }

  function wordCountNeeded(s){
    var word_count = s.match(/\d+ words/)[0];
    var matches = Number(word_count.match(/\d+/)[0]);
    
    if (matches == null){
     matches = 250;
    }
    return matches 
  }

  const handleclickFeedback = async () => {
    let res = {
      Feedback : ""
    }
    setShowFeedback(true);
    setIsLoading(true);
    let word_count_needed = wordCountNeeded(que)
    let word_count = countWords(answer)
    if (word_count >= word_count_needed){
    res = await fetchSubFeedback("rahulk30s@gmail.com", 2, {
      question: que,
      answer: answer,
    });
    } else {
      res = {
        Feedback : "Please write upto minimum word count. Current word count " + String(word_count)
      }
    }

    console.log("--->", res);
    setFeedback(res);
    setIsLoading(false);
  };

  console.log("error", errorPost, error);

  const boxRef = useRef(null);
  const handleCopyToClipboard = () => {
    if (!error) {
      if (boxRef.current) {
        const textToCopy = feedback?.Feedback;
        navigator.clipboard
          .writeText(textToCopy)
          .then(() => {
            toast({
              title: "Feedback coppied",
              description: "Learn from this feedback and score more",
              position: "top-right",
              variant: "left-accent",
              status: "success",
              duration: 4500,
              isClosable: true,
            });
          })
          .catch((error) => {
            console.error("Error copying to clipboard: ", error);
          });
      }
    } else {
      toast({
        title: "There is no Feedback",
        description: "Try again to generate feedback",
        position: "top-right",
        variant: "left-accent",
        status: "warning",
        duration: 3500,
        isClosable: true,
      });
    }
  };

  console.log("hello", errorPost);

  const handleSubmit = () => {
    if (Object.keys(errorPost).length > 0) {
      toast({
        title: `Something went wrong! Try again later`,
        status: "error",
        isClosable: true,
        position: "top-right",
        duration: 3000,
      });
      return;
    }
    setQuizCompleted(true);

    submitData.question = {
      Questions: que[param?.id],
      Answer: answer,
      Feedback:
        feedback.length > 0 ? feedback?.Feedback : "No feedback generated",
    };
    submitData.time_taken = 600 - timer;

    console.log(submitData);

    const postData = async () => {
      setIsLoadingpost(true);
      await postSubmitTestData("patelhetvarasada@gmail.com", 2, 1, submitData);
      setIsLoadingpost(false);
      toast({
        title: `Data submitted successfully`,
        position: "top-right",
        isClosable: true,
        status: "success",
        duration: 3000,
      });
    };

    postData();
  };

  useEffect(() => {
    if (timerStop) {
      return;
    } else {
      if (!isQuizCompleted) {
        const interval = setInterval(() => {
          setTimer((prevTimer) => prevTimer - 1);

          if (timer === 0) {
            setTimer(0);
            clearInterval(interval);
            setQuizCompleted(true);
          }
        }, 1000);

        return () => {
          clearInterval(interval);
        };
      }
    }
  }, [timer]);

  return (
    <Box
      display={"flex"}
      width={"full"}
      flexDir={"column"}
      padding={8}
      margin={8}
      gap={4}
    >
      <Box width={"full"}>
        <Box
          backgroundColor={"purple.100"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          padding={2}
          borderRadius={"md"}
          borderWidth={"2px"}
          borderTopColor={"purple.300"}
          boxShadow={"md"}
        >
          <Text
            fontSize={"xl"}
            fontWeight={"bold"}
            letterSpacing={"2px"}
            margin={"0"}
          >
            Subjective Test
          </Text>
        </Box>
      </Box>
      {isQuizCompleted ? (
        <>
          <Text fontSize={"lg"} mt={4} mb={0}>
            <b>Congratulations You have completed quiz 🎉</b>
          </Text>
          <Text fontWeight={"semibold"} mt={0} mb={4}>
            {isQuizCompleted ? "Time Left" : "Timer"} : {Math.floor(timer / 60)}{" "}
            mins : {timer % 60} seconds
          </Text>
        </>
      ) : (
        <>
          <Box>
            <Text fontWeight={"semibold"} mt={4} mb={4}>
              {isQuizCompleted ? "Time Left" : "Timer"} :{" "}
              {Math.floor(timer / 60)} mins : {timer % 60} seconds
            </Text>
            <Text
              fontSize={"lg"}
              color={"purple.400"}
              fontWeight={450}
              mt={"8px"}
            >
              Answer following question in your words within 10 min :
            </Text>
          </Box>
          <Box
            backgroundColor={"purple.100"}
            padding={4}
            rounded={"md"}
            flexDirection={"column"}
          >
            <Text>{que}</Text>

            <Box
              onClick={handleclickFeedback}
              display={"inline-block"}
              transition={"ease-in-out"}
              transitionDuration={"200ms"}
              background={"purple.200"}
              paddingX={3}
              paddingY={2}
              rounded={"lg"}
              _hover={{
                background: "purple.300",
                cursor: "pointer",
                color: "purple.600",
              }}
              ml={"805px"}
            >
              Feedback Preview
              <Text fontSize={"10px"} m={0} color={"black"} ml={"65px"}>
                Powered by AI
              </Text>
            </Box>
          </Box>
          <Textarea
            backgroundColor={"purple.50"}
            resize={"vertical"}
            value={answer}
            onChange={(e) => {
              setTimerStop(false);
              setAnswer(e.target.value);
            }}
            mt={2}
            placeholder="Type your answer here"
            borderWidth={"0.9px"}
          />
          <Box alignSelf={"center"} mt={4}>
            <Button
              size="sm"
              backgroundColor={"purple.100"}
              borderWidth={"2px"}
              borderColor={"purple.200"}
              fontWeight={"bold"}
              letterSpacing={"1px"}
              _hover={{
                backgroundColor: "purple.50",
              }}
              _active={{
                transform: "translateY(4px)",
              }}
              onClick={handleSubmit}
            >
              {/* {isLoading ? <Spinner size={"sm"} color="purple.500" /> : "Submit"} */}
              Submit
            </Button>
          </Box>
        </>
      )}
      {isLoading ? (
        <Box
          display={"flex"}
          flexDir={"column"}
          gap={2}
          mt={5}
          transition={"ease-in-out"}
          transitionDuration={"300ms"}
        >
          <Text fontSize={"lg"} color={"purple.400"} fontWeight={"blold"} m={0}>
            Generating Feedback AI on working... Be patient
          </Text>
          <Text fontSize={"md"} color={"purple.300"} fontWeight={450}>
            Meanwhile cheack your answer , It takes some time : )
          </Text>
          <Progress
            size="xs"
            isIndeterminate
            backgroundColor={"purple.200"}
            colorScheme="purple"
          />
        </Box>
      ) : (
        showFeedback && (
          <Tooltip
            label="Click to coppy"
            hasArrow
            bg={"purple.100"}
            color={"black"}
            mt={0.5}
          >
            <Box
              ref={boxRef}
              onClick={handleCopyToClipboard}
              transition={"ease-in-out"}
              transitionDuration={"200ms"}
              background={"purple.50"}
              padding={2}
              cursor={"pointer"}
              _hover={{
                padding: "10px",
                backgroundColor: "purple.100",
                rounded: "lg",
                fontWeight: "450",
              }}
            >
              <Text fontSize={"xl"} fontWeight={"bold"}>
                Feedback generated by AI :
              </Text>
              {!error
                ? feedback?.Feedback
                : "Technical issue, Please try again !!"}
            </Box>
          </Tooltip>
        )
      )}
    </Box>
  );
};

export default SubjectiveTestComp;
