import {
    SET_NOTIFICATION,
    SET_TASK_SOUND
} from "../types";

const initialState = {
    notificationEnabled: true,
    taskSoundEnabled: true
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_NOTIFICATION: {
      return {
        ...state,
        notificationEnabled: action.payload,
      };
    }
    case SET_TASK_SOUND: {
      return {
        ...state,
        taskSoundEnabled: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}