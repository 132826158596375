import { Box, Text, Center, Spinner } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import useGetPracticeTestSubs from "../utils/getPracticeTestSubs";
import { useEffect, useState } from "react";
const PracticePaperChapters = () => {
  const params = useParams();

  const { fetchPracticeTestSubs, isLoading, setIsLoading } =
    useGetPracticeTestSubs();

  const [testChaps, setTestChaps] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    const fetchSubs = async () => {
      const res = await fetchPracticeTestSubs(2, "rahulk30s@gmail.com");
      setTestChaps(res?.test[params?.id]);
      setIsLoading(false);
    };

    fetchSubs();
  }, []);

  console.log("hello practice test");

  const renderChap = () => {
    return testChaps?.tests?.map((test, i) => (
      <Box
        key={i}
        as="a"
        href={`/library/practice-quize/${params?.subject}/${test?.name}/${test?.test_id}`}
        fontSize={"md"}
        fontWeight={"semibold"}
        boxShadow={"sm"}
        display={"block"}
        borderWidth={"2px"}
        borderRadius={"lg"}
      >
        <Text backgroundColor={"purple.200"} padding={2} margin={0}>
          {params?.subject + " : " + test?.name}
        </Text>
      </Box>
    ));
  };
  return (
    <Box
      display={"flex"}
      width={"full"}
      flexDir={"column"}
      padding={8}
      margin={8}
    >
      <Box width={"full"}>
        <Box
          backgroundColor={"purple.100"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          padding={2}
          borderRadius={"md"}
          borderWidth={"2px"}
          borderTopColor={"purple.300"}
          boxShadow={"md"}
        >
          <Text
            fontSize={"xl"}
            fontWeight={"bold"}
            letterSpacing={"2px"}
            margin={"0"}
          >
            {params?.subject} Tests
          </Text>
        </Box>
      </Box>
      <Box
        display={"flex"}
        flexWrap={"wrap"}
        flexDir={"row"}
        gap={4}
        marginTop={8}
      >
        {isLoading ? (
          <Center ml={"455px"} mt={8}>
            <Spinner color="purple.500" />
          </Center>
        ) : (
          renderChap()
        )}
      </Box>
    </Box>
  );
};

export default PracticePaperChapters;
