import React, {useEffect, useState} from 'react';
import {Button, Heading, Text} from '@chakra-ui/react'
import { Radio, RadioGroup } from '@chakra-ui/react'
function ButtonCheckboxSecondary(props) {
    return (
        <Button

            bg='claydeck.white.primary' variant={"outline"} color="claydeck.brand.primary" _hover={{bg:"claydeck.brand.hover"}} borderRadius={"5px"} {...props}
            onClick={(e)=>{
                if(props.onClick)
                    props.onClick(e)
            }}><Radio
           isChecked={props.checked} onClick={(e)=>{
            if(props.onClick)
                props.onClick(e)
        }} value={props.checkboxValue || "1"}>{props.children}</Radio></Button>
    );
}

export default ButtonCheckboxSecondary;
