import moment from "moment";

import {
  SET_NEW_USER,
  SET_REMEMBER_ME,
  API_URL,
  NEW_API_URL,
  CLEAR_ERRORS,
  LOADING_UI,
  LOADING_USER,
  MARK_NOTIFICATIONS_READ,
  SET_ERRORS,
  SET_GROUPS,
  SET_UNAUTHENTICATED,
  SET_USER,
  SET_KEY,
  SET_ACCESS_TOKEN,
  ADD_WORKSPACES,
  SET_WORKSPACE,
  LEAVE_WORKSPACE,
  SET_USERNAME,
  ADD_FEED,
  SET_SUBSCRIPTION,
  SET_PROFILE,
  STOP_LOADING_UI,
  LOGIN_LOADING_UI,
  STOP_LOGIN_LOADING_UI, START_SIGNUP_LOADING_UI, STOP_SIGNUP_LOADING_UI, FAILED_SIGNIN
} from "../types";
import { capitalizeFirstLetter } from "../../utils/stringManipulation";

import axios from "axios";
import { sortProfileData } from "../../utils/sortData";
import { datadogRum } from "@datadog/browser-rum";

let key;

// getAsyncStorageItem("KEY").then(val => key = val);

if (key) {
  // console.log('FOUND KEY', JSON.parse(localStorage.getItem('KEY')).key);
  axios.defaults.headers.common["Authorization"] = `Token ${
    JSON.parse(key).key
  }`;
  // setAuthorizationHeader(JSON.parse(localStorage.getItem("KEY")).key)
}

// authentication

const setAuthorizationHeader = (access, refresh) => (dispatch) => {
  const FBIdToken = `Token ${access}`;
  // console.log(FBIdToken);
  //localStorage.setItem('FBIdToken', FBIdToken);
  axios.defaults.headers.common["Authorization"] = FBIdToken;
  // console.log(access, refresh)
  dispatch({
    type: SET_KEY,
    payload: {
      access,
      refresh,
    },
  });
};

export const refreshToken = (token) => (dispatch) => {
  // dispatch({ type: LOADING_UI });

  axios
    .post(`${NEW_API_URL}user/refreshtoken/`, {
      refresh: token,
    })
    .then((res) => {
      // console.log("refresh res", JSON.stringify(res));
      // remove the below line and use setAuthorizationHeader function
      // when refresh token and access token both are recieved from api call
      dispatch({
        type: SET_ACCESS_TOKEN,
        payload: res.data.access,
      });
      // dispatch({ type: STOP_LOADING_UI });
    })
    .catch((error) => {
      console.log("refresh error", JSON.stringify(error));
      // dispatch({ type: STOP_LOADING_UI });

      dispatch(logoutUser());
    });
};

export const setNewUser = (state) => {
  return {
    type: SET_NEW_USER,
    payload: state
  }
}

const fetchSubscription = async (username) => {
  try {
    // setIsLoading(true);
    const res = await axios.get(
        `${NEW_API_URL}community/plans/${username}/`
    );
    console.log(res.data,'subs');
    return res.data
  } catch (error) {
    // setIsLoading(false);
    console.error("home data fetch failed", error);
  }
};

const activatePlan = async (username,plan_id) => {
  try {
    // setIsLoading(true);
    const res = await axios.post(
        `${NEW_API_URL}community/api/activate-plan/`, {
          username: username,
          plan_id: plan_id,
        },
    );
    console.log(res.data,'subs');
    return res.data
  } catch (error) {
    // setIsLoading(false);
    console.error("home data fetch failed", error);
  }
}

export const signupUser =
  (newUserData, remember, navigate, securityQuestion) => (dispatch) => {
    console.log("new", newUserData);
    dispatch({
      type: START_SIGNUP_LOADING_UI
    });

    axios
      .post(NEW_API_URL + "user/register/", newUserData)
      .then(async (res) => {
        console.log("res", JSON.stringify(res));

        if (res.status === 201) {


          dispatch(
              loginUser({
                username: newUserData.username,
                password: newUserData.password,
                activate:true
              })
          )

          navigate("/home", {
            state: {
              username: newUserData.username,
              password: newUserData.password,
              first_name: newUserData.first_name,
              last_name: newUserData.last_name
            },
          });
          dispatch({
            type: SET_REMEMBER_ME,
            payload: remember
          });
          dispatch(setNewUser(true));
        }
      })
      .catch((err) => {
        console.log("err sign up", JSON.stringify(err));
        if (err.response?.data) {
          console.log(err.response.data);

          if(err.response.data === "User already exists"){
            alert("User already exists, please use login instead")
            return
          }

          const errors = err.response.data.errors;
          if(!errors){
            alert("An unknown error has occurred! please try again later or contact admin")
            return
          }
          if (errors.hasOwnProperty("username")) {
            alert(capitalizeFirstLetter(errors.username[0]));
          }
          if (errors.hasOwnProperty("email")) {
            alert(capitalizeFirstLetter(errors.email[0]));
          } else alert("Unable to signup with the given info");
        } else alert("Unable to signup with the given info");
      }).finally(()=>{
        dispatch({
          type: STOP_SIGNUP_LOADING_UI
        });
    });
  };

export const loginUser = (userData, remember,nav) => async (dispatch) => {
  console.log(userData);
  // dispatch({
  //   type: LOGIN_LOADING_UI
  // })
  await axios
    .post(NEW_API_URL + "user/gettoken/", userData)
    .then(async (res) => {
      console.log("Logging INNNN");
      // console.log(JSON.stringify(res));

      if (res.status === 200) {
        // dispatch({
        //   type: SET_REMEMBER_ME,
        //   payload: remember
        // })
        dispatch({
          type: SET_USERNAME,
          payload: userData.username,
        });
        localStorage.setItem('access',res.data.access)
        localStorage.setItem('refresh',res.data.refresh)
        // calling get profile data api so that the user's details get saved in redux
        dispatch(getProfileData(res.data.access));
        dispatch(getUserWorkspaces(userData.username));
        dispatch(setAuthorizationHeader(res.data.access, res.data.refresh));
        datadogRum.setUser({
          id: userData.username,
          name: userData.username,
      });

        let plans = await fetchSubscription(userData.username)
        console.log(plans,'all-plans')
        if(userData.activate)
        await activatePlan(userData.username,plans.find((i)=>i.title==="Free Trial for Beta users")?.plan_id)


      }
      dispatch({type: STOP_LOGIN_LOADING_UI});
    })
    .catch((err) => {
      console.log("IN CATCH,ER")
      if(userData.isGoogle){
      const prf = userData.prf

        dispatch(signupUser({
          first_name: prf.given_name,
          last_name: prf.name || '',
          username: prf.email,
          email:prf.email,
          password: prf.email+''+prf.googleId,
          user_image:prf.picture
        }, remember,nav));
        return
      }


      // alert("Unable to login with the provided email/password");
      console.log("\nlogin err", err);
      dispatch({ type: STOP_LOGIN_LOADING_UI });
      dispatch({ type: FAILED_SIGNIN });
    });
};

export const logoutUser = () => async (dispatch) => {
  // await AsyncStorage.clear();
  delete axios.defaults.headers.common["Authorization"];

  dispatch({ type: SET_UNAUTHENTICATED });
};

// workspace

export const joinWorkspace = (workspaceId, username, firstName, lastName) => (dispatch) => {
  axios
    .post(`${NEW_API_URL}community/join_workspace/${workspaceId}/${username}/${firstName}/${lastName}/`)
    .then((res) => {
      // console.log("join workspace res", JSON.stringify(res.data));
    })
    .catch((err) => console.log("join workspace err", JSON.stringify(err)));

  axios
    .get(
      `${NEW_API_URL}gamification/initialize_metrics/${username}/${workspaceId}/${firstName}/${lastName}/`
    )
    .then((res) => {
      // console.log("initialize_metrics", JSON.stringify(res.data));
    })
    .catch((err) => console.log("initialize_metrics err", JSON.stringify(err)));

  axios
    .get(
      `${NEW_API_URL}lessonplan/initialize_lessonplan/${username}/${workspaceId}/`
    )
    .then((res) => {
      // console.log("initialize_lessonplan", JSON.stringify(res.data));
    })
    .catch((err) =>
      console.log("initialize_lessonplan err", JSON.stringify(err))
    );
};

export const getUserWorkspaces = (username) => (dispatch) => {
  // dispatch({type: LOADING_UI})
  axios
    .get(`${NEW_API_URL}community/get_user_workspaces/${username}/`)
    .then((res) => {
        console.log("get user workspace res and select", JSON.stringify(res.data.workspaces[0]));
      dispatch(addWorkspaces(res.data.workspaces));
      dispatch(setSelectedWorkspace(res.data.workspaces[0]));
      // dispatch({type: STOP_LOADING_UI})
    })
    .catch((err) => {
      console.log("get user workspace err", JSON.stringify(err))
      // dispatch({ type: STOP_LOADING_UI });
    });
};

export const addWorkspaces = (workspaces) => (dispatch) => {
  dispatch({
    type: ADD_WORKSPACES,
    payload: workspaces,
  });
};

export const setSelectedWorkspace = (workspace) => (dispatch) => {
  dispatch({
    type: SET_WORKSPACE,
    payload: workspace,
  });
};

export const leaveWorkspace = (workspaceId, username) => (dispatch) => {
  axios
    .post(`${NEW_API_URL}community/leave_workspace/${workspaceId}/${username}/`)
    .then((res) => {
      // console.log("leave workspace res", JSON.stringify(res.data));
      if (res.data.success) {
        dispatch({
          type: LEAVE_WORKSPACE,
          payload: workspaceId,
        });
      }
    })
    .catch((err) => console.log("leave workspace err", JSON.stringify(err)));
};

export const addFeed = (feed) => (dispatch) => {
  dispatch({
    type: ADD_FEED,
    payload: feed,
  });
};

export const checkUserSubscription = (username) => (dispatch) => {
  axios
    .post(`${NEW_API_URL}community/check_subscription/${username}/`)
    .then((res) => {
      // console.log("check subscription res", JSON.stringify(res))
      if (res.data.message === "no subscription active") {
        dispatch({
          type: SET_SUBSCRIPTION,
          payload: false,
        });
      } else if (res.data.message === "subscription active") {
        dispatch({
          type: SET_SUBSCRIPTION,
          payload: true,
        });
      }
    })
    .catch((err) => console.log("check subscription err", JSON.stringify(err)));
};

// profile
export const setProfile = (profileData) => {
  return {
    type: SET_PROFILE,
    payload: profileData,
  };
};

export const getProfileData = (token) => (dispatch) => {
  const url = `${NEW_API_URL}user/profile/`;
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  axios
    .get(url, axiosConfig)
    .then((res) => {
      // console.log("\nprofile res", JSON.stringify(res.data));
      const data = res?.data;

      const profileData = sortProfileData(data);
      dispatch(setProfile(profileData));
    })
    .catch((error) =>{
      console.log("\nprofile error", JSON.stringify(error));
    });
};

export const updateProfile = (token, username, data) => (dispatch) => {
  // dispatch({ type: LOADING_UI });

  const url = `${NEW_API_URL}user/profile/`;
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const body = {
    username,
    email: username,
    first_name: data.firstName,
    last_name: data.lastName,
    phone_number: data.phoneNumber,
    study_hours_week: data.studyHabits,
    exam_date: moment(data.examDate).format("YYYY-MM-DD"),
  };

  axios
    .post(url, body, axiosConfig)
    .then((res) => {
      // console.log("\nupdate profile res", JSON.stringify(profileData));
      const data = res?.data;

      const profileData = sortProfileData(data);

      dispatch(setProfile(profileData));
      alert("Saved Changes");
      // dispatch({ type: STOP_LOADING_UI });
    })
    .catch((error) => {
      console.log("\nupdate profile error", JSON.stringify(error));

      // dispatch({ type: STOP_LOADING_UI });
      alert("There as an error. Please try again later.");
    });
};


axios.interceptors.request.use(
    config => {
      const token = localStorage.getItem('access');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
);
//
axios.interceptors.response.use(
    response => response,
    async error => {
      console.log("REGJA",error.response)
      const originalRequest = error.config;
      if (error.response && error.response.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          // Refresh the token
          // const newAccessToken = await refreshToken1(localStorage.getItem('refresh'));



          try {
            // console.log('refreshToken started with token:', token);
            axios
                .post(NEW_API_URL + "user/refreshtoken/", {
                  refresh: localStorage.getItem('refresh'),
                })
                .then(async (res) => {
                  console.log("refreshToken response:", res.data);
                  // dispatch(setAuthorizationHeader(res.data.access, res.data.refresh));
                  localStorage.setItem('refresh',res.data.refresh)
                  localStorage.setItem('access',res.data.access)
                  // await AsyncStorage.setItem("refreshToken", res.data.refresh);
                  // const currentTime = Date.now();
                  // await AsyncStorage.setItem("lastRefreshTime", currentTime.toString());
                  // console.log("refreshToken completed successfully");
                })
                .catch((error) => {
                  console.log(
                      "refreshToken error in axios post --->",
                      error?.status,
                      ":",
                      JSON.stringify(error)
                  );
                  // if (JSON.parse(error).message === "Network Error") {
                  //   Alert.alert("No internet connection");
                  // }
                });
          } catch (error) {
            console.log(
                "refreshToken error in try-catch --->",
                error?.status,
                ":",
                JSON.stringify(error)
            );
          }


          // Update the original request with the new token
          // originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

          // Retry the original request with the new token
          return axios(originalRequest);
        } catch (tokenRefreshError) {
          // If refresh fails, redirect to login or handle as appropriate
          console.error("Token refresh failed:", tokenRefreshError);
          return Promise.reject(tokenRefreshError);
        }
      }

      return Promise.reject(error);
    }
);

//
// axios.interceptors.response.use(
//     config => {
//       const token = localStorage.getItem('refresh');
//       if (token) {
//         config.headers.Authorization = `Bearer ${token}`;
//       }
//       return config;
//     },
//     error => {
//       return Promise.reject(error);
//     }
// );



export const refreshToken1 = (token) => (dispatch) => {

};


