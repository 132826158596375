import React from "react";
import { Heading } from "@chakra-ui/react";
import COLORS from "../../colors";

function MainHeading(props) {
  return (
    <Heading
      fontWeight={600}
      fontSize="xx-large"
      fontFamily={"Inter"}
      color={COLORS.dark}
      {...props}
    >
      {props.children}
    </Heading>
  );
}

export default MainHeading;
