import React, {useEffect, useState} from "react";
import {Box, Container, Flex, Heading, Image, Select, Wrap, WrapItem} from "@chakra-ui/react";
import H1 from "../../../../Components/Typography/Heading/H1";
import Psm from "../../../../Components/Typography/Text/Psm";
import InputOutline from "../../../../Components/Input/InputOutline";
import ButtonPrimary from "../../../../Components/Button/ButtonPrimary";
import ButtonSecondary from "../../../../Components/Button/ButtonSecondary";
import H3 from "../../../../Components/Typography/Heading/H3";
import Slider from "react-slick";
import Pmd from "../../../../Components/Typography/Text/Pmd";
import H2 from "../../../../Components/Typography/Heading/H2";
import Modal from "../../../../Components/Modal";
import InputTextAreaOutline from "../../../../Components/Input/InputTextAreaOutline";
import axios from "axios";
import {NEW_API_URL} from "../../../../redux/types";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
const dummyNotes = [
    { topic: "JavaScript Basics", subtopic: "Variables and Data Types" },
    { topic: "HTML", subtopic: "Document Structure" },
    { topic: "CSS", subtopic: "Styling and Layout" },
    { topic: "React", subtopic: "Component Lifecycle" },
    { topic: "Node.js", subtopic: "Server-side JavaScript" },
];

var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

export default function Notes() {
    const navigate = useNavigate();

    const [showAdd, setShowAdd] = useState(false);
    const username = useSelector(state => state.user.username);
    const [lessonPlanList, setLessonPlanList] = useState([]);
    const [topicIdx, setTopicIdx] = useState(-1);
    const [subtopicIdx, setSubtopicIdx] = useState(-1);
    const [fetchLoading, setFetchLoading] = useState(false);
    const [note, setNote] = useState('');

    const selectedWorkspace = useSelector(state => state.user.selectedWorkspace);
    let workspaceId = '';
    if(selectedWorkspace && selectedWorkspace.hasOwnProperty('workspace_id')){
        workspaceId = selectedWorkspace.workspace_id
    }



    const getLessonPlan = async () => {
        setFetchLoading(true);
        try {
            await axios
                .get(
                    `${NEW_API_URL}lessonplan/get_lessonplan/${username}/${workspaceId}/`
                )
                .then((response) => {
                    // console.log("lesson plan res --->", response.data.lesson_plan);
                    if (response.status === 200) {
                        setLessonPlanList(response.data.lesson_plan.topics);
                    }
                    // setTaskList(response?.data?.metrics?.todays_tasks?.tasks);
                })
                .catch((error) => {
                    console.error("lesson plan error --->", error);
                    // Toast.show({
                    //   type: "error",
                    //   text1: "Error",
                    //   text2: "Couldn't load data",
                    // });
                });
        } catch (e) {
            console.error(e);
            // Toast.show({
            //   type: "error",
            //   text1: "Error",
            //   text2: "Something unexpected happened",
            // });
        } finally {
            setFetchLoading(false);
        }
    };

    useEffect(() => {
        getLessonPlan();
    }, []);


    const addNote = async () => {
        try {

            const url = `${NEW_API_URL}notes/create_note/${workspaceId}/`;

            const body = {
                content: JSON.stringify({
                    note: note,
                    subject: lessonPlanList[topicIdx],
                    subtopic: lessonPlanList[topicIdx][subtopicIdx],
                }),
                username: username,
            };

            console.log("url:", url, "\nbody:", body);


            const response = await axios.post(url, body);
            console.log("add note response", response);

        } catch (error) {
            console.log("add note error:", error);
        }
    };


    console.log('topics',lessonPlanList)

    return (
        <Flex
            width={{base: "100%", xl: "80%"}}
            maxWidth={{base: "100%", xl: "80%"}}

            background={"claydeck.white.primary"} justifyContent={'center'}>

            <Box w={'80%'}>
                <H1 margin={4} marginTop={8} marginBottom={3}>
                    Notes
                </H1>
                <Wrap alignItems={'center'}>
                    <WrapItem>
                        <InputOutline placeholder={'Query'} style={{width: '100%'}}/>
                    </WrapItem>
                    <WrapItem>
                        <ButtonSecondary
                            style={{margin: 10}}
                            onClick={() => {
                                alert("Don't press me :(");
                            }}
                        >
                            Search
                        </ButtonSecondary>
                    </WrapItem>
                    <WrapItem>
                        <ButtonSecondary
                            style={{margin: 10}}

                            onClick={() => {
                                alert("Don't press me :(");
                            }}
                        >
                            Lesson Plan
                        </ButtonSecondary>
                    </WrapItem>
                    <WrapItem>
                        <ButtonSecondary
                            style={{margin: 10}}

                            onClick={() => {
                                navigate('/library/map')
                            }}
                        >
                            Map View
                        </ButtonSecondary>
                        <ButtonSecondary
                            style={{margin: 10}}

                            onClick={() => {
                                setShowAdd(true);
                            }}
                        >
                            Add note
                        </ButtonSecondary>
                    </WrapItem>
                </Wrap>

                <H3 sx={{
                    color: "claydeck.text.primary",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "120%",
                    letterSpacing: "0.2px",
                }}>Recent Notes</H3>

                <Slider {...settings} draggable={true} arrows={false}>

                    {dummyNotes && dummyNotes.map((book, index) => {
                        return (
                            <Box>
                                <Flex
                                    key={index}
                                    sx={{

                                        minHeight: "150px",

                                        bgColor: "claydeck.white.primary",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderRadius: "5px",
                                        padding: "9px 15px",
                                        cursor: "pointer",
                                    }}

                                    onClick={() => window.open(book.pdf_link)}
                                >
                                    <Pmd color={"claydeck.brand.secondary"} textAlign="center">
                                        {book.topic}
                                    </Pmd>

                                    <Pmd color={"claydeck.text.tertiary"} textAlign={"center"}>
                                        {book.subtopic}
                                    </Pmd>
                                </Flex>
                            </Box>
                        );
                    })}
                </Slider>

                <H3 sx={{
                    color: "claydeck.text.primary",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "120%",
                    letterSpacing: "0.2px",
                }}>Top Notes</H3>

                <Slider {...settings} draggable={true} arrows={false}>

                    {dummyNotes && dummyNotes.map((book, index) => {
                        return (
                            <Box>
                                <Flex
                                    key={index}
                                    sx={{

                                        minHeight: "150px",

                                        bgColor: "claydeck.white.primary",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderRadius: "5px",
                                        padding: "9px 15px",
                                        cursor: "pointer",
                                    }}

                                    onClick={() => window.open(book.pdf_link)}
                                >
                                    <Pmd color={"claydeck.brand.secondary"} textAlign="center">
                                        {book.topic}
                                    </Pmd>

                                    <Pmd color={"claydeck.text.tertiary"} textAlign={"center"}>
                                        {book.subtopic}
                                    </Pmd>
                                </Flex>
                            </Box>
                        );
                    })}
                </Slider>


                <Modal show={showAdd} handleClose={() => {
                    setShowAdd(false)
                }}>
                    <H2>Add Note</H2>
                    <InputTextAreaOutline onChange={(e) => {
                        // setTopicName(e.target.value)
                        setNote(e.target.value)
                    }} style={{marginTop: 10, marginBottom: 10}} placeholder={'Enter sub topic name'}/>

                    <Select placeholder='Topic' mt={2} onChange={(item)=>{
                        setTopicIdx(lessonPlanList.findIndex(x=>x.name===item.target.value));
                        // console.log()

                    }}>
                        {
                            !fetchLoading && lessonPlanList.map((i=>(
                                <option value={i.name}>{i.name}</option>

                            )))
                        }
                    </Select>

                    <Select placeholder='Sub Topic' mt={2} disabled={topicIdx<0}>
                        {
                            !fetchLoading && topicIdx >=0 && lessonPlanList[topicIdx].subtopics.map((i=>(
                                <option value={i.name}>{i.name}</option>

                            )))
                        }
                    </Select>

                    <ButtonSecondary
                        style={{width:'100%',marginTop:'10px'}}

                        onClick={() => {
                            addNote()
                            // alert("Don't press me :(");
                        }}
                    >
                        Create Note
                    </ButtonSecondary>

                </Modal>


            </Box>
        </Flex>
    );
}
