import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const BackArrow = ({onClick}) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const handleClick = () => {
    onClick ? onClick() : navigate(-1);
  };

  return (
    <img
      src={isHovered ? "/back_button_hover.png" : "/back_button.png"}
      onClick={handleClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        cursor: 'pointer',
        width: '36px',
        height: '36px',
        marginRight: '20px',
      }}
    />
  )
}

export default BackArrow;
