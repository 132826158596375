import React, {useState, useEffect} from "react";
import Joyride, { STATUS } from "react-joyride";

import {
  Avatar,
  Box,
  Button, Center,
  Container,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Select,
  Tag,
  VStack,
  Wrap,
  WrapItem
} from "@chakra-ui/react";
import H2 from "../../Components/Typography/Heading/H2";
import H3 from "../../Components/Typography/Heading/H3";
import Psm from "../../Components/Typography/Text/Psm";
import Pmd from "../../Components/Typography/Text/Pmd";
import ButtonPrimary from "../../Components/Button/ButtonPrimary";

import {AiOutlineArrowRight} from "react-icons/ai";
import {PiFunnelLight} from "react-icons/pi";
import {FaCircle} from "react-icons/fa";

import axios from "axios";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { NEW_API_URL } from "../../redux/types";
import { useNavigate } from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";
import {setNewUser} from "../../redux/actions/userActions";
import {capsFirst} from "../../Components/utils";
// import ChakraCarousel from "../../Components/ChakraCarousel";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Slider from "react-slick";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";
import HeadCard from "../../Components/HeadCard";
import Pxl from "../../Components/Typography/Text/Pxl";
import P2xl from "../../Components/Typography/Text/P2xl";
import MultiLayerDropdown from "../../Components/MultiLayerDropdown";
const Learn = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const newUser = useSelector(state => state.user.newUser);
  const username = useSelector(state => state.user.username);
  const selectedWorkspace = useSelector(state => state.user.selectedWorkspace);
  const user = useSelector(state => state.user);
  let workspaceId = 1;
  const [subjectData,setSubjectData] = useState([])
  const [selectedSubject,setSelectedSubject] = useState('')
  const [selectedArea,setSelectedArea] = useState('')
  const [selectedTopic,setSelectedTopic] = useState('')
  // if(selectedWorkspace && selectedWorkspace.hasOwnProperty('workspace_id')){
  //   workspaceId = selectedWorkspace.workspace_id
  // }
  // console.log('workspace_ID',workspaceId,selectedWorkspace,user)

  const get_map_data = async (username,workspace) => {
    const r = await axios.get(NEW_API_URL+'lessonplan/get_lessonplan/'+username+'/'+workspace+'/')
    console.log(r)
    setSubjectData(r.data)
    // return r.data
  }


  useEffect(() => {
    get_map_data(username,workspaceId)


  }, []);



  return (
      <>
      <Container  justifyContent={'center'} alignItems={'center'} w={'90%'} maxW='container.xl' >

        <P2xl mt={'10vh'}>
          <strong>Syllabus</strong>
        </P2xl>
        <hr/>

        {/* <Pxl mt={20}><strong>Choose topic</strong></Pxl>
        <Flex alignItems={'center'} justifyContent={'space-between'} w={'60%'}>
        <Select backgroundColor={'white'} onChange={(e)=>{setSelectedSubject(e.target.value)}} placeholder='Subject' borderRadius={20} w={'30%'} border={'0.5px solid #000000'}>

          {
            subjectData.map((item,index)=>{

              return (
                  <option key={item.subject_id} value={item.subject_id}>{item.subject_name}</option>
              )

            })
          }


        </Select>
          {console.log(selectedSubject,subjectData.find(i=>i.subject_id+"" === selectedSubject),selectedArea)}
        <Select backgroundColor={'white'} onChange={(e)=>{setSelectedArea(e.target.value)}} placeholder='Area' borderRadius={20}  w={'30%'} border={'0.5px solid #000000'}>
          {
            subjectData.find(i=>i.subject_id+"" === selectedSubject) && subjectData.find(i=>i.subject_id+"" === selectedSubject).areas.map((item,index)=>{

              return (
                  <option key={item.area_id} value={item.area_id} >{item.area_name}</option>
              )

            })
          }
        </Select>
        <Select backgroundColor={'white'} onChange={(e)=>{setSelectedTopic(e.target.value)}} placeholder='Topic' borderRadius={20} w={'30%'} border={'0.5px solid #000000'}>

          {
            subjectData.find(i=>i.subject_id+"" === selectedSubject) && subjectData.find(i=>i.subject_id+"" === selectedSubject).areas.find(i=>i.area_id+""===selectedArea) &&
              subjectData.find(i=>i.subject_id+"" === selectedSubject).areas.find(i=>i.area_id+""===selectedArea).topics.map(item=> (
                  <option key={item.topic_id} value={item.topic_id}>{item.topic_name}</option>


              ))
          }
        </Select>
        </Flex>

        <ButtonPrimary isDisabled={selectedTopic.length<=0} mt={6} w={'20%'} onClick={()=>{
          window.location.href = '/learn/'+selectedSubject+'/'+selectedArea+'/'+selectedTopic

        }}>Next</ButtonPrimary> */}

        <MultiLayerDropdown
        data={subjectData}
        text={'Select a Topic'}
        pageTitle={'Learn'}
        />

        <img src={"/intro-s2.png"} alt={`Slide`} style={{ maxHeight: '80%', maxWidth: '40%', marginLeft: '400px' }} />
      </Container>
      </>

  );
}

export default Learn;
