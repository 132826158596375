import React from 'react';
import {Flex, Image} from "@chakra-ui/react";
import Plg from "./Typography/Text/Plg";

function EmptyImage(props) {
    return (
        <Flex width={'100%'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
            <Image height={'356px'} src={'/empty.png'}></Image>
            <Plg color={'#797979'} mt={'10px'}>{props.message}</Plg>
        </Flex>
    );
}

export default EmptyImage;