export function getTimeAgo(timestamp) {
  const now = new Date();
  const postDate = new Date(timestamp);
  const timeDiff = now - postDate;

  const minutes = Math.floor(timeDiff / (1000 * 60));
  const hours = Math.floor(timeDiff / (1000 * 60 * 60));
  const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  const weeks = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 7));
  const months = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 30));

  if (days < 7) {
    if (days === 0) {
      if (hours === 0) {
        if (minutes < 5) {
          return "Just now";
        }
        switch (minutes) {
          case 5:
            return "5 minutes ago";
            break;
          case 15:
            return "15 minutes ago";
            break;

          case 30:
            return "30 minutes ago";
            break;

          case 45:
            return "45 minutes ago";
            break;
        }
      } else if (hours <= 24) {
        switch (hours) {
          case 1:
            return "1 hour ago";
            break;
          case 2:
            return "2 hours ago";
            break;
          case 3:
            return "3 hours ago";
            break;
          case 4:
            return "4 hours ago";
            break;
          case 5:
            return "5 hours ago";
            break;
          case 6:
            return "6 hours ago";
            break;
          case 7:
            return "7 hours ago";
            break;
          case 8:
            return "8 hours ago";
            break;
          case 9:
            return "9 hours ago";
            break;
          case 10:
            return "10 hours ago";
            break;
          case 11:
            return "11 hours ago";
            break;
          case 12:
            return "12 hours ago";
            break;
          case 13:
            return "13 hours ago";
            break;
          case 14:
            return "14 hours ago";
            break;
          case 15:
            return "15 hours ago";
            break;
          case 16:
            return "16 hours ago";
            break;
          case 17:
            return "17 hours ago";
            break;
          case 18:
            return "18 hours ago";
            break;
          case 19:
            return "19 hours ago";
            break;
          case 20:
            return "20 hours ago";
            break;
          case 21:
            return "21 hours ago";
            break;
          case 22:
            return "22 hours ago";
            break;
          case 23:
            return "23 hours ago";
            break;
          case 24:
            return "24 hours ago";
            break;
        }
      }
    }
    return `${days}D`;
  } else if (days < 30) {
    return `${weeks}W`;
  } else if (days < 60) {
    return `${months}M`;
  } else {
    const numMonths = Math.floor(days / 30);
    return `${numMonths}M`;
  }
}
