import React from "react";
import { Box, Divider, Flex, Image, Text } from "@chakra-ui/react";
import COLORS from "../../Components/colors";

export default function TrendingFeed() {
  return (
    <Box>
      <Text fontSize="14px" color="#828282" marginY={2}>
        The Hindu
      </Text>
      <Flex direction="row">
        <Image
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSg-3382ZgdUhzsOz0VYE8KVNtX_HTwTxRSps08Nli1&s"
          boxSize="50px"
          borderRadius="2px"
        />
        <Text className="mx-2" fontSize="14px" color="black" fontWeight="bold">
          What's causing the spike in Kerala fever cases? Is the wave unusual?
        </Text>
      </Flex>
      <Divider
        className="m-2"
        backgroundColor={COLORS.grey}
        height="0.75px"
        marginBottom="0px"
      />
    </Box>
  );
}
