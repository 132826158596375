import React from 'react';
import { Heading } from '@chakra-ui/react'
function H1(props) {
    return (
      <Heading fontSize="lg" fontWeight={600} fontFamily={"Roboto"} {...props}>
        {props.children}
      </Heading>
    );
}

export default H1;
