import { SUBJECTIVE_QUESTIONS } from "../types";

const initialState = {
  sub_questions: [],
};

const subjectiveTestReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBJECTIVE_QUESTIONS:
      return {
        ...state,
        sub_questions: action.payload,
      };

    default:
      return state;
  }
};

export default subjectiveTestReducer;
