import { useState } from 'react';
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    Switch,
    Stack,
} from '@chakra-ui/react';
import {NEW_API_URL} from "../redux/types";
import axios from "axios";
import {useSelector} from "react-redux";

const CreateRoomModal = ({ isOpen, onClose }) => {
    const [roomName, setRoomName] = useState('');
    const [description, setDescription] = useState('');
    const [micEnabled, setMicEnabled] = useState(false);
    const [videoEnabled, setVideoEnabled] = useState(false);
    const [privateRoom, setPrivateRoom] = useState(false);
    console.log()
    const username = useSelector((state)=>state.user.username)
    const currentWorkspace = useSelector(
        (state) => state.user.selectedWorkspace.workspace_id
    );
    function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    const handleCreate = () => {
        // Add your create logic here
        // You can access the form values like roomName, description, micEnabled, etc.
        // For example:
        console.log('Room Name:', roomName);
        console.log('Description:', description);
        console.log('Mic Enabled:', micEnabled);
        console.log('Video Enabled:', videoEnabled);
        console.log('Private Room:', privateRoom);

        axios
            .post(
                NEW_API_URL +
                `community/create_room/${currentWorkspace}/${getRandomInt(100, 1000)}/`,
                {
                    user_name: username,
                    name: roomName,
                    channel_name: roomName.replace(/ /g, "") + getRandomInt(100, 1000),
                    description: description,
                    settings: {
                        mic: micEnabled,
                        video: videoEnabled
                    },
                    is_private: privateRoom
                }
            )
            .then((response) => {
                // console.log("\ncreate room res", JSON.stringify(response.data));


                onClose()
            })
            .catch((err) => {
                onClose()
                console.log("\ncreate room err", JSON.stringify(err));
            });



        // onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Create Room</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl mb={4}>
                        <FormLabel>Room Name</FormLabel>
                        <Input
                            value={roomName}
                            onChange={(e) => setRoomName(e.target.value)}
                            placeholder="Enter room name"
                        />
                    </FormControl>
                    <FormControl mb={4}>
                        <FormLabel>Description</FormLabel>
                        <Textarea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Enter description"
                        />
                    </FormControl>
                    <Stack spacing={4}>
                        <FormControl display="flex" alignItems="center">
                            <FormLabel mb="0">Mic</FormLabel>
                            <Switch
                                isChecked={micEnabled}
                                onChange={() => setMicEnabled(!micEnabled)}
                            />
                        </FormControl>
                        <FormControl display="flex" alignItems="center">
                            <FormLabel mb="0">Video</FormLabel>
                            <Switch
                                isChecked={videoEnabled}
                                onChange={() => setVideoEnabled(!videoEnabled)}
                            />
                        </FormControl>
                        <FormControl display="flex" alignItems="center">
                            <FormLabel mb="0">Private Room</FormLabel>
                            <Switch
                                isChecked={privateRoom}
                                onChange={() => setPrivateRoom(!privateRoom)}
                            />
                        </FormControl>
                    </Stack>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={handleCreate}>
                        Create
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default CreateRoomModal;
