import React from "react";
import Nav from "./Nav";
import { Box } from "@chakra-ui/react";
import Pmd from "../../Components/Typography/Text/Pmd";
import MainHeading from "../../Components/Typography/Heading/MainHeading";
import Markdown from "react-markdown";
import { privacy_policy, terms_of_service } from "./data/tandc";

export default function PrivacyPolicy() {
  return (
    <>
      {/* Navbar */}
      <Nav />
      {/* Main Content */}
      <Box className="p-5 mx-5">
        <Pmd className="mt-3 mb-5">
          <Markdown>
           {privacy_policy}
          </Markdown>
        </Pmd>
      </Box>
    </>
  );
}
