import React, { useState, useEffect } from "react";
import {
    GoogleMap,
    LoadScript,
    Marker,
    InfoWindow,
} from "@react-google-maps/api";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

function StreetMapView(props) {
    const position = { lat: 28.6139, lng: 77.2090 };

    // Map container style
    const containerStyle = {
        width: "100%",
        height: "600px",
    };

    const [map, setMap] = useState(null); // State to track map instance
    const [selectedMarker, setSelectedMarker] = useState(null); // State to manage InfoWindow
    const [markerClusterer, setMarkerClusterer] = useState(null); // MarkerClusterer instance

    // Helper function to adjust overlapping markers
    const adjustMarkers = (x) => {
        const offset = 0.00005; // Small offset in latitude/longitude
        const adjustedMarkers = [];
        const seen = new Map();
        console.log(x,'la1')

        x.forEach(ax => {
            ax.locations.forEach((location) => {
                const key = `${location.latitude},${location.longitude}`;
                let count = seen.get(key) || 0;

                // Apply offset for overlapping markers
                const adjustedLat = location.latitude + count * offset;
                const adjustedLng = location.longitude + count * offset;
                seen.set(key, count + 1);

                adjustedMarkers.push({ ...location, adjustedLat, adjustedLng,title:ax.title,description:ax.description });
            });
        })



        return [...adjustedMarkers];
    };

    // Initialize clustering when the map is loaded
    useEffect(() => {
        if (map && !markerClusterer) {
            const clusterer = new MarkerClusterer({
                map: map, // Attach clusterer to the map
            });
            setMarkerClusterer(clusterer);
        }
    }, [map]);

    // Add markers to the clusterer
    useEffect(() => {
        if (markerClusterer) {
            const markers = adjustMarkers(
                props.data
            ).map((location) => {
                const marker = new window.google.maps.Marker({
                    position: { lat: location.adjustedLat, lng: location.adjustedLng },
                    // label: location.title || "",
                    // title: location.title || "",
                });

                console.log(location,'location')

                marker.addListener("click", () => {
                    setSelectedMarker({
                        lat: location.adjustedLat,
                        lng: location.adjustedLng,
                        title: location.title,
                        description: location.description,
                    });
                });

                return marker;
            });

            markerClusterer.addMarkers(markers);
        }
    }, [markerClusterer, props.data]);

    return (
        <LoadScript googleMapsApiKey="AIzaSyA110yxTc3ROgksJHiMqxIfqjYA2FEtYGE">
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={position}
                zoom={4}
                onLoad={(mapInstance) => setMap(mapInstance)}
            >
                {selectedMarker && (
                    <InfoWindow
                        position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
                        onCloseClick={() => setSelectedMarker(null)}
                    >
                        <div>
                            <h6 style={{ margin: 0 }}>{selectedMarker.title}</h6>
                            <p>{selectedMarker.description}</p>
                        </div>
                    </InfoWindow>
                )}
            </GoogleMap>
        </LoadScript>
    );
}

export default StreetMapView;
