export const terms_of_service = `

##### Last Updated: 5 Nov 2024
\n

Welcome to *Claydeck*, a product of Arc Tech Labs (“we”, “us”, “our”). By using our app, Claydeck, or visiting our website, [https://www.claydeck.com/](https://www.claydeck.com/) (collectively, the "Service"), you (“user”, “you”, “your”) agree to be bound by these Terms and Conditions (“Terms”). If you do not agree to these Terms, you must refrain from using the Service.

### 1. **Acceptance of Terms**
   By accessing or using the Service, you confirm that you have read, understood, and accepted these Terms, and agree to abide by them. You must also review our Privacy Policy, which is incorporated into these Terms by reference.

### 2. **Service Overview**
   *Claydeck* is an educational platform designed to help students prepare for the UPSC exams. The Service includes access to:
   - Study materials, including names of reference books and AI-generated content like notes, case studies, timelines, and current affairs articles.
   - Progress tracking features and personalized content suggestions.
   - Tools for creating notes, uploading images, and chatting with an AI chatbot.

   The Service is accessible via a free 7-day trial, after which users may continue to access it through a paid subscription.

### 3. **Account Registration and User Obligations**

   - **Eligibility**: You must be at least 18 years old or have obtained parental consent to use this Service.
   - **Account Creation**: You may create an account manually or sign in using Google authentication. By creating an account, you agree to provide accurate information, including your name, email, and profile image.
   - **Account Security**: You are responsible for maintaining the confidentiality of your login credentials and for all activities conducted under your account. Notify us immediately of any unauthorized access to your account.

### 4. **Subscription and Payment**
   - **Free Trial**: New users may access the Service with a 7-day free trial.
   - **Subscription Plans**: After the trial period, access to the Service is subject to subscription fees, which vary by duration and plan type. Details on pricing and payment terms are provided within the app or website.
   - **Automatic Renewal**: Unless canceled, subscriptions will automatically renew at the end of each billing period. You may cancel your subscription through your account settings.

### 5. **User Content**
   - **Content Creation**: Users can create notes, upload images, and access AI-generated materials within the app. By uploading or creating content, you grant us a non-exclusive, worldwide license to use, display, and distribute this content as needed for providing the Service.
   - **Prohibited Content**: You agree not to post or share any content that is illegal, offensive, or violates the rights of third parties. We reserve the right to remove content that violates these Terms.

### 6. **AI-Generated Content**
   - The Service provides AI-generated content to enhance learning experiences. While we strive to provide accurate and helpful information, AI-generated content may contain inaccuracies. Users should independently verify the information provided before relying on it for study purposes.

### 7. **Privacy and Data Collection**
   - We collect and use your personal data as outlined in our Privacy Policy. The data collected includes your name, email, profile image, and any additional content you upload or create in the app. We use this data to personalize content, track progress, and enhance the user experience.
   - By using the Service, you consent to the collection, use, and sharing of your data as described in our Privacy Policy.

### 8. **Intellectual Property**
   - **Our Content**: All content, features, and functionality in *Claydeck* and on [https://www.claydeck.com/](https://www.claydeck.com/), including AI-generated material, design elements, and code, are the intellectual property of Arc Tech Labs and protected under applicable copyright and trademark laws.
   - **Limited License**: We grant you a non-exclusive, non-transferable license to use the Service for personal, non-commercial purposes. You may not distribute, modify, or reverse engineer any part of the Service.

### 9. **Prohibited Activities**
   You agree not to:
   - Use the Service for any illegal or unauthorized purpose.
   - Copy, distribute, or exploit any portion of the Service, except as permitted by these Terms.
   - Attempt to gain unauthorized access to our systems or disrupt the Service.

### 10. **Termination and Suspension**
   - We reserve the right to suspend or terminate your access to the Service, with or without notice, for violations of these Terms or if required by law. Upon termination, your right to use the Service will cease, and any data you have submitted may be deleted.

### 11. **Disclaimers and Limitations of Liability**
   - **Educational Use Only**: The content provided in *Claydeck* is for educational purposes. We do not guarantee success in any examination and are not responsible for any reliance placed on the content provided.
   - **No Warranty**: The Service is provided "as is" without warranty of any kind, either express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, or non-infringement.
   - **Limitation of Liability**: To the maximum extent permitted by law, Arc Tech Labs will not be liable for any indirect, incidental, special, or consequential damages arising from your use of the Service.

### 12. **Indemnification**
   You agree to indemnify and hold harmless Arc Tech Labs, its officers, directors, employees, and affiliates from any claims, losses, damages, liabilities, and expenses arising out of your use of the Service or violation of these Terms.

### 13. **Changes to Terms**
   We reserve the right to update or modify these Terms at any time. We will notify you of significant changes by posting an updated version on our website or app. Your continued use of the Service following such changes constitutes your acceptance of the updated Terms.

### 14. **Governing Law and Jurisdiction**
   These Terms shall be governed by and construed in accordance with the laws of India. Any disputes arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts in Rohtak, Haryana, India.

### 15. **Contact Us**
   If you have any questions or concerns about these Terms, please contact us at:
   - **Email**: rahulkhatry@arctechlabs.com
   - **Address**: Arc Tech Labs, House No 117/22, Dev Colony, Rohtak, 124001, Haryana, India

   \n\n\n\n
   --
    `;

export const privacy_policy = `
# Privacy Policy

Last updated: November 05, 2024

This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.

We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the [Privacy Policy Generator](https://www.termsfeed.com/privacy-policy-generator/).

## Interpretation and Definitions

### Interpretation

The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.

### Definitions

For the purposes of this Privacy Policy:

- __Account__ means a unique account created for You to access our Service or parts of our Service.
- __Affiliate__ means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.

- __Application__ refers to Claydeck, the software program provided by the Company.

- __Company__ (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Arc Tech Labs, House No 117/22, Dev Colony, Rohtak, 124001.

- __Cookies__ are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.

- __Country__ refers to: Haryana,  India

- __Device__ means any device that can access the Service such as a computer, a cellphone or a digital tablet.

- __Personal Data__ is any information that relates to an identified or identifiable individual.

- __Service__ refers to the Application or the Website or both.

- __Service Provider__ means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.


- __Usage Data__ refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).


- __Website__ refers to Claydeck, accessible from [https://www.claydeck.com/](https://www.claydeck.com/)

- __You__ means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.



## Collecting and Using Your Personal Data

### Types of Data Collected

#### Personal Data

While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:

- Email address
- First name and last name
- Usage Data


#### Usage Data

Usage Data is collected automatically when using the Service.

Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.

When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.

We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.





#### Information Collected while Using the Application

While using Our Application, in order to provide features of Our Application, We may collect, with Your prior permission:



- Pictures and other information from your Device's camera and photo library

We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it may be simply stored on Your device.

You can enable or disable access to this information at any time, through Your Device settings.




#### Tracking Technologies and Cookies

We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:

- __Cookies or Browser Cookies.__ A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.
- __Web Beacons.__ Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).

Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser. You can learn more about cookies on [TermsFeed website](https://www.termsfeed.com/blog/cookies/#What_Are_Cookies) article.

We use both Session and Persistent Cookies for the purposes set out below:

- __Necessary / Essential Cookies__

	Type: Session Cookies

	Administered by: Us

	Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.
- __Cookies Policy / Notice Acceptance Cookies__

	Type: Persistent Cookies

	Administered by: Us

	Purpose: These Cookies identify if users have accepted the use of cookies on the Website.
- __Functionality Cookies__

	Type: Persistent Cookies

	Administered by: Us

	Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.



For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy or the Cookies section of our Privacy Policy.


### Use of Your Personal Data

The Company may use Personal Data for the following purposes:

- __To provide and maintain our Service__, including to monitor the usage of our Service.
- __To manage Your Account:__ to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.
- __For the performance of a contract:__ the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.
- __To contact You:__ To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.
- __To provide You__ with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.
- __To manage Your requests:__ To attend and manage Your requests to Us.

- __For business transfers:__ We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.
- __For other purposes__: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.

We may share Your personal information in the following situations:

- __With Service Providers:__ We may share Your personal information with Service Providers to monitor and analyze the use of our Service,  to contact You.
- __For business transfers:__ We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.
- __With Affiliates:__ We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.
- __With business partners:__ We may share Your information with Our business partners to offer You certain products, services or promotions.
- __With other users:__ when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.
- __With Your consent__: We may disclose Your personal information for any other purpose with Your consent.

### Retention of Your Personal Data

The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.

The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.

### Transfer of Your Personal Data

Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.

Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.

The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.

### Delete Your Personal Data

You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You.

Our Service may give You the ability to delete certain information about You from within the Service.

You may update, amend, or delete Your information at any time by signing in to Your Account, if you have one, and visiting the account settings section that allows you to manage Your personal information. You may also contact Us to request access to, correct, or delete any personal information that You have provided to Us.

Please note, however, that We may need to retain certain information when we have a legal obligation or lawful basis to do so.

### Disclosure of Your Personal Data

#### Business Transactions

If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.

#### Law enforcement

Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).

#### Other legal requirements

The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:

- Comply with a legal obligation
- Protect and defend the rights or property of the Company
- Prevent or investigate possible wrongdoing in connection with the Service
- Protect the personal safety of Users of the Service or the public
- Protect against legal liability

### Security of Your Personal Data

The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.

## Children's Privacy

Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.

If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.


## Links to Other Websites

Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.

We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.

## Changes to this Privacy Policy

We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.

We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.

You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.

## Contact Us

If you have any questions about this Privacy Policy, You can contact us:


- By email: claydeck.arctech@gmail.com
    `;
