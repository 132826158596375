import { Box, Img, Text, useMediaQuery } from "@chakra-ui/react";
import RoomLists from "./components/roomList";
import ParticipantSidebar from "./components/participantsSidebar";
import EventsList from "./components/eventsList";
import MobileSidebarItems from "./components/mobileSidebarItems";
import postCreateRoom from "./utils/postCreateRoom";
import postCreateEvent from "./utils/postCreateEvent";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import AddRoomModal from "./components/models/AddRoomModal";
import { useState } from "react";
import AddEventsModal from "./components/models/AddEvents";

const MentorWorkspacePage = ({ roomdata, eventdata }) => {
  const [isSmallDevice] = useMediaQuery("(max-width: 768px)");
  const [isMediumDevice] = useMediaQuery("(max-width: 992px)");
  const [isRoomModalOpen, setIsRoomModalOpen] = useState(false);
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);

  const toggleEventModal = () => {
    setIsEventModalOpen(!isEventModalOpen);
  };

  const toggleRoomModal = () => {
    setIsRoomModalOpen  (!isRoomModalOpen);
  };

  const handleAddRoom = () => {};
  const handleAddEvent = () => {};

  // const {isLoading} = postCreateRoom(1,data) post createRoom data to database ...
  // const {isLoading} = postCreateEvent(1,1,data) post createevent data to database ...

  return (
    <>
      <>
        <AddRoomModal
          isOpen={isRoomModalOpen}
          onClose={toggleRoomModal}
          handleAddRoom={handleAddRoom}
        />
        <AddEventsModal
          isOpen={isEventModalOpen}
          onClose={toggleEventModal}
          handleAddRoom={handleAddEvent}
        />
      </>
      <Box margin={0} paddingY={"26px"} paddingX={"8px"} width={"full"}>
        <Box maxWidth={"auto"} paddingX={"6px"}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            paddingX={"8px"}
            marginBottom={"8px"}
          >
            <Text fontSize={"18px"} fontWeight={"semibold"}>
              Community Rooms
            </Text>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={"5"}
            >
              <Box as="button" onClick={toggleRoomModal}>
                <Img src="assets/icons/plusSign.svg" />
              </Box>
              {!isSmallDevice && <Img src="assets/icons/filter-alt.svg" />}
            </Box>
          </Box>
          <RoomLists data={roomdata} />
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            paddingX={"8px"}
            marginY={"8px"}
          >
            <Text fontSize={"18px"} fontWeight={"semibold"}>
              Events List
            </Text>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={"5"}
              onClick={toggleEventModal}
            >
              <Img src="assets/icons/plusSign.svg" />
            </Box>
          </Box>
          <EventsList data={eventdata} />
          {isSmallDevice && isMediumDevice && <MobileSidebarItems />}
        </Box>
      </Box>
      {!isSmallDevice && !isMediumDevice && <ParticipantSidebar />}
    </>
  );
};

export default MentorWorkspacePage;
