import React, { useState, useEffect } from "react";
import Joyride, { STATUS } from "react-joyride";

import {
  Avatar,
  Box,
  Button, Center,
  Container,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Select,
  Tag,
  VStack,
  Wrap,
  WrapItem
} from "@chakra-ui/react";
import H2 from "../../Components/Typography/Heading/H2";
import H3 from "../../Components/Typography/Heading/H3";
import Psm from "../../Components/Typography/Text/Psm";
import Pmd from "../../Components/Typography/Text/Pmd";
import ButtonPrimary from "../../Components/Button/ButtonPrimary";

import { AiOutlineArrowRight } from "react-icons/ai";
import { PiFunnelLight } from "react-icons/pi";
import { FaCircle } from "react-icons/fa";

import axios from "axios";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { NEW_API_URL } from "../../redux/types";
import { useNavigate } from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";
import { setNewUser } from "../../redux/actions/userActions";
import { capsFirst } from "../../Components/utils";
// import ChakraCarousel from "../../Components/ChakraCarousel";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Slider from "react-slick";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";
import HeadCard from "../../Components/HeadCard";
import Pxl from "../../Components/Typography/Text/Pxl";
import P2xl from "../../Components/Typography/Text/P2xl";
import MultiLayerDropdown from "../../Components/MultiLayerDropdown";

const dummyPlanData = [
  {
    duration: "14",
    endDate: null,
    plan_id: "dd1978c0-989e-42a3-8bf8-8129512c880a",
    price: 0,
    renewal: false,
    status: "Inactive",
    title: "Free Trial for Beta users"
  },
  {
    duration: "30",
    endDate: null,
    plan_id: "dd1978c0-989e-42a3-8bf8-8129512c880a1",
    price: 299,
    renewal: false,
    status: "Inactive",
    title: "1-month Access"
  },
  {
    duration: "30",
    endDate: null,
    plan_id: "dd1978c0-989e-42a3-8bf8-8129512c880a2",
    price: 299,
    renewal: true,
    status: "Inactive",
    title: "Monthly Subscription"
  },
]

const Subscription = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const newUser = useSelector(state => state.user.newUser);
  const username = useSelector(state => state.user.username);
  const selectedWorkspace = useSelector(state => state.user.selectedWorkspace);
  const user = useSelector(state => state.user);
  const [planData, setPlanData] = useState([dummyPlanData])
  const [planType, setPlanType] = useState('One-time');
  let workspaceId = 1;

  const get_plan_data = async (username) => {
    const r = await axios.get(NEW_API_URL + 'community/plans/' + username)
    console.log(r)
    setPlanData(r.data)
    // return r.data
  }


  useEffect(() => {
    // get_plan_data(username);
    setPlanData(dummyPlanData);
  }, []);



  return (
    <>
      <Container justifyContent={'center'} alignItems={'center'} w={'90%'} maxW='container.xl' >

        <P2xl mt={'10vh'}>
          <strong>Available Plans:</strong>
        </P2xl>
        <hr />

        <HeadCard shadow border>
          <VStack>
            <H2>Plans</H2>
            <Psm>Choose a plan that suits your needs</Psm>
            <Psm>With all of Claydeck’s plans, get unlimited access to:</Psm>
            <VStack align="start" spacing={2}>
              <HStack>
                <Icon as={FaCircle} color="green.500" />
                <Psm>1000+ interactive activities to master all UPSC topics</Psm>
              </HStack>
              <HStack>
                <Icon as={FaCircle} color="green.500" />
                <Psm>100+ timelines and visualizations to understand connections between topics</Psm>
              </HStack>
              <HStack>
                <Icon as={FaCircle} color="green.500" />
                <Psm>Priority of news topics to cover for UPSC updated daily</Psm>
              </HStack>
              <HStack>
                <Icon as={FaCircle} color="green.500" />
                <Psm>Get instant doubt clarification at any time</Psm>
              </HStack>
            </VStack>
          </VStack>
          <HStack spacing={4} mt={4} pl={'24px'}>
            <ButtonSecondary onClick={() => setPlanType('One-time')} isActive={planType === 'One-time'}>
              One-time
            </ButtonSecondary>
            <ButtonSecondary onClick={() => setPlanType('Monthly')} isActive={planType === 'Monthly'}>
              Monthly
            </ButtonSecondary>
          </HStack>
          <Wrap>
            {planData.filter(
              (plan) => planType === 'One-time' ? plan.renewal === false : plan.renewal === true
            ).map((plan) => (
              <WrapItem key={plan.plan_id} pl={'24px'} pt={'24px'}>
                <Box p={'16px'} border={'1px solid #0962A2'} borderRadius={'16px'} width={'300px'} height={'200px'}>
                  <Pmd color={'#0962A2'} fontWeight={'500'}>{plan.title}</Pmd>
                  {plan.price > 0 ?
                    <>
                      <Pmd fontWeight={'800'} fontSize={'20px'}>Rs. {plan.price}</Pmd>
                    </>
                    :
                    <Pmd fontWeight={'800'} fontSize={'20px'}>Free</Pmd>
                  }
                  <Pmd fontWeight={'800'}>Valid for {plan.duration} days</Pmd>
                  <HStack>
                    <ButtonPrimary>Activate Plan</ButtonPrimary>
                  </HStack>
                </Box>
              </WrapItem>
            ))}
          </Wrap>
        </HeadCard>
      </Container>
    </>

  );
}

export default Subscription;
