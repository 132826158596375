import React, { useState, useEffect } from "react";
import Joyride, { STATUS } from "react-joyride";
import { Link } from "react-router-dom";

import {
  Avatar,
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import H2 from "../../Components/Typography/Heading/H2";
import H3 from "../../Components/Typography/Heading/H3";
import Psm from "../../Components/Typography/Text/Psm";
import Pmd from "../../Components/Typography/Text/Pmd";
import ButtonPrimary from "../../Components/Button/ButtonPrimary";

import { AiOutlineArrowRight } from "react-icons/ai";
import { PiFunnelLight } from "react-icons/pi";
import { FaCircle } from "react-icons/fa";

import axios from "axios";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { NEW_API_URL } from "../../redux/types";
import { useNavigate } from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";
import { setNewUser } from "../../redux/actions/userActions";
import { capsFirst } from "../../Components/utils";
// import ChakraCarousel from "../../Components/ChakraCarousel";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Slider from "react-slick";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";
import HeadCard from "../../Components/HeadCard";
import Pxl from "../../Components/Typography/Text/Pxl";
import P2xl from "../../Components/Typography/Text/P2xl";
import { ArrowBackIcon } from "@chakra-ui/icons";
import Plg from "../../Components/Typography/Text/Plg";
import { quiz_map } from "../../data/new_generated/quiz_map";
import EmptyImage from "../../Components/EmptyImage";
const Tests = () => {
  const [tab, setTab] = useState("personalized");
  const [personalizedTests, setPersonalizedTests] = useState(
    quiz_map.personalised_tests
  );
  const [standardTests, setStandardTests] = useState(quiz_map.standard_tests);
  const navigate = useNavigate();

  return (
    <>
      <Container
        justifyContent={"center"}
        alignItems={"center"}
        w={"95%"}
        mt={'150px'}
        maxW="container.xl"
      >
        <EmptyImage message={'Coming Soon'}/>
        {/*<Flex*/}
        {/*  flexDirection={"column"}*/}
        {/*  alignItems={"space-between"}*/}
        {/*  justifyContent={"space-between"}*/}
        {/*  minH={"95vh"}*/}
        {/*>*/}
        {/*  <Box>*/}
        {/*    <Flex mt={"10vh"} alignItems={"center"}>*/}


        {/*      <Box>*/}
        {/*        <P2xl mb={0} ml={0} mr={0}>*/}
        {/*          <strong>Test</strong>*/}
        {/*        </P2xl>*/}
        {/*      </Box>*/}
        {/*    </Flex>*/}

        {/*    <Tabs align={"center"}>*/}
        {/*      <TabList>*/}
        {/*        <Tab>*/}
        {/*          <Pxl*/}
        {/*            style={{ fontWeight: "300" }}*/}
        {/*            ml={"30px"}*/}
        {/*            mr={"30px"}*/}
        {/*            cursor={"pointer"}*/}
        {/*            onClick={() => {*/}
        {/*              setTab("personalized");*/}
        {/*            }}*/}
        {/*            color={tab == "personalized" ? "#0962A2" : "black"}*/}
        {/*          >*/}
        {/*            Personalised*/}
        {/*          </Pxl>*/}
        {/*        </Tab>*/}
        {/*        <Tab>*/}
        {/*          <Pxl*/}
        {/*            style={{ fontWeight: "300" }}*/}
        {/*            ml={"30px"}*/}
        {/*            mr={"30px"}*/}
        {/*            cursor={"pointer"}*/}
        {/*            onClick={() => {*/}
        {/*              setTab("standard");*/}
        {/*            }}*/}
        {/*            color={tab == "standard" ? "#0962A2" : "black"}*/}
        {/*          >*/}
        {/*            Standard*/}
        {/*          </Pxl>*/}
        {/*        </Tab>*/}
        {/*      </TabList>*/}

        {/*      <TabPanels>*/}
        {/*        <TabPanel>*/}
        {/*          <Link to={"/tests/1"}>*/}
        {/*            <Container maxW={"70%"}>*/}
        {/*              <HeadCard*/}
        {/*                title={"News test"}*/}
        {/*                mt={"30px"}*/}
        {/*                bgColor={"none"}*/}
        {/*              >*/}

        {/*                {*/}
        {/*                    personalizedTests.length <= 0  && <EmptyImage message={'No tests available yet'}/>*/}
        {/*                }*/}

        {/*                <Flex ml={"12px"} pb={"15px"} flexWrap={"wrap"}>*/}
        {/*                  {personalizedTests.map((item, index) => (*/}
        {/*                    <Flex*/}
        {/*                      mr={"20px"}*/}
        {/*                      key={index}*/}
        {/*                      flexDirection={"column"}*/}
        {/*                      mt={"30px"}*/}
        {/*                      w={"200px"}*/}
        {/*                      p={"12px"}*/}
        {/*                      boxShadow={"0px 3px 3px 0px #00000040"}*/}
        {/*                      borderRadius={"12px"}*/}
        {/*                      borderLeft={"4px solid #2A6291"}*/}
        {/*                      minH={"150px"}*/}
        {/*                    >*/}
        {/*                      <Center>*/}
        {/*                        <Image*/}
        {/*                          height={"64px"}*/}
        {/*                          width={"53px"}*/}
        {/*                          src={"/news_test.png"}*/}
        {/*                        ></Image>*/}
        {/*                      </Center>*/}

        {/*                      /!*<Flex alignItems={'center'}>*!/*/}
        {/*                      /!*  <Plg margin={0} pl={'20px'}>3 / 10</Plg>*!/*/}
        {/*                      /!*</Flex>*!/*/}
        {/*                      <Box mt={"10px"}>*/}
        {/*                        <Pmd mb={0} textAlign={"center"}>*/}
        {/*                          <strong>{item.title}</strong>*/}
        {/*                        </Pmd>*/}
        {/*                      </Box>*/}
        {/*                    </Flex>*/}
        {/*                  ))}*/}
        {/*                </Flex>*/}
        {/*              </HeadCard>*/}
        {/*            </Container>*/}
        {/*          </Link>*/}
        {/*        </TabPanel>*/}
        {/*        <TabPanel>*/}
        {/*          <Container maxW={"70%"}>*/}
        {/*            <HeadCard title={"News test"} mt={"30px"} bgColor={"none"}>*/}
        {/*              {*/}
        {/*                  standardTests.length <= 0  && <EmptyImage message={'No tests available yet'}/>*/}
        {/*              }*/}
        {/*              <Flex ml={"12px"} pb={"15px"} flexWrap={"wrap"}>*/}
        {/*                {standardTests.map((item, index) => (*/}
        {/*                  <Link to={"/tests/1"}>*/}
        {/*                    <Flex*/}
        {/*                      mr={"20px"}*/}
        {/*                      key={index}*/}
        {/*                      flexDirection={"column"}*/}
        {/*                      mt={"30px"}*/}
        {/*                      w={"200px"}*/}
        {/*                      p={"12px"}*/}
        {/*                      boxShadow={"0px 3px 3px 0px #00000040"}*/}
        {/*                      borderRadius={"12px"}*/}
        {/*                      borderLeft={"4px solid #2A6291"}*/}
        {/*                      minH={"150px"}*/}
        {/*                    >*/}
        {/*                      <Center>*/}
        {/*                        <Image*/}
        {/*                          height={"64px"}*/}
        {/*                          width={"53px"}*/}
        {/*                          src={"/news_test.png"}*/}
        {/*                        ></Image>*/}
        {/*                      </Center>*/}

        {/*                      /!*<Flex alignItems={'center'}>*!/*/}
        {/*                      /!*  <Plg margin={0} pl={'20px'}>3 / 10</Plg>*!/*/}
        {/*                      /!*</Flex>*!/*/}
        {/*                      <Box mt={"10px"}>*/}
        {/*                        <Pmd mb={0} textAlign={"center"}>*/}
        {/*                          <strong>{item.title}</strong>*/}
        {/*                        </Pmd>*/}
        {/*                      </Box>*/}
        {/*                    </Flex>*/}
        {/*                  </Link>*/}
        {/*                ))}*/}
        {/*              </Flex>*/}
        {/*            </HeadCard>*/}
        {/*          </Container>*/}
        {/*        </TabPanel>*/}
        {/*      </TabPanels>*/}
        {/*    </Tabs>*/}
        {/*  </Box>*/}
        {/*</Flex>*/}
      </Container>
    </>
  );
};

export default Tests;
