import { Box, Button } from "@chakra-ui/react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_FORUM_ACTIVETAGS } from "../../redux/types";

const ButtonTag = ({ buttonText }) => {
  const dispatch = useDispatch();
  const activeTags = useSelector((state) => state.forumData.activeTags);
  const [isActive, setIsActive] = useState(activeTags.includes(buttonText));

  const handleClick = () => {
    const updatedActiveTags = isActive
      ? activeTags.filter((tag) => tag !== buttonText)
      : [...activeTags, buttonText];

    dispatch({ type: SET_FORUM_ACTIVETAGS, payload: updatedActiveTags });
    setIsActive(!isActive);
  };

  // useEffect(() => {
  //   console.log("isActive:", isActive);
  // }, [isActive]);

  return (
    <Box backgroundColor={"#fff"}>
      <Button
        onClick={() => handleClick()}
        isActive={isActive}
        size={"sm"}
        _active={{
          backgroundColor: "rgba(33, 0, 93, 0.1)",
          textColor: "#6750a4",
        }}
        fontWeight={"semibold"}
        fontSize={"small"}
        border={"1px solid #949494"}
        backgroundColor={"white"}
        textColor={"#949494"}
        paddingX={"10px"}
        paddingY={"8px"}
        rounded={"full"}
        maxH={"28px"}
      >
        {buttonText}
      </Button>
    </Box>
  );
};

export default ButtonTag;
