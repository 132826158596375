import React, {useEffect, useState} from "react";
import PlanBanner from "./StudyResourceComps/components/PlanBanner";
import COLORS from "../../Components/colors";
import {Box, Flex, Image, Text} from "@chakra-ui/react";
import H2 from "../../Components/Typography/Heading/H2";
import StudyResources from "./StudyResource";

import ExamPracticeMAterial from "./ExamPracticeMaterial";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";
import H3 from "../../Components/Typography/Heading/H3";
import Slider from "react-slick";
import Pmd from "../../Components/Typography/Text/Pmd";
import moment from "moment";
import axios from "axios";
import {NEW_API_URL} from "../../redux/types";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Psm from "../../Components/Typography/Text/Psm";
const styles = {
    heading: {
        color: "claydeck.text.primary",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "120%",
        letterSpacing: "0.2px",
    },
    bookContainer: {

        minHeight: "350px",

        bgColor: "claydeck.white.primary",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "5px",
        padding: "9px 15px",
        cursor: "pointer",
    },
    feedContainer: {
        minHeight: { base: "400px" },
        width: "60px",
        bgColor: "claydeck.white.primary",
        borderRadius: { base: "5px", md: "10px" },
        padding: { base: "12px", md: "16px", lg: "24px" },
        cursor: "pointer",
    },
    userStats: {
        // height: "116px",
        // minWidth: "357px",
        width:'100%',
        minWidth:'100%',
        backgroundColor: 'claydeck.white.primary',
        justifyContent: "space-around",
        alignItems: "center",
        borderRadius: "5px",
        // paddingTop: "8px",
        // paddingBottom: "7px",
        // margin: "22px",
        marginBottom: "100px",
    },
    userStat: {
        textAlign: 'center',
        width:'100%',
        flexDirection: 'column',
        alignItems: 'center'
    },
    userStatIcon: {
        height: "48px",
        width: '48px',
        bgColor: "claydeck.white.primary",
        objectFit: 'contain',
        borderRadius: "4px",
        boxShadow: "0px 4px 20px 5px rgba(0, 0, 0, 0.03)",
        padding: "8px"
    },
    userStatTitle: {
        textAlign: 'center',
        color: 'claydeck.text.tertiary',
        fontSize: '12px',
        fontWeight:'400',
        lineHeight: '120%',
        marginTop: '12px',
        marginBottom: '8px'
    },
    userStatValue: {
        fontSize: '30px',
        fontWeight: 400
    },
    option: {
        bgColor: '#fff',
        color: '#000'
    },
    labelInfo: {
        color: '#000',
        fontSize: '14px',
        marginLeft: '8px'
    }
};

export default function Library() {
  const [typeOfLibraryContentToBeShown, setTypeOfLibraryContentToBeShown] =
    useState("Study Resources");

    const [loading, setLoading] = useState(false);
    const [mustReads, setMustReads] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const newUser = useSelector(state => state.user.newUser);
    const username = useSelector(state => state.user.username);
    const selectedWorkspace = useSelector(state => state.user.selectedWorkspace);
    const user = useSelector(state => state.user);
    let workspaceId = '';
    if(selectedWorkspace && selectedWorkspace.hasOwnProperty('workspace_id')){
        workspaceId = selectedWorkspace.workspace_id
    }

    const [bookRecommendations, setBookRecommendations] = useState([
        {'title':'lorem','author':'aaa','image':'https://cdn.sanity.io/images/bilbb9h8/production/8148c49082454c83aa1fb24744d506c5c8231672-128x128.svg'},
        {'title':'lorem','author':'aaa','image':'https://cdn.sanity.io/images/bilbb9h8/production/8148c49082454c83aa1fb24744d506c5c8231672-128x128.svg'},
        {'title':'lorem','author':'aaa','image':'https://cdn.sanity.io/images/bilbb9h8/production/8148c49082454c83aa1fb24744d506c5c8231672-128x128.svg'},
        {'title':'lorem','author':'aaa','image':'https://cdn.sanity.io/images/bilbb9h8/production/8148c49082454c83aa1fb24744d506c5c8231672-128x128.svg'},
        {'title':'lorem','author':'aaa','image':'https://cdn.sanity.io/images/bilbb9h8/production/8148c49082454c83aa1fb24744d506c5c8231672-128x128.svg'},
        {'title':'lorem','author':'aaa','image':'https://cdn.sanity.io/images/bilbb9h8/production/8148c49082454c83aa1fb24744d506c5c8231672-128x128.svg'},
        {'title':'lorem','author':'aaa','image':'https://cdn.sanity.io/images/bilbb9h8/production/8148c49082454c83aa1fb24744d506c5c8231672-128x128.svg'},
        {'title':'lorem','author':'aaa','image':'https://cdn.sanity.io/images/bilbb9h8/production/8148c49082454c83aa1fb24744d506c5c8231672-128x128.svg'},
        {'title':'lorem','author':'aaa','image':'https://cdn.sanity.io/images/bilbb9h8/production/8148c49082454c83aa1fb24744d506c5c8231672-128x128.svg'},
        {'title':'lorem','author':'aaa','image':'https://cdn.sanity.io/images/bilbb9h8/production/8148c49082454c83aa1fb24744d506c5c8231672-128x128.svg'},
        {'title':'lorem','author':'aaa','image':'https://cdn.sanity.io/images/bilbb9h8/production/8148c49082454c83aa1fb24744d506c5c8231672-128x128.svg'},
        {'title':'lorem','author':'aaa','image':'https://cdn.sanity.io/images/bilbb9h8/production/8148c49082454c83aa1fb24744d506c5c8231672-128x128.svg'},
        {'title':'lorem','author':'aaa','image':'https://cdn.sanity.io/images/bilbb9h8/production/8148c49082454c83aa1fb24744d506c5c8231672-128x128.svg'},
        {'title':'lorem','author':'aaa','image':'https://cdn.sanity.io/images/bilbb9h8/production/8148c49082454c83aa1fb24744d506c5c8231672-128x128.svg'},
        {'title':'lorem','author':'aaa','image':'https://cdn.sanity.io/images/bilbb9h8/production/8148c49082454c83aa1fb24744d506c5c8231672-128x128.svg'}
    ]);
    const [feeds, setFeeds] = useState([
        {source:'a', title:'z'},
        {source:'a', title:'z'},
        {source:'a', title:'z'},
        {source:'a', title:'z'},
        {source:'a', title:'z'},
        {source:'a', title:'z'},
        {source:'a', title:'z'},
        {source:'a', title:'z'},
        {source:'a', title:'z'},
        {source:'a', title:'z'},
        {source:'a', title:'z'},
        {source:'a', title:'z'}
    ]);

    console.log('workspace_ID',workspaceId,selectedWorkspace,user)
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    useEffect(() => {
        if (username !== "" && workspaceId !== "") {
            fetchUserStats();
            fetchMustReads();
        }
    }, [username, workspaceId]);

    const fetchUserStats = async () => {
        // dispatch(checkUserSubscription(username));
        try {
            setLoading(true);
            await axios
                .get(
                    `${NEW_API_URL}gamification/get_metrics/${username}/${workspaceId}`
                )
                .then((response) => {
                    // console.log(JSON.stringify(response.data))
                    if (response.status === 200) {
                        // setNewsReadStatus(response.data.metrics.news_reading_status);
                        // setAvgTestScore(response.data.metrics.average_test_scores);
                        // setReadingHoursDone(response.data.metrics.hours_reading_done);
                        // setReadingHoursLeft(response.data.metrics.hours_reading_left);
                        // setTodaysTasks(response.data.metrics.todays_tasks.tasks);

                        // setBadges(response.data.metrics?.badges?.badges);

                        setBookRecommendations(
                            response.data.metrics?.book_recommendations?.tasks || []
                        );
                        setFeeds(response.data.metrics?.headlines || []);
                        // setNotifications(response.data.metrics.notifications.notifications);

                        // notificationScheduler(
                        //   response.data.metrics.notifications.notifications
                        // );
                    }
                })
                .catch((error) => {
                    console.error(error);
                    alert("Couldn't load data");
                });
        } catch (e) {
            console.error(e);
            alert("Something unexpected happened");
        } finally {
            setLoading(false);
        }
    };
    const fetchMustReads = async () => {
        // dispatch(checkUserSubscription(username));
        try {
            setLoading(true);
            await axios
                .get(
                    `${NEW_API_URL}library/get_must_reads/${workspaceId}/${username}/`
                )
                .then((response) => {
                    // console.log(JSON.stringify(response.data))
                    if (response.status === 200) {
                        // setNewsReadStatus(response.data.metrics.news_reading_status);
                        // setAvgTestScore(response.data.metrics.average_test_scores);
                        // setReadingHoursDone(response.data.metrics.hours_reading_done);
                        // setReadingHoursLeft(response.data.metrics.hours_reading_left);
                        // setTodaysTasks(response.data.metrics.todays_tasks.tasks);

                        // setBadges(response.data.metrics?.badges?.badges);

                        setMustReads(

                            response.data.must_reads || []
                        );
                        // setFeeds(response.data.metrics?.headlines || []);
                        // setNotifications(response.data.metrics.notifications.notifications);

                        // notificationScheduler(
                        //   response.data.metrics.notifications.notifications
                        // );
                    }
                })
                .catch((error) => {
                    console.error(error);
                    alert("Couldn't load data");
                });
        } catch (e) {
            console.error(e);
            alert("Something unexpected happened");
        } finally {
            setLoading(false);
        }
    };




  return (
    <Box width={{ base: "100%", xl: "80%" }}
         maxWidth={{ base: "100%", xl: "80%" }}>
      {/*<PlanBanner />*/}
      {/*  <ButtonSecondary mx={4} onClick={()=>{*/}
      {/*      window.location.href = "/lesson-plan"*/}
      {/*  }}>Lesson Plan</ButtonSecondary>*/}




        <Box width={{ base: "100%" }} minWidth={{ base: "100%" }} padding={"24px 32px"}>
            <Link style={{
                color: "claydeck.text.primary",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "120%",
                letterSpacing: "0.2px",
            }} to={'books'}>Books</Link>

            <Slider {...settings} draggable={true} arrows={false}>

                {bookRecommendations && bookRecommendations.map((book, index) => {
                    return (
                        <Box>
                            <Flex
                                key={index}
                                sx={styles.bookContainer}

                                onClick={() => window.open(book.pdf_link)}
                            >
                                <Pmd color={"claydeck.brand.secondary"} textAlign="center">
                                    {book.title}
                                </Pmd>
                                <Image
                                    overflow={"hidden"}
                                    objectFit={"contain"}
                                    src={
                                        book?.image ? book.image : "./assets/images/NCERT.png"
                                    }
                                    height={"135px"}
                                    width={"103px"}
                                    marginTop={"8px"}
                                    marginBottom={"6px"}
                                />
                                <Pmd color={"claydeck.text.tertiary"} textAlign={"center"}>
                                    {book.author}
                                </Pmd>
                            </Flex>
                        </Box>
                    );
                })}
            </Slider>


            {
                mustReads.map(item=>(
                    <>
                        <Link style={{
                            color: "claydeck.text.primary",
                            fontSize: "16px",
                            fontWeight: 600,
                            lineHeight: "120%",
                            letterSpacing: "0.2px",
                        }} to={'books'}>{item.name}</Link>

                        <Slider {...settings} draggable={true} arrows={false}>

                            {item.items && item.items.map((book, index) => {
                                return (
                                    <Box>
                                        <Flex
                                            key={index}
                                            sx={styles.bookContainer}

                                            onClick={() => window.open(book.url)}
                                        >
                                            <Pmd color={"claydeck.brand.secondary"} textAlign="center">
                                                {book.name}
                                            </Pmd>
                                            <Image
                                                overflow={"hidden"}
                                                objectFit={"contain"}
                                                src={
                                                    book?.image ? book.image : "./assets/images/NCERT.png"
                                                }
                                                height={"135px"}
                                                width={"103px"}
                                                marginTop={"8px"}
                                                marginBottom={"6px"}
                                            />
                                            <Pmd color={"claydeck.text.tertiary"} textAlign={"center"}>
                                                {book.author}
                                            </Pmd>
                                        </Flex>
                                    </Box>
                                );
                            })}
                        </Slider>

                    </>
                ))
            }


            <H3 style={{marginTop:'30px'}} sx={styles.heading}>Notes</H3>
            <Slider {...settings} draggable={true} arrows={false}>

                {feeds && feeds.map((feed, index) => {
                    return (

                        <Box
                            key={index}
                            sx={styles.feedContainer}

                            onClick={() => window.open(feed.source)}
                        >


                            {/*<Flex sx={styles.feedContent}>*/}
                                <Image
                                    src={
                                        feed?.image
                                            ? feed.image
                                            : "../assets/images/HinduLogo.png"
                                    }
                                    objectFit={"contain"}
                                    borderRadius={"4px"}
                                />
                                <H3 style={{minHeight:'70px',marginTop:'10px',marginBottom:'10px'}}>{feed.title}</H3>

                            {/*</Flex>*/}
                            <Pmd
                                textAlign={"left"}
                                fontSize={{ base: "11px", md: "14px" }}
                            >
                                {moment(feed.pub_date).format("DD/MM/YYYY")}
                            </Pmd>
                        </Box>
                    );
                })}
            </Slider>


            <H3 style={{marginTop:'30px'}} sx={styles.heading}>Saved Feeds Articles</H3>
            <Slider {...settings} draggable={true} arrows={false}>

                {feeds && feeds.map((feed, index) => {
                    return (

                        <Box
                            key={index}
                            sx={styles.feedContainer}
                            onClick={() => window.open(feed.source)}
                        >
                            <Box>
                                <Flex alignItems={'center'} mb={'10px'}>

                                <Image
                                    src={
                                        "../assets/images/HinduLogo.png"
                                    }
                                    height={"30px"}
                                    width={"30px"}
                                    objectFit={"contain"}
                                    border={'1px solid black'}
                                    borderRadius={"50%"}
                                />
                                    <Box style={{marginLeft:'10px'}}>
                                    <Psm style={{margin:0}}>The Hindu</Psm>
                                    <Psm
                                        style={{margin:0}}

                                        // textAlign={"right"}
                                        // fontSize={{ base: "11px", md: "14px" }}
                                    >
                                        {moment(feed.pub_date).format("DD/MM/YYYY")}
                                    </Psm>
                                    </Box>
                                </Flex>
                                <Image
                                    src={
                                        feed?.image
                                            ? feed.image
                                            : "./assets/images/HinduLogo.png"
                                    }
                                    // height={"75px"}
                                    width={"100%"}
                                    objectFit={"contain"}
                                    borderRadius={"4px"}
                                />
                                <H3>{feed.title}</H3>


                            </Box>

                        </Box>
                    );
                })}
            </Slider>


        </Box>




      {/*<Flex direction="row">*/}
      {/*  {["Study Resources", "Exam practice material"].map((item) => (*/}
      {/*    <Box*/}
      {/*      key={item}*/}
      {/*      onClick={() => setTypeOfLibraryContentToBeShown(item)}*/}
      {/*      className="mx-4 cursor"*/}
      {/*      borderBottomWidth={3}*/}
      {/*      borderBottomColor={*/}
      {/*        item === typeOfLibraryContentToBeShown*/}
      {/*          ? COLORS.purple*/}
      {/*          : COLORS.white*/}
      {/*      }*/}
      {/*    >*/}
      {/*      <H2 color={COLORS.black}>{item}</H2>*/}
      {/*    </Box>*/}
      {/*  ))}*/}
      {/*</Flex>*/}
      {/*{typeOfLibraryContentToBeShown === "Study Resources" ? (*/}
      {/*  <StudyResources />*/}
      {/*) : (*/}
      {/*  <ExamPracticeMAterial />*/}
      {/*)}*/}
    </Box>
  );
}
