import { useRef } from "react";
import {Input} from "@chakra-ui/react";

const InputDate = (props) => {

  const ref = useRef();

  const styles = {
    input: {
      size: "sm",
      height: { base: "42px", lg: "56px" },
      bg: "claydeck.white.primary",
      color: "claydeck.text.primary",
      variant: "outline",
      fontSize: {base: "16px", lg: '18px'},
      fontWeight: "500",
      lineHeight: "140%",
      letterSpacing: "0.6px",
      borderRadius: "8px",
      border: "1px solid #AEAAAE",
    },
  };

  return (
    <Input
      ref={ref}
      type="text"
      sx={styles.input}
      _hover={{ bg: "claydeck.brand.hover" }}
      _placeholder={{
        color: "claydeck.text.tertiary",
        fontSize: {base: "14px", lg: '16px'},
        letterSpacing: "0.6px",
      }}
      onFocus={() => (ref.current.type = "date")}
      onBlur={() => (ref.current.type = "text")}
      {...props}
    />
  );
};

export default InputDate;