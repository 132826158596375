import React, {useState, useEffect} from "react";
import Joyride, { STATUS } from "react-joyride";

import {
    Avatar,
    Box,
    Button, Center,
    Container,
    Flex,
    Heading,
    HStack,
    Icon,
    Image,
    Select,
    Tag, useDisclosure,
    VStack,
    Wrap,
    WrapItem,Text
} from "@chakra-ui/react";
import H2 from "../../Components/Typography/Heading/H2";
import H3 from "../../Components/Typography/Heading/H3";
import Psm from "../../Components/Typography/Text/Psm";
import Pmd from "../../Components/Typography/Text/Pmd";
import ButtonPrimary from "../../Components/Button/ButtonPrimary";

import {AiOutlineArrowRight, AiOutlineSearch} from "react-icons/ai";
import {PiFunnelLight} from "react-icons/pi";
import {FaCircle} from "react-icons/fa";

import axios from "axios";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { NEW_API_URL } from "../../redux/types";
import {useNavigate, useParams} from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";
import {setNewUser} from "../../redux/actions/userActions";
import {capsFirst} from "../../Components/utils";
// import ChakraCarousel from "../../Components/ChakraCarousel";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Slider from "react-slick";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";
import HeadCard from "../../Components/HeadCard";
import Pxl from "../../Components/Typography/Text/Pxl";
import P2xl from "../../Components/Typography/Text/P2xl";
import {ArrowBackIcon} from "@chakra-ui/icons";
import TopicCard from "../../Components/TopicCard";
import NotesCard from "../../Components/NotesCard";
import CustomStyledModal from "../../Components/Modal/CustomStyledModal";
import EmptyImage from "../../Components/EmptyImage";
import BackArrow from "../../Components/backArrow";
const MyNotes = (props) => {
    const {isTimeline} = props
    console.log(isTimeline,'is_timeline_thing')

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {topic,subject,area} = useParams();
    console.log("topic:",topic)
    const notesModalStates = useDisclosure()

    const newUser = useSelector(state => state.user.newUser);
    const username = useSelector(state => state.user.username);
    const selectedWorkspace = useSelector(state => state.user.selectedWorkspace);
    const user = useSelector(state => state.user);
    let workspaceId = '';
    if(selectedWorkspace && selectedWorkspace.hasOwnProperty('workspace_id')){
        workspaceId = selectedWorkspace.workspace_id
    }
    console.log('workspace_ID timeline',workspaceId,selectedWorkspace,user)


    const [noteType,setNoteType] = useState('All Notes')
    const [topicDetail,setTopicDetail] = useState({})
    const [timelinesDetail,setTimelinesDetail] = useState([])
    const [notesDetail,setNotesDetail] = useState([])
    const [notesDetailOrigial,setNotesDetailOriginal] = useState([])

    const get_map_data = async (username,workspace) => {
        const r = await axios.get(NEW_API_URL+'notes/get_note_list/'+username).catch(err=>{})
        // setTopicDetail(r.data.topic_detail)
        // setDailyNews(r.data.topic_detail.news)

        if(isTimeline){

            setTimelinesDetail(r.data.topic_detail.timelines)
        }
        else {
            // notesDetailOrigial.concat(...topicDetail.related_notes)
            if (Array.isArray(r.data)) {
                setNotesDetail(r.data)
                setNotesDetailOriginal(r.data)
            }
        }
        // setTopicDetail(r.data.topic_detail)

        return r.data
    }


    useEffect(() => {
        get_map_data(username,topic)


    }, []);



    return (
        <>
            <Container  justifyContent={'center'} alignItems={'center'} w={'90%'} maxW='container.xl' >
                <Flex flexDirection={'column'} alignItems={'space-between'} justifyContent={'space-between'} minH={'95vh'}>
                    <Box>
                        <Flex mt={'10vh'} alignItems={'center'}>
                            {/* <ArrowBackIcon cursor={'pointer'} onClick={()=>{navigate(-1)}} marginRight={'20px'} boxSize={'45px'}/> */}
                            <BackArrow/>
                            <Box>
                                <P2xl mb={0} ml={0} mr={0}>
                                    <strong>My Notes</strong>


                                </P2xl>
                                <Pmd color={'#817D7D'} style={{margin: 0}}><strong>My Notes</strong></Pmd>
                            </Box>
                        </Flex>
                        <hr/>

                        <Flex  mt={20} justifyContent={'space-between'}>
                            <P2xl>
                                <strong>{isTimeline?'Timeline':'Notes'}</strong>
                            </P2xl>
                            <Box>

                                {!isTimeline && <ButtonPrimary link={'/learn/mynote/mynote/mynote/notes_reading/0?mynote=1'}
                                                               border={'1px solid #0962A2'}><Image marginRight={'10px'} src={'/radio.png'} width={'18px'}
                                                                                                   height={'18px'}></Image> Start reading all
                                    notes</ButtonPrimary>}
                            </Box>

                        </Flex>
                        <Wrap spacing={'20px'}>

                            {
                                (!isTimeline && notesDetail?notesDetail:[]).length<=0 && !isTimeline && <EmptyImage message={'No notes available yet'}/>
                            }

                            {
                                (!isTimeline && notesDetail?notesDetail:[]).map((item)=>(
                                    <WrapItem>
                                        <NotesCard read_status={item.read_status} key={item.id} to={'/learn/'+subject+'/'+area+'/'+topic+'/notes/'+item.id+"?mynote=1"} shadow w={'180px'} title={item.title} icon={item.icon_image_link}/>
                                    </WrapItem>
                                ))
                            }
                            {/*<WrapItem>*/}
                            {/*<NotesCard to={isTimeline?'/learn/history/geo/eco/timeline/123':'/learn/history/geo/eco/notes/123'} shadow w={'180px'} title={'Notes 1'} icon={'/notes_preview.png'}/>*/}
                            {/*</WrapItem>*/}
                            {/*<WrapItem>*/}
                            {/*  <NotesCard shadow w={'180px'} title={'Notes 1'} icon={'/notes_preview.png'}/>*/}
                            {/*</WrapItem>*/}
                            {/*<WrapItem>*/}
                            {/*  <NotesCard shadow w={'180px'} title={'Case study'} icon={'/notes_preview.png'}/>*/}
                            {/*</WrapItem>*/}
                            {/*<WrapItem>*/}
                            {/*  <NotesCard shadow w={'180px'} title={'Mind map'} icon={'/mind_map_preview.png'}/>*/}
                            {/*</WrapItem>*/}
                            {/*<WrapItem>*/}
                            {/*  <NotesCard shadow w={'180px'} title={'Notes 1'} icon={'/notes_preview.png'}/>*/}
                            {/*</WrapItem>*/}

                        </Wrap>
                    </Box>
                </Flex>
                <CustomStyledModal
                    add_type_topic={!isTimeline}
                    area={''} subject={''} topic_name={topicDetail.topic_name} topic_id={topic}
                    toast={'Note successfully created!'} topicDetail={topicDetail} add_note isOpen={notesModalStates.isOpen} onOpen={notesModalStates.onOpen} onClose={notesModalStates.onClose} title={'Notes'} selectedText={''}/>
                <Flex zIndex={5} onClick={()=>{notesModalStates.onOpen()}} cursor={'pointer'} w={'80px'} h={'80px'} alignItems={'center'} justifyContent={'center'} bg={"claydeck.brand.primary"} border={'2px solid white'} boxShadow={'0px 4px 4px 0px #00000040'} position={'fixed'} bottom={'80px'} right={'20px'} borderRadius={'50px'}>
                    <Text color={'white'} margin={0} fontSize={'32px'}>+</Text>
                </Flex>
            </Container>
        </>

    );
}

export default MyNotes;
