import React from "react";
import Nav from "./Nav";
import { GridItem, SimpleGrid, Grid, useColorModeValue, Box } from "@chakra-ui/react";
import Pmd from "../../Components/Typography/Text/Pmd";
import Psm from "../../Components/Typography/Text/Psm";
import H1 from "../../Components/Typography/Heading/H1";
import H3 from "../../Components/Typography/Heading/H3";
import MainHeading from "../../Components/Typography/Heading/MainHeading";
import COLORS from "../../Components/colors";
import "./LandingPage.css";
import section3Icon1 from "../../assets/landing/section3Icon1.png";
import section3Icon2 from "../../assets/landing/section3Icon2.png";
import section3Icon3 from "../../assets/landing/section3Icon3.png";
import section5Img1 from "../../assets/landing/section5Img1.png";
import section5Img2 from "../../assets/landing/section5Img2.png";
import section5Img3 from "../../assets/landing/section5Img3.png";
import section6Img1 from "../../assets/razorpay.png";
import section6Img2 from "../../assets/landing/section6Img2.png";
import section6Img3 from "../../assets/landing/section6Img3.png";
import section6Img4 from "../../assets/landing/section6Img4.png";
import section7Avatar from "../../assets/landing/section7Avatar.webp";
import section7Avatar2 from "../../assets/landing/section7Avatar3.webp";
import section7Logo from "../../assets/landing/section7Logo.png";
import section1Asset from "../../assets/landing/section1Asset1.webp";
import Pxl from "../../Components/Typography/Text/Pxl";
import P2xl from "../../Components/Typography/Text/P2xl";

export default function LandingPage() {
  return (
    <div>
      {/* NavBar */}
      <Nav />

      {/* Section 1 */}
      <div style={{ position: 'relative', minHeight:'100vh', background: '#EEEEEE' }}>
        {/* Background image */}
        <img
          src={section1Asset}
          style={{
            width: '100%',
            // height: '100%',
            position: 'absolute',
            top: '25%',
            left: 0,
            objectFit: 'cover'
          }}
          alt="Section 1 Asset"
        />

        {/* Content container */}
        <div style={{
          position: 'relative',
          display: 'flex',
          minHeight: '100vh',
          padding: '5vw',
          alignItems: 'center',
        }}>
          <div style={{ flex: '0 0 50%', paddingRight: '5vw' }}>
            <MainHeading fontWeight={800} className="section-1-main-heading mb-4" fontSize={40}>
              Study smart with Claydeck, Your AI-powered UPSC prep companion
            </MainHeading>
            <Pmd fontSize={14}>
              Claydeck is an intelligent, AI-powered UPSC preparation tool designed for serious aspirants. With curated content, case studies, and real-time current affairs articles generated by our AI, you can optimize your study time and focus on what matters most. Track your progress, personalize your experience, and get instant help from our chatbot. Try Claydeck free for 7 days and see the difference it can make in your prep.
            </Pmd>
            <Box
              as="a"
              px={8}
              py={4}
              borderRadius={30}
              style={{
                backgroundColor: COLORS.blue,
                color: COLORS.offWhite,
                display: 'inline-block'
              }}
              _hover={{
                textDecoration: "none",
              }}
              marginTop={16}
              className="cursor"
              href={'https://forms.gle/HKATqSQWHxtrT4dw9'}
            >
              Get Started
            </Box>
          </div>
        </div>
      </div>

      {/* Section 2 */}
      <SimpleGrid columns={1} spacing="40px" className="mb-5">
        <GridItem
          w="100%"
          h={{ lg: "20vh", md: "40vh" }}
          style={{ padding: "10%", paddingLeft: "20%", paddingRight: "20%" }}
          className="d-flex justify-content-center flex-column text-center"
        >
          <H3 className="mt-5" color={COLORS.blue} fontSize={32}>
            Unlock Your UPSC Success with Claydeck:
          </H3>
          <MainHeading>
            Where Interactive Learning Meets Intelligent Insights!
          </MainHeading>
        </GridItem>
      </SimpleGrid>

      {/* Section 3 */}
      <SimpleGrid
        columns={{ sm: 1, md: 3, lg: 3 }}
        spacing="40px"
        className="my-5"
        style={{ paddingLeft: "10vw", paddingRight: "10vw"}}
      >
        <GridItem
          w="100%"
          style={{ backgroundColor: COLORS.white, border: '1px solid #A9a9a9' }}
          minH={{ lg: "25vh", md: "35vh" }}
          className="d-flex justify-content-center rounded flex-column p-5 shadow-secondary"
        >
          <div className="d-flex flex-row align-items-center my-4">
            <div
              className="p-2 mx-2 rounded"
              style={{ backgroundColor: COLORS.lightPurple }}
            >
              <img src={section3Icon1} alt="section3Icon1" />
            </div>
            <P2xl style={{ color: COLORS.black, marginBottom: 0.5 }}>Interactive content</P2xl>
          </div>
          <Pmd style={{ color: COLORS.black }}>
            Simplify your UPSC prep with interactive tools, AI support, and visual content to connect and conquer every topic effortlessly.
          </Pmd>
        </GridItem>
        <GridItem
          w="100%"
          style={{ backgroundColor: COLORS.white, border: '1px solid #A9a9a9' }}
          minH={{ lg: "25vh", md: "35vh" }}
          className="d-flex justify-content-center rounded flex-column p-5 shadow-secondary"
        >
          <div className="d-flex flex-row align-items-center my-4">
            <div
              className="p-2 mx-2 rounded"
              style={{ backgroundColor: COLORS.lightBlue }}
            >
              <img src={section3Icon2} alt="section3Icon2" />
            </div>
            <P2xl style={{ color: COLORS.black, marginBottom: 0.5 }}>Instant Doubt Clarification</P2xl>
          </div>
          <Pmd style={{ color: COLORS.black }}>
            Meet Master G, your personal AI coach for UPSC—keeping you in the groove and guiding you through every tough spot. Don't lose momentum; just ask Master G!
          </Pmd>
        </GridItem>
        <GridItem
          w="100%"
          style={{ backgroundColor: COLORS.white,border: '1px solid #A9a9a9' }}
          minH={{ lg: "25vh", md: "35vh" }}
          className="d-flex justify-content-center rounded flex-column p-5 shadow-secondary"
        >
          <div className="d-flex flex-row align-items-center my-4">
            <div
              className="p-2 mx-2 rounded"
              style={{ backgroundColor: COLORS.lightPink }}
            >
              <img src={section3Icon3} alt="section3Icon3" />
            </div>
            <P2xl style={{ color: COLORS.black, marginBottom: 0.5 }}>News Priority</P2xl>
          </div>
          <Pmd style={{ color: COLORS.black }}>
            Cut through the noise with Claydeck's advanced news priority feature—access the most relevant content and save it directly to your notes, organized by subject or topic.
          </Pmd>
        </GridItem>
      </SimpleGrid>

      {/* Section 4 */}
      {/* <SimpleGrid columns={1} spacing="40px">
        <GridItem
          w="100%"
          h={{ lg: "20vh", md: "40vh" }}
          style={{ padding: "10%", paddingLeft: "10%", paddingRight: "10%" }}
          className="d-flex justify-content-center flex-column mt-5"
        >
          <H3 className="mt-5" color={COLORS.darkPurple}>
            Study Groups
          </H3>
          <MainHeading>Explore Trending Groups of Community</MainHeading>
          <Pmd color={COLORS.grey}>
            Let's join our famous class, the knowledge provided will definitely
            be useful for you.
          </Pmd>
        </GridItem>
      </SimpleGrid> */}

      {/* Section 5 */}
      {/* <SimpleGrid
        columns={{ sm: 1, md: 3, lg: 3 }}
        spacing="40px"
        style={{ paddingLeft: "10vw", paddingRight: "10vw" }}
      >
        <GridItem
          w="100%"
          style={{ backgroundColor: COLORS.white }}
          h={{ lg: "70vh", md: "80vh" }}
          className="d-flex justify-content-center rounded flex-column p-5 shadow"
        >
          <img src={section5Img1} alt="section5Img1" />
          <H3 className="mt-3" color={COLORS.purple4}>
            General
          </H3>
          <H1>Science Group</H1>
          <Pmd color={COLORS.grey}>
            We’ll discuss chemistry, physics, biology and botany topics in
            detail. Let’s connect!
          </Pmd> */}
      {/*<div className="d-flex flex-row align-items-center">*/}
      {/*  <div*/}
      {/*    className="circle-small"*/}
      {/*    style={{ backgroundColor: COLORS.green }}*/}
      {/*  />*/}
      {/*  <Psm*/}
      {/*    style={{ marginBottom: 0 }}*/}
      {/*    color={COLORS.purple4}*/}
      {/*    className="mx-2"*/}
      {/*  >*/}
      {/*    250 Active Students*/}
      {/*  </Psm>*/}
      {/*  <Psm style={{ marginBottom: 0 }} color={COLORS.grey}>*/}
      {/*    (325)*/}
      {/*  </Psm>*/}
      {/*</div>*/}
      {/*<button className="p-2 rounded mt-3 section-5-btn">*/}
      {/*  Science Group*/}
      {/*</button>*/}
      {/* </GridItem>
        <GridItem
          w="100%"
          style={{ backgroundColor: COLORS.white }}
          h={{ lg: "70vh", md: "80vh" }}
          className="d-flex justify-content-center rounded flex-column p-5 shadow"
        >
          <img src={section5Img2} alt="section5Img2" />
          <H3 className="mt-3" color={COLORS.purple4}>
            General
          </H3>
          <H1>Science Group</H1>
          <Pmd color={COLORS.grey}>
            We’ll discuss chemistry, physics, biology and botany topics in
            detail. Let’s connect!
          </Pmd> */}
      {/*<div className="d-flex flex-row align-items-center">*/}
      {/*  <div*/}
      {/*    className="circle-small"*/}
      {/*    style={{ backgroundColor: COLORS.green }}*/}
      {/*  />*/}
      {/*  <Psm*/}
      {/*    style={{ marginBottom: 0 }}*/}
      {/*    color={COLORS.purple4}*/}
      {/*    className="mx-2"*/}
      {/*  >*/}
      {/*    250 Active Students*/}
      {/*  </Psm>*/}
      {/*  <Psm style={{ marginBottom: 0 }} color={COLORS.grey}>*/}
      {/*    (325)*/}
      {/*  </Psm>*/}
      {/*</div>*/}
      {/*<button className="p-2 rounded mt-3 section-5-btn">*/}
      {/*  Science Group*/}
      {/*</button>*/}
      {/* </GridItem>
        <GridItem
          w="100%"
          style={{ backgroundColor: COLORS.white }}
          h={{ lg: "70vh", md: "80vh" }}
          className="d-flex justify-content-center rounded flex-column p-5 shadow"
        >
          <img src={section5Img3} alt="section5Img3" />
          <H3 className="mt-3" color={COLORS.purple4}>
            General
          </H3>
          <H1>Chess Learning Group</H1>
          <Pmd color={COLORS.grey}>
            Apart from learning, let’s have a chess play. It will help you grow
            intelligent. C’mon join!
          </Pmd> */}
      {/*<div className="d-flex flex-row align-items-center">*/}
      {/*  <div*/}
      {/*    className="circle-small"*/}
      {/*    style={{ backgroundColor: COLORS.green }}*/}
      {/*  />*/}
      {/*  <Psm*/}
      {/*    style={{ marginBottom: 0 }}*/}
      {/*    color={COLORS.purple4}*/}
      {/*    className="mx-2"*/}
      {/*  >*/}
      {/*    250 Active Students*/}
      {/*  </Psm>*/}
      {/*  <Psm style={{ marginBottom: 0 }} color={COLORS.grey}>*/}
      {/*    (325)*/}
      {/*  </Psm>*/}
      {/*</div>*/}
      {/*<button className="p-2 rounded mt-3 section-5-btn">*/}
      {/*  Science Group*/}
      {/*</button>*/}
      {/* </GridItem>
      </SimpleGrid> */}

      {/* Section 6 */}
      <div style={{minHeight:'100vh', background: '#EEEEEE', display:'flex', alignItems:'center'}}>
      <SimpleGrid
        columns={{ sm: 1, md: 2, lg: 2 }}
        style={{ paddingLeft: "10vw", paddingRight: "10vw" }}
      >
        <GridItem
          w="100%"
          style={{ backgroundColor: COLORS.white, }}
          className="d-flex justify-content-center rounded flex-row p-5"
        >
          <div style={{minHeight:'30vh',  display:'flex', alignItems:'center'}}>
          <img
            src={section6Img1}
            alt="section6Img1"
            className="me-3"
            style={{ width: 100, height: 100 }}
          />
          <div className="d-flex flex-column">
            <H1>Progress Tracking and Personalization</H1>
            <Pmd color={COLORS.grey}>
              Track your progress with precision and let Claydeck customize your learning path. Watch as it adapts to your needs, helping you focus on the areas that matter most.
            </Pmd>
          </div>
          </div>
        </GridItem>
        <GridItem
          w="100%"
          style={{ backgroundColor: COLORS.white }}
          className="d-flex justify-content-center rounded flex-row p-5"
        >
          <div style={{minHeight:'30vh',  display:'flex', alignItems:'center'}}>
          <img
            src={section6Img2}
            alt="section6Img2"
            className="me-3"
            style={{ width: 100, height: 100 }}
          />
          <div className="d-flex flex-column">
            <H1>Personal Notes Creation</H1>
            <Pmd color={COLORS.grey}>
              Make your learning personal by creating your own notes, adding insights, and organizing information your way. Claydeck’s intuitive editor keeps all your resources at hand, ready when you are.
            </Pmd>
          </div>
          </div>
        </GridItem>
        <GridItem
          w="100%"
          style={{ backgroundColor: COLORS.white }}
          className="d-flex justify-content-center rounded flex-row p-5"
        >
          <div style={{minHeight:'30vh',  display:'flex', alignItems:'center'}}>
          <img
            src={section6Img3}
            alt="section6Img3"
            className="me-3"
            style={{ width: 100, height: 100 }}
          />
          <div className="d-flex flex-column">
            <H1>In-Depth Case Studies</H1>
            <Pmd color={COLORS.grey}>
              Deepen your understanding with case studies tailored to every topic. From real-world examples to analysis, Claydeck helps you grasp complex concepts with ease.
            </Pmd>
          </div>
          </div>
        </GridItem>
        <GridItem
          w="100%"
          style={{ backgroundColor: COLORS.white }}
          className="d-flex justify-content-center rounded flex-row p-5"
        >
          <div style={{minHeight:'30vh',  display:'flex', alignItems:'center'}}>
          <img
            src={section6Img4}
            alt="section6Img4"
            className="me-3"
            style={{ width: 100, height: 100 }}
          />
          <div className="d-flex flex-column">
            <H1>PYQs and Practice Tests
            </H1>
            <Pmd color={COLORS.grey}>
              Boost your readiness with previous years' questions and tailored practice tests. Claydeck gives you the resources you need to master each topic step-by-step.
            </Pmd>
          </div>
          </div>
        </GridItem>
      </SimpleGrid>
      </div>

      {/* Section 7 */}
      <div style={{minHeight:'100vh', display:'flex', flexDirection:'column', alignItems:'center'}}>
      <MainHeading marginTop={'40px'}>
            Testimonials
          </MainHeading>
      <SimpleGrid columns={1} spacing="2px" className="my-5">
        <GridItem
          w="100%"
          h={{ lg: "20vh", md: "40vh" }}
          style={{ padding: "10%", paddingLeft: "20%", paddingRight: "20%" }}
          className="d-flex justify-content-center flex-column text-center align-items-center"
        >
          {/* <img
            src={section7Logo}
            alt="section7Logo"
            width={"15%"}
            className="mb-3"
          /> */}

          <MainHeading fontSize={'20px'}>
            "Its fantastic that the app helps me clear my doubts in real time when i am reading something. it has increased my learning speed a lot."
          </MainHeading>
          <img
            src={section7Avatar}
            alt="section7Avatar"
            className="mt-3 mb-2"
            width={"10%"}
          />
          <Psm style={{ marginBottom: 0 }}>Ahaan Durg</Psm>
          <Psm color={COLORS.grey}>Civil Service Student, Jaipur</Psm>
        </GridItem>
        <GridItem
          w="100%"
          h={{ lg: "20vh", md: "40vh" }}
          style={{ padding: "10%", paddingLeft: "20%", paddingRight: "20%" }}
          className="d-flex justify-content-center flex-column text-center align-items-center"
        >
          <MainHeading fontSize={'20px'}>
            "This app has helped me a lot in my preparation. The AI Master G and Timelines are very helpful and the content is very well structured."
          </MainHeading>
          <img
            src={section7Avatar2}
            alt="section7Avatar2"
            className="mt-3 mb-2"
            width={"10%"}
          />
          <Psm style={{ marginBottom: 0 }}>Riya Upadhyaya</Psm>
          <Psm color={COLORS.grey}>Civil Service Student, Mumbai</Psm>
        </GridItem>
      </SimpleGrid>
      </div>

      {/* Footer  */}

      <Grid
        className="mt-5"
        templateAreas={{
          lg: `"logo logo product company social legal"`,
          md: `"logo logo"
      "product company"
      "social legal"`,
          sm: `"logo"
      "logo"
      "product"
      "company"
      "social"
      "legal"`,
        }}
        gap="1"
        style={{
          padding: 100,
          marginTop: 100,
          backgroundColor: COLORS.blue,
        }}
      >
        <GridItem
          area={"logo"}
          style={{ padding: "10%", paddingLeft: "20%", paddingRight: "20%" }}
          className="d-flex justify-content-center flex-column"
        >
          <H1 color={COLORS.white}>Claydeck</H1>
          <Pmd color={COLORS.white} className="mt-4">
            Top learning experiences that create more talent in the world.
          </Pmd>
        </GridItem>
        {/* <GridItem
          className="d-flex justify-content-center flex-column"
          area={"product"}
        >
          <Psm color={COLORS.grey} className="mb-1">
            Product
          </Psm>
          <Pmd color={COLORS.white} className="my-1">
            Overview
          </Pmd>
          <Pmd color={COLORS.white} className="my-1">
            Features
          </Pmd>
          <Pmd color={COLORS.white} className="my-1">
            Solutions
          </Pmd>
          <Pmd color={COLORS.white} className="my-1">
            Tutorial
          </Pmd>
          <Pmd color={COLORS.white} className="my-1">
            Pricing
          </Pmd>
        </GridItem> */}
        <GridItem
          className="d-flex justify-content-center flex-column"
          area={"company"}
        >
          <Psm color={COLORS.offWhite} className="mb-1" fontSize={20}>
            Company
          </Psm>
          <Pmd
            as="a"
            color={COLORS.white}
            className="my-1 cursor"
            _hover={{
              textDecoration: "underline",
            }}
            href={'/'}
          >
            About Us
          </Pmd>
          <Pmd
            as="a"
            color={COLORS.white}
            className="my-1 cursor"
            _hover={{
              textDecoration: "underline",
            }}
            href={'/'}
          >
            Careers
          </Pmd>
          <Pmd
            as="a"
            color={COLORS.white}
            className="my-1 cursor"
            _hover={{
              textDecoration: "underline",
            }}
            href={'/'}
          >
            Leadership
          </Pmd>
          {/* <Pmd color={COLORS.white} className="my-1">
            News
          </Pmd> */}
        </GridItem>
        <GridItem
          className="d-flex justify-content-center flex-column"
          area={"social"}
        >
          <Psm color={COLORS.offWhite} className="mb-1" fontSize={20}>
            Social
          </Psm>
          <Pmd
            as="a"
            color={COLORS.white}
            className="my-1 cursor"
            _hover={{
              textDecoration: "underline",
            }}
            href={'https://www.instagram.com/clay.deck/?hl=en-gb'}
          >
            Instagram
          </Pmd>
          <Pmd
            as="a"
            color={COLORS.white}
            className="my-1 cursor"
            _hover={{
              textDecoration: "underline",
            }}
            href={'https://www.linkedin.com/company/claydeck/'}
          >
            LinkedIn
          </Pmd>
          <Pmd
            as="a"
            color={COLORS.white}
            className="my-1 cursor"
            _hover={{
              textDecoration: "underline",
            }}
            href={'/'}
          >
            Twitter
          </Pmd>
          {/* <Pmd color={COLORS.white} className="my-1">
            Discord
          </Pmd> */}
        </GridItem>
        <GridItem
          className="d-flex justify-content-center flex-column"
          area={"legal"}
        >
          <Psm color={COLORS.offWhite} className="mb-1" fontSize={20}>
            Legal
          </Psm>
          <Pmd
            as="a"
            color={COLORS.white}
            className="my-1 cursor"
            _hover={{
              textDecoration: "underline",
            }}
            href={'tandc'}
          >
            Terms
          </Pmd>
          <Pmd
            as="a"
            color={COLORS.white}
            className="my-1 cursor"
            _hover={{
              textDecoration: "underline",
            }}
            href={'privacy-policy'}
          >
            Privacy
          </Pmd>
          {/* <Pmd color={COLORS.white} className="my-1">
            Cookies
          </Pmd> */}
          <Pmd
            as="a"
            color={COLORS.white}
            className="my-1 cursor"
            _hover={{
              textDecoration: "underline",
            }}
            href={'/'}
          >
            Contact
          </Pmd>
        </GridItem>
      </Grid>
    </div>
  );
}
