import { Box, Button, useDisclosure } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import {useState} from "react";

const MotionBox = motion(Box);

const FlipCard = (props) => {
    const { isOpen, onToggle } = useDisclosure();
    const [showFront,setShowFront] = useState(true);

    return (
        <Box position="relative" width="100%" height="200px" style={{cusor:'pointer'}} onClick={()=>{onToggle()}}>
            <AnimatePresence initial={false} custom={isOpen}>

                <MotionBox

                    position="absolute"
                    width="100%"
                    height="100%"
                    background="purple"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    fontSize="2em"
                    color="white"
                    borderRadius="md"
                    boxShadow="xl"
                    key="back"
                    custom={isOpen}
                    initial={{ rotateY: -360 }}
                    animate={{ rotateY: isOpen ? 0 : -360 }}
                    exit={{ rotateY: -360 }}
                    onAnimationComplete={()=>{
                        setShowFront(!showFront)
                    }}
                    transition={{ flip: true }}
                    onTap={()=>{
                        onToggle()
                    }}
                >
                    {showFront ? props.frontContent : props.backContent}
                </MotionBox>
            </AnimatePresence>

            {/*<Button position="absolute" bottom="0" onClick={onToggle}>*/}
            {/*    Flip*/}
            {/*</Button>*/}
        </Box>
    );
};

export default FlipCard;
