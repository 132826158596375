import React from "react";
import { Input } from "@chakra-ui/react";

const InputOutline = (props) => {
  return (
    <Input
      size="md"
      height="42px"
      bg="claydeck.white.primary"
      color="claydeck.text.primary"
      variant="outline"
      fontSize={{ base: "16px", lg: "18px" }}
      _hover={{ bg: "blue.100" }}
      _placeholder={{
        color: "claydeck.text.tertiary",
        fontSize: { base: "14px", lg: "16px" },
        letterSpacing: "0.6px",
      }}
      fontWeight={"500"}
      line-height="140%"
      letterSpacing="0.6px"
      borderRadius={"50px"}
      borderWidth={'0px'}
      // border={"0px solid #AEAAAE"}
      {...props}
    />
  );
};

export default InputOutline;
