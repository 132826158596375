import {
  SET_USER,
  SET_NEW_USER,
  SET_USERNAME,
  SET_AUTHENTICATED,
  SET_UNAUTHENTICATED,
  SET_REMEMBER_ME,
  LOADING_USER,
  LIKE_SCREAM,
  UNLIKE_SCREAM,
  MARK_NOTIFICATIONS_READ,
  SET_GROUPS,
  SET_KEY,
  SET_ACCESS_TOKEN,
  ADD_WORKSPACE,
  ADD_WORKSPACES,
  LEAVE_WORKSPACE,
  SET_WORKSPACE,
  ADD_FEED,
  SET_SUBSCRIPTION,
  SET_PROFILE
} from "../types";
// import { getAsyncStorageItem } from '../../utils/asyncStorage';

let credentials, groups;

// getAsyncStorageItem("CREDENTIALS").then((val) => (credentials = val));
// getAsyncStorageItem("GROUPS").then((val) => (groups = val));

const initialState = {
  newUser: false,
  authenticated: false,
  credentials: credentials ? JSON.parse(credentials).cred : {} || {},
  accessToken: null,
  refreshToken: null,
  rememberMe: false,
  username: "",
  loading: false,
  likes: [],
  notifications: [],
  groups: groups ? JSON.parse(groups) : [],
  workspaces: [],
  selectedWorkspace: {},
  selectedFeeds: [],
  subscriptionActive: false,
  profile: {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    studyHabits: {
      // monday: new Date(),
      // tuesday: new Date(),
      // wednesday: new Date(),
      // thursday: new Date(),
      // friday: new Date(),
      // saturday: new Date(),
      // sunday: new Date(),
      monday: "0",
      tuesday: "0",
      wednesday: "0",
      thursday: "0",
      friday: "0",
      saturday: "0",
      sunday: "0",
    },
    examDate: new Date(),
  },
};


export default function (state = initialState, action) {
  switch (action.type) {
    case SET_NEW_USER:
      return {
        ...state,
        newUser: action.payload,
      };
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: true,
      };
    case SET_UNAUTHENTICATED:
      return initialState;
    case SET_REMEMBER_ME:
      return {
        ...state,
        rememberMe: action.payload,
      };
    case SET_USER:
      return {
        authenticated: true,
        loading: false,
        accessToken: state.accessToken,
        ...action.payload,
      };
    case SET_USERNAME:
      return {
        ...state,
        username: action.payload,
      };
    case LOADING_USER:
      return {
        ...state,
        loading: true,
      };
    case LIKE_SCREAM:
      return {
        ...state,
        likes: [
          ...state.likes,
          {
            userHandle: state.credentials.handle,
            screamId: action.payload.screamId,
          },
        ],
      };
    case UNLIKE_SCREAM:
      return {
        ...state,
        likes: state.likes.filter(
          (like) => like.screamId !== action.payload.screamId
        ),
      };
    case MARK_NOTIFICATIONS_READ:
      state.notifications.forEach((not) => (not.read = true));
      return {
        ...state,
      };
    case SET_GROUPS:
      return {
        ...state,
        groups: action.payload,
      };
    case ADD_WORKSPACE:
      // add a single workspace
      return {
        ...state,
        workspaces: [...state.workspaces, action.payload],
      };
    case ADD_WORKSPACES:
      // add multiple workspaces
      // ! if the global state already has any workspaces from payload it will get replaced in the global state with the ones in payload
      const filteredWorkspaces = state.workspaces.filter((workspace) => {
        // this will remove workspaces from the global state that are common with the payload
        // and keep the workspaces from the global state that do not match with the payload
        const index = action.payload.findIndex(
          ({ workspace_id }) => workspace_id === workspace["workspace_id"]
        );
        return index === -1 ? true : false;
      });

      return {
        ...state,
        workspaces: [...filteredWorkspaces, ...action.payload],
      };
    case SET_WORKSPACE:
      // to set a selected workspace
      return {
        ...state,
        selectedWorkspace: action.payload,
      };
    case LEAVE_WORKSPACE:
      const currentWorkspaces = state.workspaces;
      const newWorkspaces = currentWorkspaces.filter(
        (workspace) => workspace.workspace_id !== action.payload
      );
      return {
        ...state,
        workspaces: newWorkspaces,
        selectedWorkspace: newWorkspaces[0],
      };
    case ADD_FEED:
      return {
        ...state,
        selectedFeeds: [...state.selectedFeeds, action.payload],
      };
    case SET_KEY:
      return {
        ...state,
        accessToken: action.payload.access,
        refreshToken: action.payload.refresh,
      };
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload,
      };
    case SET_SUBSCRIPTION:
      return {
        ...state,
        subscriptionActive: action.payload,
      };
    case SET_PROFILE:
      return {
        ...state,
        profile: {
          ...action.payload,
        },
      };
    default:
      return state;
  }
}
