import React from 'react';
import { Link } from 'react-router-dom';

import {Button, Heading, Text} from '@chakra-ui/react'
function ButtonText(props) {

    if(props.link){
        return (
            <Link to={props.link}>
            <Button color={"white"}
                    bg={"transparent"} _hover={{bg:"claydeck.brand.hover"}} borderRadius={"50px"} {...props} >{props.children}</Button>
            </Link>
        );
    }

    return (
        <Button color={"white"}
                bg={"transparent"} _hover={{bg:"claydeck.brand.hover"}} borderRadius={"50px"} {...props} >{props.children}</Button>
    );
}

export default ButtonText;
