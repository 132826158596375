import {
    Input,
    FormControl,
    FormLabel,
    InputGroup,
    InputLeftElement,
    FormErrorMessage,
    Code,
    Icon,
    InputRightElement, Flex, Box, IconButton,Image
} from "@chakra-ui/react";
import {FiFile, FiX} from "react-icons/fi";
import {useEffect, useRef, useState} from "react";
import ButtonPrimary from "./Button/ButtonPrimary";

const FileUpload = ({ name, placeholder, acceptedFileTypes, control, children, isRequired=false,style,setImageData }) => {
    const inputRef = useRef();
    const[value,setValue] = useState(undefined);

    const [images, setImages] = useState([]);
    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);

        // Update value (to be passed to parent via `setImageData`)
        setValue(files);

        // Generate previews
        const newImages = files.map((file) => ({
            file,
            url: URL.createObjectURL(file),
        }));
        setImages(newImages);
    };

    const handleRemoveImage = (index) => {
        setImages((prev) => {
            const updatedPreviews = [...prev];
            updatedPreviews.splice(index, 1);

            // Update value to reflect removal
            const updatedFiles = value.filter((_, i) => i !== index);
            setValue(updatedFiles);

            return updatedPreviews;
        });
    };


    useEffect(() => {
        if(value)
        setImageData(value);
    }, [value]);

    return (
        <FormControl style={style} isInvalid={false}>
            <FormLabel htmlFor="writeUpFile">{children}</FormLabel>
            <InputGroup>
                {/*<InputRightElement*/}
                {/*    pointerEvents="none"*/}
                {/*    children={<Icon as={FiFile} />}*/}
                {/*/>*/}
                <input multiple="multiple" onChange={(e)=>{
                    setValue(e.target.files);
                    handleFileChange(e);
                }
                } type='file' accept={acceptedFileTypes} name={name} ref={inputRef} style={{ display: 'none' }}></input>
                <Input
                    variant={'filled'}
                    background={'#EBEBEB'}
                    borderRadius={'12px 0px 0px 12px'}
                    placeholder={placeholder || "Upload an Image"}
                    onClick={() => inputRef.current.click()}
                    // value={value}
                />
                <ButtonPrimary width={'200px'} onClick={() => inputRef.current.click()} borderRadius={'0px 12px 12px 0px'}>Choose Image</ButtonPrimary>
            </InputGroup>
            {images.length > 0 && (
                <Flex wrap="wrap" mt={4} gap={4}>
                    {images.map((image, index) => (
                        <Box key={index} position="relative" w="100px" h="100px">
                            <Image
                                src={image.url}
                                alt={`Preview ${index}`}
                                objectFit="cover"
                                borderRadius="md"
                                w="100%"
                                h="100%"
                            />
                            <IconButton
                                icon={<FiX />}
                                size="sm"
                                position="absolute"
                                top="0"
                                right="0"
                                aria-label="Remove image"
                                onClick={() => handleRemoveImage(index)}
                                colorScheme="red"
                            />
                        </Box>
                    ))}
                </Flex>)}
        </FormControl>
    );
}

export default FileUpload;
