import React, { useEffect, useState } from "react";
import "./FeedItem.css";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { Badge, Stack } from "@chakra-ui/react";
import { getFeedItem } from "../../utils/Request";
import moment from "moment/moment";
import Modal from "../../Components/Modal";
import COLORS from "../../Components/colors";
import InputOutline from "../../Components/Input/InputOutline";
import Pmd from "../../Components/Typography/Text/Pmd";

import arrowUp from "../../assets/arrowFatUp.png";
import bookmark from "../../assets/bookmark.png";
import users from "../../assets/users.png";
import commentsGrey from "../../assets/commentsGrey.png";
import hashtagIcon from "../../assets/hashtag.png";
import hashtagIllustration from "../../assets/hashtagIllustration.png";
import fullStop from "../../assets/full-stop.png";

export default function FeedItem({ todaysHeadlineItem }) {
  const [data, setData] = useState({});
  const [comment, setComment] = useState("");
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [hashtag, setHashtag] = useState("");
  const [showHashtagModal, setShowHashtagModal] = useState(false);
  // console.log('todayheadline',todaysHeadlineItem)
  // useEffect(() => {
  //   getData();
  // }, []);
  //
  // const getData = async () => {
  //   try {
  //     let res = await getFeedItem(todaysHeadlineItem);
  //     console.log({ res: res.data });
  //     setData(res.data);
  //   } catch (error) {
  //     console.log("get feed item error:", error);
  //   }
  // };

  return (
    <>
      <Box className="feed-item-container shadow-0" padding={4}>
        <Flex direction="row">
          <Image
            src={
              todaysHeadlineItem?.image_url !== undefined
                ? todaysHeadlineItem?.image_url?.url
                : "./assets/images/HinduLogo.png"
            }
            boxSize="40px"
            borderRadius="20px"
            marginRight="10px"
            objectFit="cover"
            alt="feed-source"
          />
          <Box>
            <Text fontSize="16px" color={COLORS.black} fontWeight="bold">
              {todaysHeadlineItem.source}
            </Text>
            <Text fontSize="12px" color="gray" fontWeight="lighter">
              {moment(todaysHeadlineItem.pub_date).format("DD/MM/YYYY")}
            </Text>
          </Box>
        </Flex>
        <Text fontSize="14px" color={COLORS.black} fontWeight="bold">
          {todaysHeadlineItem.title}
        </Text>
        {todaysHeadlineItem?.image_url ? (
          <Image
            src={todaysHeadlineItem.image_url?.url}
            className="my-2"
            width={`${todaysHeadlineItem.image_url?.width}px`}
            borderRadius="3px"
            alt="feed"
          />
        ) : (
          <></>
        )}
        <Text fontSize="14px" color={COLORS.grey}>
          {todaysHeadlineItem.summary}
        </Text>
        {/*<Flex justify="space-between" direction="row" mt="3">*/}
        {/*  <Box>*/}
        {/*    <Flex*/}
        {/*      backgroundColor={COLORS.backgroundGrey}*/}
        {/*      display={"inline-flex"}*/}
        {/*      direction={"row"}*/}
        {/*      align={"center"}*/}
        {/*      justify={"center"}*/}
        {/*      borderRadius="15px"*/}
        {/*      padding={2}*/}
        {/*    >*/}
        {/*      <Image*/}
        {/*        src={arrowUp}*/}
        {/*        objectFit={"contain"}*/}
        {/*        alt="upvote"*/}
        {/*        className="cursor"*/}
        {/*      />*/}
        {/*      <Pmd*/}
        {/*        width="125px"*/}
        {/*        color="#828282"*/}
        {/*        textAlign={"center"}*/}
        {/*        fontSize="14px"*/}
        {/*        marginX={3}*/}
        {/*        marginBottom={"0px"}*/}
        {/*      >*/}
        {/*        Found Useful . {data.vote?.upvotes ? data.vote?.upvotes : 0}*/}
        {/*      </Pmd>*/}
        {/*      <Image*/}
        {/*        src={arrowUp}*/}
        {/*        objectFit={"contain"}*/}
        {/*        className="rotate cursor"*/}
        {/*        alt="downvote"*/}
        {/*      />*/}
        {/*    </Flex>*/}
        {/*    <Flex className="d-inline-flex flex-row p-2">*/}
        {/*      <Image src={bookmark} alt="bookmark" className="cursor" />*/}
        {/*      <Image*/}
        {/*        src={commentsGrey}*/}
        {/*        className="mx-3 cursor"*/}
        {/*        boxSize="17px"*/}
        {/*        marginTop="1px"*/}
        {/*        alt="comment"*/}
        {/*        onClick={() => setShowCommentModal(true)}*/}
        {/*      />*/}
        {/*      <Image*/}
        {/*        className="cursor"*/}
        {/*        src={hashtagIcon}*/}
        {/*        boxSize="17px"*/}
        {/*        alt="hashtag"*/}
        {/*        onClick={() => setShowHashtagModal(true)}*/}
        {/*      />*/}
        {/*    </Flex>*/}
        {/*  </Box>*/}
        {/*  <Flex className="d-inline-flex mx-2" height="25px">*/}
        {/*    <Image src={users} alt="users" />*/}
        {/*  </Flex>*/}
        {/*</Flex>*/}
        <Modal
          show={showCommentModal}
          handleClose={() => setShowCommentModal(false)}
        >
          <Text fontSize="20px" color={COLORS.purple} fontWeight="bold">
            <u>Comments:</u>
          </Text>
          {/*{data?.comments ? null : <Text>Be the first one to comment...</Text>}*/}
          {/*{data?.comments?.map((item) => {*/}
          {/*  return (*/}
          {/*    <>*/}
          {/*      <Flex*/}
          {/*        className="d-flex flex-row align-items-center"*/}
          {/*        marginBottom="-20px"*/}
          {/*      >*/}
          {/*        <Text*/}
          {/*          style={{*/}
          {/*            fontSize: "14px",*/}
          {/*            color: COLORS.black,*/}
          {/*            fontWeight: "bold",*/}
          {/*          }}*/}
          {/*        >*/}
          {/*          {item.username}*/}
          {/*        </Text>*/}
          {/*        <Image src={fullStop} boxSize="20px" alt="dot" />*/}
          {/*        <Text*/}
          {/*          style={{*/}
          {/*            fontSize: "12px",*/}
          {/*            color: COLORS.grey,*/}
          {/*          }}*/}
          {/*        >*/}
          {/*          {moment(item.time).format("DD/MM/YYYY")}*/}
          {/*        </Text>*/}
          {/*      </Flex>*/}
          {/*      <br />*/}
          {/*      <Text*/}
          {/*        style={{*/}
          {/*          fontSize: "14px",*/}
          {/*          color: COLORS.black,*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        {item.text}*/}
          {/*      </Text>*/}
          {/*      <br />*/}
          {/*      <br />*/}
          {/*    </>*/}
          {/*  );*/}
          {/*})}*/}
          <Flex style={{ flexDirection: "row" }}>
            <InputOutline
              height="40px"
              placeholder="Enter your comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <Flex
              className="d-flex justify-content-center align-items-center px-3 mx-2 cursor"
              boxSize="40px"
              backgroundColor={COLORS.purple}
              color={COLORS.white}
              borderRadius="5px"
            >
              Submit
            </Flex>
          </Flex>
        </Modal>
        <Modal
          show={showHashtagModal}
          handleClose={() => setShowHashtagModal(false)}
        >
          <Flex justify="center" direction="column" align="center">
            <Image
              src={hashtagIllustration}
              alt="hashtag-illustration"
              boxSize="30%"
            />
            <Stack
              direction="row"
              className="d-flex align-self-center mb-3"
              style={{
                width: "50%",
              }}
            >
              {/*{data?.hashtags?.map((item) => {*/}
              {/*  return (*/}
              {/*    <Box*/}
              {/*      className="py-1 px-3 mx-3"*/}
              {/*      backgroundColor={COLORS.grey}*/}
              {/*      color={COLORS.white}*/}
              {/*      borderRadius="20px"*/}
              {/*    >*/}
              {/*      {item}*/}
              {/*    </Box>*/}
              {/*  );*/}
              {/*})}*/}
            </Stack>
          </Flex>
          <Flex style={{ flexDirection: "row" }}>
            <InputOutline
              height="40px"
              placeholder="Enter your hashtag"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <Flex
              className="d-flex justify-content-center align-items-center px-3 mx-2 cursor"
              boxSize="40px"
              backgroundColor={COLORS.purple}
              color={COLORS.white}
              borderRadius="5px"
            >
              Submit
            </Flex>
          </Flex>
        </Modal>
      </Box>
      <Box backgroundColor={"#eee"} height={"1px"} width={"100%"} />
    </>
  );
}
