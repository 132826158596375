import { Box, Divider, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import MockPaperTest from "./MockPaperTest";

const PracticePaperTestPage = () => {
  const params = useParams();

  const [isQuizCompleted, setQuizCompleted] = useState(false);
  const [timer, setTimer] = useState(600);

  useEffect(() => {
    if (isQuizCompleted) {
      return;
    } else {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);

        if (timer === 0) {
          setTimer(0);
          clearInterval(interval);
          setQuizCompleted(true);
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [timer]);

  return (
    <Box
      display={"flex"}
      width={"full"}
      flexDir={"column"}
      padding={8}
      margin={8}
    >
      <Box width={"full"}>
        <Box
          backgroundColor={"purple.100"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          padding={2}
          borderRadius={"md"}
          borderWidth={"2px"}
          borderTopColor={"purple.300"}
          boxShadow={"md"}
        >
          <Text
            fontSize={"xl"}
            fontWeight={"bold"}
            letterSpacing={"2px"}
            margin={"0"}
          >
            Practice Test
          </Text>
        </Box>
      </Box>

      <Box marginTop={"16px"} display={"flex"} flexDir={"column"} gap={2}>
        <Text color={"purple.500"} m={0}>
          Details: {params?.subject + " - " + params?.test}{" "}
        </Text>
        <Text fontWeight={"semibold"} mt={0}>
          {isQuizCompleted ? "Time Left" : "Timer"}: {Math.floor(timer / 60)}{" "}
          mins : {timer % 60} seconds
        </Text>
      </Box>
      <Divider colorScheme="purple" margin={0} opacity={1} />
      <Box>
        <MockPaperTest
          setQuizCompleted={setQuizCompleted}
          isQuizCompleted={isQuizCompleted}
          id={params?.id}
        />
      </Box>
    </Box>
  );
};

export default PracticePaperTestPage;
