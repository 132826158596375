import { Box, Container, Divider, Text, Button, Spinner } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import "./style.css";
import useForumGetData from "../../hooks/useForumGetData";
import { useSelector } from "react-redux";
import ButtonTag from "../../Components/Button/ButtonTag";
import { nanoid } from "nanoid";

const DetailedForumPage = () => {
  const { id } = useParams();

  const {} = useForumGetData();
  const dataApi = useSelector((state) => state.forumData.data.message);

  if (!dataApi) {
    return <Spinner size="md" />; // or some other loading indicator
  }

  const dataById = () => {
    return dataApi?.filter((data) => data?.id === id);
  };

  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = dataById()[0]?.detailed_text;

  const Data0 = dataById()[0]?.tags;
  const splittedData = Data0[0]?.split(",");

  const handleTagsMapping = (rawData, Data) => {
    if (rawData?.length > 1) {
      let jsonString = Data[0].replace(/'/g, '"');
      const innerArray = JSON.parse(jsonString);
      console.log(innerArray);
      return innerArray;
    } else {
      console.log(Data);
      return Data;
    }
  };

  return (
    <Box as="div" className="x" position={"relative"}>
      <Container
        borderLeft={"1px solid #E2E8F0"}
        as={"div"}
        position={"absolute"}
        top={"0px"}
        right={"44px"}
        minW={"1044px"}
        paddingX={"22px"}
        paddingY={"48px"}
      >
        <Text fontSize={"md"} fontWeight={"bolder"} textColor={"#6750a4"}>
          Tags:
        </Text>
        <Box key={nanoid()} padding={"8px"} display={"flex"} gap={2}>
          {handleTagsMapping(splittedData, Data0).map((item, i) => (
            <Button
              disabled
              cursor={"default"}
              key={nanoid()}
              size={"sm"}
              fontWeight={"semibold"}
              fontSize={"small"}
              border={"1px solid #949494"}
              backgroundColor={"white"}
              textColor={"#949494"}
              paddingX={"10px"}
              paddingY={"8px"}
              rounded={"full"}
              maxH={"28px"}
            >
              {item}
            </Button>
          ))}
        </Box>
        <div dangerouslySetInnerHTML={{ __html: tempDiv.innerHTML }} />
        <Divider orientation="horizontal" size={"md"} marginY={"50px"} />
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"start"}
          alignItems={"start"}
        >
          <Text>Attached Files :</Text>
          <Box display={"flex"} padding={"8px"}>
            {dataById()[0]?.pdfs?.map((pdf, i) => (
              <Box
                key={nanoid()}
                border={"1px solid black"}
                rounded={"full"}
                paddingX={"16px"}
                paddingY={"4px"}
                color={"white"}
                fontWeight={"semibold"}
                style={{
                  cursor: "pointer",
                  backgroundColor: "#6750a4",
                  color: "white",
                }}
                as="a"
                href={pdf}
                download
              >
                pdf{i}
              </Box>
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default DetailedForumPage;
