import { useState, useRef } from "react";
import {
  Box,
  VStack,
  Flex,
  Spacer,
  Button,
  Text,
  Divider,
  Input,
  Image,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";

import getEventTimeDetails from "../utils/getEventTimeDetails";

const MessageModalBox = () => {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [attachment, setAttachment] = useState(null);
  const fileInputRef = useRef(null);

  const handleSendMessage = (e) => {
    if (message !== "" || attachment !== null) {
      const timestamp = new Date();
      const { MonthDateFormat, TimeFormate } = getEventTimeDetails(timestamp);

      const newMessage = {
        text: message,
        timestamp: `${MonthDateFormat} at ${TimeFormate}`,
        attachment: attachment,
      };

      setMessages([...messages, newMessage]);

      setMessage("");
      setAttachment(null);
    }
    return;
  };

  const handleAttachmentChange = (e) => {
    const selectedFile = e.target.files[0];
    setAttachment(selectedFile);
  };

  const handleDeleteAttachment = () => {
    setAttachment(null);
    fileInputRef.current.value = "";
  };

  return (
    <>
      <Box
        width={{ lg: "375px", base: "350px" }}
        rounded={"sm"}
        maxHeight={"400px"}
        overflowY={"auto"}
        backgroundColor="rgba(227, 245, 255, 0.5)"
        border="0.75px solid black"
        p="2"
        sx={{
          "::-webkit-scrollbar-track": {
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
            borderRadius: "5px",
            backgroundColor: "rgba(227, 245, 255, 0.5)",
          },
          "::-webkit-scrollbar": {
            width: "7px",
            backgroundColor: "rgba(227, 245, 255, 0.5)",
          },
          "::-webkit-scrollbar-thumb": {
            borderRadius: "5px",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
            backgroundColor: "rgba(66, 153, 225, 1)",
          },
        }}
      >
        <VStack align="start" spacing={2} mb="2">
          {messages.map((message, index) => (
            <Box key={index} marginLeft={"8px"} marginBottom={"4px"}>
              <Text fontSize={{ lg: "15px", base: "14px" }}>
                {message.text}
              </Text>
              {message.attachment && (
                <Box
                  gap={"1"}
                  border={"0.5px solid gray"}
                  rounded={"md"}
                  p={"1"}
                  px={"2"}
                  display={"inline-flex"}
                  width={"auto%"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  _hover={{
                    ".hover-image": {
                      opacity: 0.8,
                    },
                    ".hover-text": {
                      color: "black",
                    },
                    border: "1px solid black",
                    transform: "scale(1.01)",
                    transition: "transform 0.18s",
                  }}
                >
                  <Image
                    src="assets/icons/materials/doc.svg"
                    w={"20px"}
                    h={"20px"}
                    opacity={"50%"}
                    className="hover-image"
                  />
                  <Text
                    as={"a"}
                    fontSize={{ lg: "12px", base: "10px" }}
                    color={"rgba(130, 130, 130, 1)"}
                    className="hover-text"
                    href={URL.createObjectURL(message.attachment)}
                    download={message.attachment.name}
                  >
                    {message.attachment.name}
                  </Text>
                </Box>
              )}
              <Text
                fontSize={{ lg: "10px", base: "9px" }}
                color={"#828282"}
                marginTop={"1"}
              >
                {message.timestamp}
              </Text>
              <Divider width={"315px"} colorScheme="telegram" size={"xl"} />
            </Box>
          ))}
        </VStack>
        <Box>
          <Input
            required
            border={"1px solid gray"}
            rounded={"md"}
            size={"sm"}
            placeholder="Type your message..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            mb="2"
          />

          <Flex alignItems="center" mb="2">
            <Box display={"flex"} flexDir={"column"}>
              <label htmlFor="file-input">
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={"1"}
                  cursor={"pointer"}
                  opacity={"0.75"}
                  _hover={{
                    opacity: 1,
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M12.375 4.5V13.125C12.375 14.7825 11.0325 16.125 9.375 16.125C7.7175 16.125 6.375 14.7825 6.375 13.125V3.75C6.375 2.715 7.215 1.875 8.25 1.875C9.285 1.875 10.125 2.715 10.125 3.75V11.625C10.125 12.0375 9.7875 12.375 9.375 12.375C8.9625 12.375 8.625 12.0375 8.625 11.625V4.5H7.5V11.625C7.5 12.66 8.34 13.5 9.375 13.5C10.41 13.5 11.25 12.66 11.25 11.625V3.75C11.25 2.0925 9.9075 0.75 8.25 0.75C6.5925 0.75 5.25 2.0925 5.25 3.75V13.125C5.25 15.405 7.095 17.25 9.375 17.25C11.655 17.25 13.5 15.405 13.5 13.125V4.5H12.375Z"
                      fill="#828282"
                    />
                  </svg>
                  <Text fontSize={"12px"} color={"rgba(130, 130, 130, 1)"}>
                    Attach any files here
                  </Text>
                </Box>
              </label>
              <input
                type="file"
                id="file-input"
                accept=".pdf, .doc, .jpg, .png"
                style={{ display: "none" }}
                onChange={handleAttachmentChange}
                ref={fileInputRef}
              />
              {attachment && (
                <Text
                  marginTop={"4px"}
                  fontSize={"12px"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={"1"}
                >
                  {attachment.name}{" "}
                  <Box
                    as="div"
                    onClick={handleDeleteAttachment}
                    cursor={"pointer"}
                    _hover={{
                      color: "red",
                      opacity: "0.69",
                      transform: "scale(1.15)",
                      transition: "transform 0.25s",
                    }}
                    style={{}}
                  >
                    <DeleteIcon />
                  </Box>
                </Text>
              )}
            </Box>
            <Spacer />
            <Button
              size={"sm"}
              fontSize={"12px"}
              fontWeight={"light"}
              onClick={handleSendMessage}
              backgroundColor="rgba(33, 0, 93, 1)"
              color="white"
              _hover={{ bg: "rgba(33, 0, 93, 0.8)" }}
            >
              Send
            </Button>
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export default MessageModalBox;
