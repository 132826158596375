import React from "react";
import { Text } from "@chakra-ui/react";

function Pmd(props) {
  return (
    <Text
      color={"claydeck.text.primary"}
      fontWeight={400}
      fontSize="md"
      {...props}
    >
      {props.children}
    </Text>
  );
}

export default Pmd;
