import React, { useState, useEffect } from "react";
import { Box, Text, useMediaQuery, Collapse, Flex } from "@chakra-ui/react";
import RoomCard from "./roomCard";
import useGetAllRooms from "../utils/getAllRooms";

const RoomLists = ({ data }) => {
  const [isSmallDevice] = useMediaQuery("(max-width: 768px)");
  const [displayCount, setDisplayCount] = useState(3);
  const [showAll, setShowAll] = useState(false);

  // const { r_data, isLoading } = useGetAllRooms(1); Get all rooms from workspace....

  // console.log("data=======>", r_data, "isLoading=======>", isLoading);

  useEffect(() => {
    if (!isSmallDevice) {
      setDisplayCount(data.length);
    } else {
      setDisplayCount(3);
    }
  }, [isSmallDevice, data.length]);

  const handleShowMore = () => {
    setShowAll(!showAll);
  };

  return (
    <Box
      overflowX="auto"
      whiteSpace="nowrap"
      maxWidth={{ lg: "700px", md: "auto", base: "auto" }}
      sx={{
        "::-webkit-scrollbar-track": {
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
          borderRadius: "5px",
          backgroundColor: "#F8F8F8",
        },
        "::-webkit-scrollbar": {
          width: "4px",
          backgroundColor: "#F8F8F8",
        },
        "::-webkit-scrollbar-thumb": {
          borderRadius: "5px",
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
          backgroundColor: "#F8F8F8",
        },
      }}
    >
      <Flex flexDir={isSmallDevice ? "column" : null}>
        {data.slice(0, displayCount).map((room) => (
          <RoomCard key={room.id} room={room} />
        ))}
        {isSmallDevice && (
          <Collapse in={showAll} animateOpacity>
            <Flex flexWrap="wrap" flexDir={"column"}>
              {data.slice(3).map((room) => (
                <RoomCard key={room.id} room={room} />
              ))}
            </Flex>
          </Collapse>
        )}
        {isSmallDevice && data.length > 3 && (
          <Box
            as="div"
            onClick={handleShowMore}
            display={"flex"}
            width={"full"}
            justifyContent={"center"}
            alignItems={"center"}
            cursor={"pointer"}
          >
            <Text fontSize={"14px"} color={"#828282"}>
              {showAll ? "Show Less" : "Show More"}
            </Text>
            {isSmallDevice && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
              >
                <path
                  d="M11.3136 11.3137V13.3078L19.212 13.3148L10.6065 21.9203L12.0207 23.3345L20.6262 14.7291L20.6333 22.6274H22.6273V11.3137H11.3136Z"
                  fill="#828282"
                />
              </svg>
            )}
          </Box>
        )}
      </Flex>
    </Box>
  );
};

export default RoomLists;
