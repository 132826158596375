import React from 'react';
import {Box, Flex, Image, Select} from "@chakra-ui/react";
import Pmd from "./Typography/Text/Pmd";
import ButtonPrimary from "./Button/ButtonPrimary";
import ButtonSecondary from "./Button/ButtonSecondary";
import {ArrowBackIcon} from "@chakra-ui/icons";

function HeadCard(props) {
    const filterData = [
        {
            label: "Last 1 week",
            value: "7",
        },
        // { label: "Last Day", value: "last_day" },
        // { label: "Last 1 week", value: "7" },
        { label: "Last 1 month", value: "30" },
        { label: "Last 3 months", value: "90" },
        { label: "Last 6 months", value: "180" },
        { label: "Last 1 Year", value: "365" },
        { label: "Last 2 Years", value: "730" },
    ];
    const [isHovered, setIsHovered] = React.useState(false);

    return (
        <Box position={'relative'} zIndex={2} borderRadius={'12px'} backgroundColor={'claydeck.white.primary'} {...props} border={props.border?'1px solid #B7B7B7':'none'} boxShadow={'0px 4px 4px 0px #00000040'}>
            <Flex justifyContent={'space-between'} alignItems={'center'}>
                <Flex alignItems={'center'} mt={'13px'}>
                    {props.title && !props.dontShowBlueBox && <Box width={'4px'} height={'20px'} backgroundColor={'claydeck.brand.primary'}
                          borderRadius={'0px 12px 12px 0px'}/>}
                    {
                        props.showBack &&
                        // <ArrowBackIcon
                        //     cursor={"pointer"}
                        //     onClick={() => {
                        //         props.back()
                        //     }}
                        //     // marginLeft={"-65px"}
                        //     marginRight={"0px"}
                        //     ml={'12px'}
                        //     boxSize={"35px"}
                        // />
                        <img
                        src={isHovered ? "/back_button_hover.png" : "/back_button.png"}
                        onClick={() => {props.back()}}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        style={{
                        cursor:'pointer',
                        width:'28px',
                        height:'28px',
                        marginRight:'0px',
                        marginLeft:'24px'
                        }}
                          />

                        // <ButtonSecondary mr={'10px'} onClick={()=>{}}>Back</ButtonSecondary>
                    }

                    <Pmd color={'#343B40'} margin={0} pl={'16px'}>
                        <strong>{props.title}</strong>
                    </Pmd>
                </Flex>

                <Flex alignItems={'center'} mt={'13px'} mr={'13px'}>
                <Pmd color={'claydeck.brand.secondary'} margin={0} pr={'8px'}>

                {
                    props.titleButton ? props.titleButton : ''
                }
                </Pmd>

                    {
                        props.select && <Select onChange={(e)=>{
                            if(props.selectChange){
                                props.selectChange(e)
                            }
                        }} borderRadius={30} w={'150px'} border={'1px solid #0962A2'} color={'#0962A2'}>


                            {
                                filterData.map((item,idx)=> (
                                    <option value={item.value}>{item.label}</option>
                                ))
                            }
                        </Select>
                    }

                {
                    props.titleButton && <Image src={'/bluearrow.png'} height={'15px'}></Image>
                }
                </Flex>
            </Flex>


            {props.children}

        </Box>
    );
}

export default HeadCard;
