import {
    Avatar,
    Box, Button,
    Center,
    Flex,
    Image,
    Modal, ModalBody, ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure
} from "@chakra-ui/react";
import { Link } from 'react-router-dom';

import TaskList from "./TaskList";
import Timer from "./Timer";
import TaskCalendar from "./TaskCalendar";
import {MdOutlineDynamicFeed, MdOutlineForum, MdOutlineHome} from "react-icons/md";
import {BiVideo} from "react-icons/bi";
import {PiBookOpenText} from "react-icons/pi";
import {LiaElementor} from "react-icons/lia";
import Psm from "../../Components/Typography/Text/Psm";
import React, {useState} from "react";
import Plg from "../../Components/Typography/Text/Plg";
import Pmd from "../../Components/Typography/Text/Pmd";
import Pxl from "../../Components/Typography/Text/Pxl";
import { useSelector } from "react-redux";


const Sidebar = (props) => {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const {first_name} = useSelector(state => state.user.profile)
    const username = useSelector(state => state.user.username)

    // const {navigation} = props
  // return (
  //   <Box position={"relative"} width={{ base: props.sidebarVisible ? "100%" : "0%", xl: "20%" }} minWidth={{ base: props.sidebarVisible ? "100%" : "0%", xl: "20%" }}>
  //     <Box
  //       bgColor={"claydeck.white.primary"}
  //       boxShadow={"xl"}
  //       paddingBottom={"24px"}
  //
  //       position={{ base: "absolute", xl: "sticky" }}
  //       top={0}
  //       left={{base: props.sidebarVisible ? "0px" : "-500px", xl: '0px'}}
  //       height={{base: 'min-content'}}
  //       minHeight={'100vh'}
  //       overflowY={"scroll"}
  //       zIndex={1}
  //       width={'100%'}
  //     >
  //       {/*<TaskList />*/}
  //       {/*<Timer />*/}
  //       {/*<TaskCalendar />*/}
  //     </Box>
  //   </Box>
  // );

    const paths = [
        {
            name: "Home",
            path: "/home",
            icon: '/navcons/homeIcon1.png',
            selectedIcon: '/navcons/homeIconfilled.png',
        },
        {
            name: "Syllabus",
            path: "/learn",
            icon: '/navcons/openbook.png',
            selectedIcon: '/navcons/openbookfilled.png',

        },
        {
            name: "News",
            path: "/news",
            icon: '/navcons/news2.png',
            selectedIcon: '/navcons/newsfilled.png',

        },
        {
            name: "Tests",
            path: "/tests",
            icon: '/navcons/test2.png',
            selectedIcon: '/navcons/testfilled.png',

        },

    ];

    const menuLinks=[
        {
            name:'My Notes',
            onClick:()=>{
                window.location.href = '/mynotes'
            }
        },
        // {
        //     name:'My Progress'
        // },
        // {
        //     name:'setting'
        // },
        // {
        //     name:'News Notes'
        // },
        {
            name:'Contact Us',
            onClick:()=>{
                onOpen();

                // localStorage.clear()
                // window.location.reload()
            }
        },
        {
            name:'Logout',
            onClick:()=>{
                localStorage.clear()
                window.location.reload()
            }
        }
    ]

    const [menuOpen,setMenuOpen] = useState(false);


    return (
        <Box width={"6%" } minWidth={"6%"} >
            <Flex
                // mt={'7vh'}
                bgColor={"claydeck.brand.secondary"}
                // boxShadow={"xl"}
                paddingBottom={"24px"}

                position={"sticky"}
                top={'7vh'}
                mt={'7vh'}
                // left={{base: props.sidebarVisible ? "0px" : "-500px", xl: '0px'}}
                height={{base: 'min-content'}}
                minHeight={'93vh'}
                // overflowY={"scroll"}
                zIndex={10}
                width={'100%'}
                flexDirection={'column'}
                justifyContent={'space-between'}

            >
                <Box>
                {
                    paths.map((path, index) => (
                        <Link to={path.path}>
                        <Center flexDirection={'column'} pt={'30px'} cursor={'pointer'} onClick={()=>{
                            // window.location.href = path.path
                        }}>
                            <Box borderRadius={'35px'} mx={2}>
                            <Image color={'red'} height={'1.3em'} src={window.location.href.includes(path.path)?path.selectedIcon:path.icon}/>
                            </Box>
                            <Pmd mt={2} color={'white'}>{path.name}</Pmd>
                        </Center>
                        </Link>
                    ))
                }</Box>

                {/*<TaskList />*/}
                {/*<Timer />*/}
                {/*<TaskCalendar />*/}
                <Center>
                <Avatar cursor={'pointer'} onClick={()=>{
                    setMenuOpen(!menuOpen);
                }} name='Dan Abrahmov' src='/avatar1.png' />
                </Center>


                {menuOpen && <Box padding={'24px'} position={'absolute'} bottom={'30px'} left={'60px'} borderRadius={12}
                      bgColor={'white'} boxShadow={'1px 2px 2px 0px #00000040'} minHeight={'400px'} minWidth={'250px'}>
                    <Flex mb={8} alignContent={'center'}>
                        <Avatar onClick={() => {
                            setMenuOpen(!menuOpen);
                        }} name='Dan Abrahmov' src='/avatar1.png'/>
                        <Box flex={1} ml={'12px'} alignContent={'center'}>
                            <Plg><strong>{username.length > 10 ? username.substring(0, 10) + '...' : username}</strong></Plg>
                            {/* <Psm>Beginner</Psm> */}
                        </Box>

                        <Image onClick={() => {
                            setMenuOpen(false)
                        }} src={'/multiply.png'} width={'47px'} height={'30px'} cursor={'pointer'}></Image>

                    </Flex>
                    {
                        menuLinks.map((link, index) => (
                            <>
                                <Pmd fontWeight={'300'} marginBottom={'0.4rem'} cursor={'pointer'} onClick={link.onClick} key={index}><strong>{link.name}</strong></Pmd>
                                <hr style={{marginTop:'10px',marginBottom:'10px'}}/>
                            </>
                        ))
                    }
                    <Psm mt={40}>v1.0.0</Psm>
                </Box>}
            </Flex>


            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Contact Us</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Pmd>
                            You can reach out to us at <span><strong>claydeck.help@gmail.com</strong></span>

                        </Pmd>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>


            <Box position={'fixed'} right={30} top={'70vh'} w={'200px'} h={'200px'} zIndex={1}>
                <Image src={'/watermark.png'} h={'223px'} w={'127px'}>

                </Image>
            </Box>

        </Box>
    );

};

export default Sidebar;
