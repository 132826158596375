import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import { persistStore, persistReducer } from "redux-persist";
// import AsyncStorage from "@react-native-async-storage/async-storage";
import storage from "redux-persist/lib/storage";

import userReducer from "./reducers/userReducer";
import settingsReducer from "./reducers/settingsReducer";
import forumDataReducer from "./reducers/forumDataReducer";
import uiReducer from "./reducers/uiReducer";
import subjectiveTestReducer from "./reducers/subjectiveTestReducer";
import aiSearchReducer from "./reducers/aiSearchReducer";
// import dataReducer from './reducers/dataReducer';
// import testReducer from './reducers/testReducer';

const initialState = {};

const middleware = [thunk];

// add the name of the states in the `whitelist` to keep the value persisted in store
const userPersistConfig = {
  key: "root",
  storage: storage,
  whitelist: [
    "accessToken",
    "refreshToken",
    "authenticated",
    "profile",
    "workspaces",
    "selectedWorkspace",
    "username",
    "selectedFeeds",
  ],
};

const settingsPersistConfig = {
  key: "root",
  storage: storage,
};

const reducers = combineReducers({
  user: persistReducer(userPersistConfig, userReducer),
  settings: persistReducer(settingsPersistConfig, settingsReducer),
  forumData: persistReducer(settingsPersistConfig, forumDataReducer),
  subTestQue: persistReducer(settingsPersistConfig, subjectiveTestReducer),
  aiSearch: aiSearchReducer,
  UI: uiReducer,
  // data: dataReducer,
  // test: testReducer,
});

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;
const enhancer = composeEnhancers(applyMiddleware(...middleware));

const store = createStore(reducers, initialState, enhancer);
const persistor = persistStore(store);

export { store, persistor };
