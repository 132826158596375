import "./css/LikeDisLike.css";
import { Box, Text, Divider } from "@chakra-ui/react";
import { useState } from "react";
import postDownVotes from "./utils/postDownvotes";
import postUpVotes from "./utils/postUpvotes";

const LikeAndDisLikeBox = ({ likes, id, ws_id }) => {
  const [isLiked, setIsLike] = useState(false);
  const [isDisliked, setIsDisliked] = useState(false);
  const [totalLikes, setTotalLikes] = useState(likes);
  const { UpVotes } = postUpVotes();
  const { DownVotes } = postDownVotes();

  const handleClick = (e) => {
    if (e.target.id === "like") {
      if (isLiked === false) {
        if (isDisliked === true) {
          setIsDisliked(false);
        }
        setIsLike(true);
        UpVotes("test123@gmail.com", id, ws_id);
        setTotalLikes(totalLikes + 1);
      } else {
        setIsLike(false);
        DownVotes("test123@gmail.com", id, ws_id);
        setTotalLikes(totalLikes - 1);
      }
    } else if (e.target.id === "dislike") {
      if (isDisliked === false) {
        if (isLiked === true) {
          setIsLike(false);
          setIsDisliked(true);
          DownVotes("test123@gmail.com", id, ws_id);
          setTotalLikes(totalLikes - 1);
        }
        setIsDisliked(true);
        DownVotes("test123@gmail.com", id, ws_id);
      } else {
        setIsDisliked(false);
        UpVotes("test123@gmail.com", id, ws_id);
      }
    }
  };

  return (
    <Box
      as="div"
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      gap={"2"}
      border={"0.75px solid #DEE0E1"}
      borderRadius={"31px"}
      backgroundColor={"#F6F6F6"}
      padding={"4px"}
      transition={"fill 0.3s ease-in-out"}
    >
      <Box as="button" onClick={handleClick}>
        <svg
          id="like"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill={isLiked ? "#38cf38" : "black"}
        >
          <path
            id="like"
            d="M17.9422 8.93282L10.4422 1.43282C10.3841 1.37471 10.3152 1.32861 10.2393 1.29715C10.1635 1.2657 10.0821 1.24951 10 1.24951C9.91787 1.24951 9.83654 1.2657 9.76067 1.29715C9.68479 1.32861 9.61586 1.37471 9.55782 1.43282L2.05782 8.93282C1.97031 9.02022 1.9107 9.13163 1.88655 9.25294C1.86239 9.37424 1.87477 9.49998 1.92211 9.61425C1.96946 9.72851 2.04964 9.82616 2.15252 9.89483C2.25539 9.9635 2.37632 10.0001 2.5 10H5.625V16.25C5.625 16.5815 5.7567 16.8995 5.99112 17.1339C6.22554 17.3683 6.54348 17.5 6.875 17.5H13.125C13.4565 17.5 13.7745 17.3683 14.0089 17.1339C14.2433 16.8995 14.375 16.5815 14.375 16.25V10H17.5C17.6237 10.0001 17.7446 9.9635 17.8475 9.89483C17.9504 9.82616 18.0305 9.72851 18.0779 9.61425C18.1252 9.49998 18.1376 9.37424 18.1135 9.25294C18.0893 9.13163 18.0297 9.02022 17.9422 8.93282ZM13.75 8.75C13.5842 8.75 13.4253 8.81585 13.3081 8.93306C13.1909 9.05027 13.125 9.20924 13.125 9.375V16.25H6.875V9.375C6.875 9.20924 6.80916 9.05027 6.69195 8.93306C6.57474 8.81585 6.41576 8.75 6.25 8.75H4.0086L10 2.7586L15.9914 8.75H13.75Z"
          />
        </svg>
      </Box>

      <Text fontSize={"sm"} color={"#828282"}>
        Found useful . {totalLikes}
      </Text>
      <Divider orientation="vertical" borderWidth={"0.75px"} />
      <Box as="button" onClick={handleClick}>
        <svg
          id="dislike"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill={isDisliked ? "#FF0000" : "#828282"}
        >
          <path
            id="dislike"
            d="M17.9422 11.0672L10.4422 18.5672C10.3841 18.6253 10.3152 18.6714 10.2393 18.7028C10.1635 18.7343 10.0821 18.7505 10 18.7505C9.91787 18.7505 9.83654 18.7343 9.76067 18.7028C9.68479 18.6714 9.61586 18.6253 9.55782 18.5672L2.05782 11.0672C1.97031 10.9798 1.9107 10.8684 1.88655 10.7471C1.86239 10.6258 1.87477 10.5 1.92211 10.3858C1.96946 10.2715 2.04964 10.1738 2.15252 10.1052C2.25539 10.0365 2.37632 9.9999 2.5 10H5.625V3.75C5.625 3.41848 5.7567 3.10054 5.99112 2.86612C6.22554 2.63169 6.54348 2.5 6.875 2.5H13.125C13.4565 2.5 13.7745 2.63169 14.0089 2.86612C14.2433 3.10054 14.375 3.41848 14.375 3.75V10H17.5C17.6237 9.9999 17.7446 10.0365 17.8475 10.1052C17.9504 10.1738 18.0305 10.2715 18.0779 10.3858C18.1252 10.5 18.1376 10.6258 18.1135 10.7471C18.0893 10.8684 18.0297 10.9798 17.9422 11.0672ZM13.75 11.25C13.5842 11.25 13.4253 11.1841 13.3081 11.0669C13.1909 10.9497 13.125 10.7908 13.125 10.625V3.75H6.875V10.625C6.875 10.7908 6.80916 10.9497 6.69195 11.0669C6.57474 11.1841 6.41576 11.25 6.25 11.25H4.0086L10 17.2414L15.9914 11.25H13.75Z"
          />
        </svg>
      </Box>
    </Box>
  );
};

export default LikeAndDisLikeBox;
