import axios from "axios";

const postDownVotes = () => {
  const DownVotes = async (currentUser, id, ws_id) => {
    try {
      const response = await axios.post(
        `https://kk2z2518c6.execute-api.ap-south-1.amazonaws.com/staging/forum/forum-posts/${id}/${ws_id}/downvote/`,
        currentUser
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  return { DownVotes };
};

export default postDownVotes;
