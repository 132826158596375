export const quiz_map = {
    "standard_tests": [
      {
        "subject_area": "History",
        "topic": "Ancient History",
        "id": "1",
        "title": "Ancient History Quiz",
        "read_status": "Unread",
        "score": 85,
        "icon": "https://example.com/history_icon.png"
      },
      {
        "subject_area": "Geography",
        "topic": "Physical Geography",
        "id": "2",
        "title": "Physical Geography Quiz",
        "read_status": "Read",
        "score": 90,
        "icon": "https://example.com/geography_icon.png"
      },
      {
        "subject_area": "Polity",
        "topic": "Constitutional Framework",
        "id": "3",
        "title": "Constitutional Framework Quiz",
        "read_status": "Unread",
        "score": 78,
        "icon": "https://example.com/polity_icon.png"
      },
      {
        "subject_area": "Economics",
        "topic": "Microeconomics",
        "id": "4",
        "title": "Microeconomics Quiz",
        "read_status": "Read",
        "score": 88,
        "icon": "https://example.com/economics_icon.png"
      }
    ],
    "personalised_tests": [
      {
        "topic": "Modern History",
        "score": 92,
        "id": "1",
        "title": "Modern History Quiz",
        "read_status": "Unread",
        "icon": "https://example.com/modern_history_icon.png"
      },
      {
        "topic": "Indian Geography",
        "score": 89,
        "id": "2",
        "title": "Indian Geography Quiz",
        "read_status": "Read",
        "icon": "https://example.com/indian_geography_icon.png"
      },
      {
        "topic": "Governance",
        "score": 95,
        "id": "3",
        "title": "Governance Quiz",
        "read_status": "Unread",
        "icon": "https://example.com/governance_icon.png"
      },
      {
        "topic": "Macroeconomics",
        "score": 87,
        "id": "4",
        "title": "Macroeconomics Quiz",
        "read_status": "Read",
        "icon": "https://example.com/macroeconomics_icon.png"
      }
    ]
  }
