import React, { useState } from 'react';
import './ButtonImageDrag.css';
import ButtonSecondary from "./Button/ButtonSecondary"; // Import the CSS file

const ButtonImageDrag = () => {
    const [buttonData, setButtonData] = useState([{offset:{x:10,y:-70},label:'Button 1'}, {offset:{x:100,y:-80},label:'Button 2'}, {offset:{x:190,y:-70},label:'Button 3'}]);
    const [coordinates, setCoordinates] = useState([{x: 10, y: 50,present:false,presentId:-1}, {x: 130, y: 80,present:false,presentId:-1}, {x: 40, y: 150,present:false,presentId:-1}]);
    const [styles, setStyles] = useState(Array(buttonData.length).fill({}));
    // const [pre, setStyles] = useState(Array(buttonData.length).fill({}));
    const [nextPos, setNextPos] = useState(0);
    console.log(coordinates,'poss')

    const getEmptyPositionIndex = () =>{
        return coordinates.findIndex(i=>!i.present)
    }

    const getCordbyPresentIdx = (idx) =>{
        return coordinates.findIndex(i=>i.presentId === idx)
    }

    const getEmptyPositionObject = () =>{
        return coordinates.find(i=>!i.present)
    }

    const handleClick = (index) => {
        const newStyles = [...styles];
        if (newStyles[index].transform) {
            newStyles[index] = {};
            // setNextPos(Math.min(index,nextPos - 1) < 0 ? 0:Math.min(index,nextPos - 1));

            let empty_pos = coordinates[getCordbyPresentIdx(index)]
            const empty_pos_idx = getCordbyPresentIdx(index)
            empty_pos.present = false
            let cordcpy = JSON.parse(JSON.stringify(coordinates))
            cordcpy[empty_pos_idx] = empty_pos
            setCoordinates(cordcpy)

        } else {
            let empty_pos = JSON.parse(JSON.stringify(getEmptyPositionObject()))
            const empty_pos_idx = getEmptyPositionIndex()
            console.log(empty_pos_idx,empty_pos)
            empty_pos.present = true
            empty_pos.presentId = index
            let cordcpy = JSON.parse(JSON.stringify(coordinates))
            console.log(empty_pos_idx,empty_pos,cordcpy,'first')
            cordcpy[empty_pos_idx] = empty_pos
            console.log(empty_pos_idx,empty_pos,cordcpy)

            newStyles[index] = {
                transform: `translate(${empty_pos.x - buttonData[index].offset.x}px, ${empty_pos.y - buttonData[index].offset.y}px)`,
                transition: 'transform 0.5s'
            };
            setCoordinates(cordcpy)
            // setNextPos(nextPos + 1);
        }
        setStyles(newStyles);
    };
// console.log(buttonData[0].offest.y,'btnda')
    return (
        <div className="container">
            {buttonData.map((text, index) => {
                console.log(text.offset,'texttx')
                return <ButtonSecondary key={index} style={{top: text.offset.y, left: text.offset.x, ...styles[index]}}
                        onClick={() => handleClick(index)}>
                    {console.log(text, 'log')}
                    {text.label}
                </ButtonSecondary>
            })}
            <img src="/img.png" alt="Image" />

        </div>
    );
};

export default ButtonImageDrag;
