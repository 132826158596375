import { Box, Text, Center, Spinner } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import useGetMockTestSubs from "../utils/getMockTestSubs";

import { useEffect, useState } from "react";

const MockPaperChapters = () => {
  const param = useParams();

  const { fetchMockTestSubs, isLoading, setIsLoading } = useGetMockTestSubs();
  const [testSubs, setTestSubs] = useState([]);

  useEffect(() => {
    const fetchTestSubs = async () => {
      setIsLoading(true);
      const res = await fetchMockTestSubs(2, "rahulk30s@gmail.com");
      setTestSubs(res?.test[param?.id]);
      setIsLoading(false);
    };

    fetchTestSubs();
  }, []);

  console.log("chap", isLoading);

  const renderChap = () => {
    return testSubs?.tests?.map((test, i) => (
      <Box
        key={i}
        as="a"
        href={`/library/mock-papers/${param?.subject}/${test.name}/${test?.test_id}`}
        fontSize={"md"}
        fontWeight={"semibold"}
        boxShadow={"sm"}
        display={"block"}
        borderWidth={"2px"}
        borderRadius={"lg"}
      >
        <Text backgroundColor={"purple.200"} padding={2} margin={0}>
          {test.name + " :"}
          {" Chapter " + (i + 1)}
        </Text>
      </Box>
    ));
  };

  return (
    <>
      <Box
        display={"flex"}
        width={"full"}
        flexDir={"column"}
        padding={8}
        margin={8}
      >
        <Box width={"full"}>
          <Box
            backgroundColor={"purple.100"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            padding={2}
            borderRadius={"md"}
            borderWidth={"2px"}
            borderTopColor={"purple.300"}
            boxShadow={"md"}
          >
            <Text
              fontSize={"xl"}
              fontWeight={"bold"}
              letterSpacing={"2px"}
              margin={"0"}
            >
              {param.subject + " "}Tests
            </Text>
          </Box>
        </Box>

        <Box
          display={"flex"}
          flexWrap={"wrap"}
          flexDir={"row"}
          gap={4}
          marginTop={8}
        >
          {isLoading ? (
            <Center ml={"455px"} mt={8}>
              <Spinner color="purple.500" />
            </Center>
          ) : (
            renderChap()
          )}
        </Box>
      </Box>
    </>
  );
};

export default MockPaperChapters;
