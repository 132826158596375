// VideoCall.js
import React, { useEffect, useRef, useState } from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';
import axios from "axios";
import {useSelector} from "react-redux";
import SteamWindow from "./VideoCall/SteamWindow";
import SteamMoreWindow from "./VideoCall/SteamMoreWindow";
import ButtonPrimary from "./Button/ButtonPrimary";
import ButtonSecondary from "./Button/ButtonSecondary";
import {Box, Icon} from "@chakra-ui/react";

import {BiMicrophone} from "react-icons/bi";
import {BiMicrophoneOff} from "react-icons/bi";
import {BiCamera} from "react-icons/bi";
import {BiCameraOff} from "react-icons/bi";
import {MdOutlineScreenShare} from "react-icons/md";
import {MdOutlineStopScreenShare} from "react-icons/md";
import {ImPhoneHangUp} from "react-icons/im";
import {AiOutlineSearch} from "react-icons/ai";


function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update state to force render
    // A function that increment 👆🏻 the previous state like here
    // is better than directly setting `setValue(value + 1)`
}
const VideoCallNGComponent = (props) => {
    const forceUpdate = useForceUpdate();
    console.log(props.match,'paramsmatc')
    const localVideoRef = useRef(null);
    const remoteVideoRef = useRef();
    const [client, setClient] = useState(null);
    const [clientScreen, setClientScreen] = useState(null);
    const [localStream, setLocalStream] = useState(null);
    const [localAudioStream, setLocalAudioStream] = useState(null);
    const [localScreenStream, setlocalScreenStream] = useState(null);
    const [remoteStreams, setRemoteStreams] = useState([]);
    const [userCount, setUserCount] = useState(1);
    const [tempStream, setTempStream] = useState(null);
    const [localUID, setLocalUID] = useState(null);
    const username = useSelector((state) => state.user.username);
    const workspaceId = useSelector(state => state.user.selectedWorkspace.workspace_id);


    console.log(`CURRENT REMOTE STREAMS = ${remoteStreams.length}`)
    useEffect(() => {
        console.log("I FIRE ONE!")
        initializeAgora().then(r=>{});
        return () => {
            leaveChannel();
        };
    }, []);

    useEffect(()=>{
        if(client)
            forceUpdate()
    },[client?.remoteUsers])


    useEffect(()=>{
        const f = async () => {
            const appId = '6c73e2727ea8419098cd469dd5d5a23c';
            const user_channel = window.location.href.split("/")[window.location.href.split("/").length-1].split("/")[0] + "channel"
            const genuid =Math.floor(Math.random() * 230) + 2;
            // const genuid ='Harsh Vardhan';
            // const genuid = 2882341273//Math.floor(Math.random() * 230) + 1;
            console.log("CONNECTING AS ",username)
            // setLocalUID(genuid)
            // document.getElementById("local_uid").innerHTML = genuid
            
            console.log("https://55aaqcxwk7.execute-api.ap-south-1.amazonaws.com/dev/community/get_tokens/"+window.location.href.split("room/")[1].split("/")[0]+"/"+user_channel+"/"+username+"/");

            const api_token = await axios.post("https://55aaqcxwk7.execute-api.ap-south-1.amazonaws.com/dev/community/get_tokens/"+window.location.href.split("room/")[1].split("/")[0]+"/"+user_channel+"/"+username+"/",{
                "uid" : genuid+"",
                "user_name" : username
            })
            // console.log(api_token,api_token.data.RTC_Token,api_token.data.uid)
            const uid= await client.join(appId, user_channel, api_token.data.RTC_Token,api_token.data.uid);
            // const localStream = AgoraRTC.createStream({ streamID: api_token.data.uid+"", audio: true, video: true, screen: false });
            //
            // await localStream.init();
            // localStream.play(localVideoRef.current);
            const localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
            const localVideoTrack = await AgoraRTC.createCameraVideoTrack();
            localVideoTrack.play(document.getElementById('videos'))
            console.log("AGORA PUBLISH CALLED!")
            await client.publish([localAudioTrack,localVideoTrack]);
            setLocalStream(localVideoTrack);
            setLocalAudioStream(localAudioTrack)
        }
        if(client){
            f()
        }
    },[client])


    const initializeAgora = async () => {
        const client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });



        client.on("user-published", async (user, mediaType) => {

            console.log("USER PUBLISHED!",user,mediaType)
            await client.subscribe(user, mediaType);
            forceUpdate()
        });
        client.on("user-joined", async (user) => {
            forceUpdate()
        });
        client.on("user-left", async (user) => {
            console.log("USER HAS LEFT",user)
            forceUpdate()
        });
        client.on("user-unpublished", async (user) => {
            console.log("USER HAS UNPUB",user)
            forceUpdate()
        });
        client.on("user-info-updated", async (user) => {
            console.log("USER HAS UPDT",user)
            forceUpdate()
        });



        setClient(client);
        // setUserCount(1)
    };

    // const subscribeToRemote = async (user, mediaType) => {
    //     // const remoteStream = await client.subscribe(user, mediaType);
    //     console.log(user,mediaType,"AGORA DATA ENTRY!!!")
    //
    //     if (mediaType == "video")
    //     {
    //         // Retrieve the remote video track.
    //         // channelParameters.remoteVideoTrack = user.videoTrack;
    //         // Retrieve the remote audio track.
    //         // channelParameters.remoteAudioTrack = user.audioTrack;
    //         // Save the remote user id for reuse.
    //         // channelParameters.remoteUid = user.uid.toString();
    //         // Specify the ID of the DIV container. You can use the uid of the remote user.
    //         // remotePlayerContainer.id = user.uid.toString();
    //         // channelParameters.remoteUid = user.uid.toString();
    //         // remotePlayerContainer.textContent = "Remote user " + user.uid.toString();
    //         // Append the remote container to the page body.
    //         // document.body.append(remotePlayerContainer);
    //         // if(options.role != 'host')
    //         // {
    //             // Play the remote video track.
    //         console.log(user,mediaType,"AGORA DATA!!")
    //             user.videoTrack?.play('videos');
    //         if(user.videoTrack)
    //             setTempStream(user.videoTrack)
    //         // }
    //     }
    //     // Subscribe and play the remote audio track If the remote user publishes the audio track only.
    //     if (mediaType == "audio")
    //     {
    //         if(user.audioTrack)
    //             setTempStream(user.audioTrack)
    //         // Get the RemoteAudioTrack object in the AgoraRTCRemoteUser object.
    //         // channelParameters.remoteAudioTrack = user.audioTrack;
    //         // Play the remote audio track. No need to pass any DOM element.
    //         // channelParameters.remoteAudioTrack.play();
    //     }
    //     // setRemoteStreams((prevRemoteStreams) => [...prevRemoteStreams, remoteStream]);
    // };

    const leaveChannel = async () => {
        if (client) {
            await client.leave();
        }
        if (localStream) {
            localStream.stop();
            localStream.close();
        }
        setClient(null);
        setLocalStream(null);
        setRemoteStreams([]);
    };
    console.log(client?.remoteUsers,'REMOTE USERS CLIENT')
    return (
        <div style={{width:'100%',height:'100%',padding:'10px'}}>

            <div id={"local_uid"}>

            </div>
            {client && <div id={"videos"} className={`video-container ${
                client.remoteUsers.length+1===1 ? 'grid-cols-1':
                    client.remoteUsers.length +1> 1 && client.remoteUsers.length +1<= 4 ? 'grid-cols-2' :
                        client.remoteUsers.length+1 >4 && client.remoteUsers.length +1<= 9 ? 'grid-cols-3':
                        "grid-cols-3"    
            }`}>
                {/*{*/}
                {/*    [...Array(userCount)].map(i=>(*/}
                {/*        <div style={{backgroundColor:'beige'}}>{i}</div>*/}
                {/*    ))*/}
                {/*}*/}
                {console.log("SORTED ARR!",[...client?.remoteUsers].sort((a, b) => a.uid - b.uid))}

                {
                    client && [...client?.remoteUsers].sort((a, b) =>a.uid - b.uid).map((stream,id)=> {
                        console.log(id,client?.remoteUsers.length)
                        return (
                                <SteamWindow total_users={client.remoteUsers.length} key={id} stream={stream} video={stream.videoTrack} visible={(id <7 || id ===7 && client.remoteUsers.length===8)} numberWindow={id===8}/>
                            )

                    })
                }

            </div>}

            {/*<h1>Video Call</h1>*/}
            {/*{localVideoRef  && <div ref={localVideoRef}></div>}*/}
            {/*<div id={"agora-preview"} style={{width:`${100/userCount}%`,height:`${100/userCount}%`,backgroundColor:'yellowgreen'}}></div>*/}
            {/*<h1>Remote streams</h1>*/}
            {/*<div id={"agora-remote-preview"} style={{width:300,height:300,backgroundColor:'yellowgreen'}}></div>*/}

            {/*{remoteStreams.map((remoteStream) => (*/}
            {/*    <div key={remoteStream.getId()} ref={remoteVideoRef}>*/}
            {/*        <span>Remote User: {remoteStream.getId()}</span>*/}
            {/*    </div>*/}
            {/*))}*/}
            <Box mt={10} display={"flex"} alignItems={"center"} justifyContent={"center"}>

            {localAudioStream && <ButtonSecondary style={{marginLeft:'10px'}} onClick={() => {
                client.unpublish(localAudioStream)
                setLocalAudioStream(null)
            }}><Icon
                as={BiMicrophone}
                boxSize={{ base: "16px", lg: "20px" }}
                color={"#6750A4"}
            /></ButtonSecondary>}
            {!localAudioStream && <ButtonSecondary style={{marginLeft:'10px'}} onClick={() => {
                AgoraRTC.createMicrophoneAudioTrack().then(r=>{
                    client.publish(r).then(r=>{});
                    setLocalAudioStream(r)
                });

            }}>
                <Icon
                    as={BiMicrophoneOff}
                    boxSize={{ base: "16px", lg: "20px" }}
                    color={"#6750A4"}
                /></ButtonSecondary>}

            {
                localStream && <ButtonSecondary style={{marginLeft:'10px'}} onClick={() => {
                    client.unpublish(localStream)
                    localStream.stop()
                    setLocalStream(null)
                }}>
                    <Icon
                        as={BiCamera}
                        boxSize={{ base: "16px", lg: "20px" }}
                        color={"#6750A4"}
                    /></ButtonSecondary>
            }
            {
                !localStream && <ButtonSecondary style={{marginLeft:'10px'}} onClick={() => {
                    AgoraRTC.createCameraVideoTrack().then(r=>{
                        client.publish(r).then(r=>{});
                        r.play(document.getElementById('videos'))
                        setLocalStream(r)
                    });
                }}>
                    <Icon
                        as={BiCameraOff}
                        boxSize={{ base: "16px", lg: "20px" }}
                        color={"#6750A4"}
                    /></ButtonSecondary>
            }


            {
                localScreenStream && <ButtonSecondary style={{marginLeft:'10px'}} onClick={() => {
                    // client.unpublish(localScreenStream)
                    localScreenStream.stop()
                    setlocalScreenStream(null)
                    clientScreen.leave()
                    setClientScreen(null)
                }}><Icon
                    as={MdOutlineStopScreenShare}
                    boxSize={{ base: "16px", lg: "20px" }}
                    color={"#6750A4"}
                /></ButtonSecondary>
            }
            {
                client && !localScreenStream && <ButtonSecondary style={{marginLeft:'10px'}} isDisabled={client?([...client.remoteUsers].filter(i=>i.uid===1)).length>0:false} onClick={async () => {
                    const clientScreen = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });

                    const appId = '6c73e2727ea8419098cd469dd5d5a23c';
                    const user_channel = window.location.href.split("/")[window.location.href.split("/").length-1].split("/")[0] + "channel"
                    const genuid =1;
                    // const genuid = 2882341273//Math.floor(Math.random() * 230) + 1;
                    console.log("CONNECTING AS ",username)
                    // setLocalUID(genuid)
                    // document.getElementById("local_uid").innerHTML = genuid
                    const api_token = await axios.post("https://55aaqcxwk7.execute-api.ap-south-1.amazonaws.com/dev/community/get_tokens/"+window.location.href.split("room/")[1].split("/")[0]+"/"+user_channel+"/"+username+"/",{
                        "uid" : genuid+"",
                        "user_name" : username+"_screen"
                    })
                    // console.log(api_token,api_token.data.RTC_Token,api_token.data.uid)
                    const uid= await clientScreen.join(appId, user_channel, api_token.data.RTC_Token,api_token.data.uid);
                    console.log(clientScreen,'client_screen')
                    AgoraRTC.createScreenVideoTrack({
                        encoderConfig: "1080p_1",
                        screenSourceType: 'screen'
                    },"auto").then(r=>{
                        console.log("IN JOIN PUBLISH")
                        clientScreen.publish(r).then(r=>{
                            console.log("PUBLISH SUCCESS")

                        });
                        setlocalScreenStream(r)
                        setClientScreen(clientScreen)
                    }).catch(err=>{
                        alert("Oh it appears your browser does not support this feature!")
                        try{
                            clientScreen.leave()
                        }catch (e) {
                            console.log("err")
                        }
                    });
                }}><Icon
                    as={MdOutlineScreenShare}
                    boxSize={{ base: "16px", lg: "20px" }}
                    color={"#6750A4"}
                /></ButtonSecondary>
            }

                <ButtonSecondary style={{marginLeft:'10px'}} onClick={async () => {
                    window.location.href = "/room/"
                }}><Icon
                    as={ImPhoneHangUp}
                    boxSize={{ base: "16px", lg: "20px" }}
                    color={"#da5072"}
                /></ButtonSecondary>



            {/*<button onClick={()=>{setTempStream({*/}
            {/*    uid:Math.random()*/}
            {/*})}}>ADD</button>*/}

            </Box>
        </div>
    );
};

export default VideoCallNGComponent;
